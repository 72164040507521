import { Injectable, Type } from '@angular/core';
import { Subject } from 'rxjs';

export interface InjectableComponent {
  data: any;
}

@Injectable({ providedIn: 'root' })
export class SidenavService {
  component$ = new Subject<{component: Type<any>, data: any}>();

  injectComponent(component: Type<any>, data: any): void {
    this.component$.next({ component, data });
  }

  clearComponent(): void {
    this.component$.next(undefined);
  }
}
