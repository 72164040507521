import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { IssuerBiddingRound } from '@project-shared/model/bidding-round/bidding-round.model';
import { ApiService } from '@project-shared/modules/api/services/api.service';

@Injectable()
export class LatestBiddingRoundResolver {
  constructor(private api: ApiService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<IssuerBiddingRound> {
    return this.api.getLatestBiddingRoundForTenderUid(route.params.tenderUid);
  }
}
