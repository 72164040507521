import { ChangedProjectField } from '../project/project-log.model';
import { default as moment, Moment } from 'moment';
import { ParticipantLog, ParticipantLogDto } from './participant-log.model';
import { TenderContactPerson, TenderContactPersonDto } from './tender-contact-person.log';

export enum LogType {
  CREATED = 'CREATED',
  PUBLISHED = 'PUBLISHED',
  MODIFIED = 'MODIFIED',
  DELETED = 'DELETED',
  COMPLETED = 'COMPLETED',
  REOPENED = 'REOPENED',
  MODIFICATION_NOTIFICATION = 'MODIFICATION_NOTIFICATION',
  BIDDER_INVITED = 'BIDDER_INVITED',
  BIDDER_INVITED_NOTIFICATION = 'BIDDER_INVITED_NOTIFICATION',
  REMIND_BIDDER = 'REMIND_BIDDER',
  CREATE_PRIVATE_BIDDER = 'CREATE_PRIVATE_BIDDER',
  BIDDER_REMOVED = 'BIDDER_REMOVED',
  BIDDER_EXPECT = 'BIDDER_EXPECT',
  UNSUBSCRIBED_BIDDER = 'UNSUBSCRIBED_BIDDER',
  EXTEND_SUBMISSION_DATE = 'EXTEND_SUBMISSION_DATE',
  BIDDER_APPLIED = 'BIDDER_APPLIED',
  BIDDER_APPLIED_NOTIFICATION = 'BIDDER_APPLIED_NOTIFICATION',
  BIDDER_REJECTED = 'BIDDER_REJECTED',
  BIDDER_REJECTED_NOTIFICATION = 'BIDDER_REJECTED_NOTIFICATION',
  BIDDER_OFFER_REJECTED = 'BIDDER_OFFER_REJECTED',
  BIDDER_OFFER_REJECTED_NOTIFICATION = 'BIDDER_OFFER_REJECTED_NOTIFICATION',
  CANCEL = 'CANCEL',
  CANCEL_NOTIFICATION = 'CANCEL_NOTIFICATION',
  TENDER_PARTICIPANT_REMOVED = 'TENDER_PARTICIPANT_REMOVED',
  TENDER_PARTICIPANT_ADDED = 'TENDER_PARTICIPANT_ADDED',
  PUBLICATION_SCHEDULED = 'PUBLICATION_SCHEDULED',
  SCHEDULED_PUBLICATION_CANCELED = 'SCHEDULED_PUBLICATION_CANCELED',
  PUBLISHED_AUTOMATICALLY = 'PUBLISHED_AUTOMATICALLY',
  OPEN_APPLICATIONS_REMINDER = 'OPEN_APPLICATIONS_REMINDER',
  SUBMISSION_DEADLINE_REMINDER = 'SUBMISSION_DEADLINE_REMINDER',
  OFFER_CREATED = 'OFFER_CREATED',
  OFFER_CHANGED = 'OFFER_CHANGED',
  OFFER_DELETED = 'OFFER_DELETED',
  NOTIFICATION_SENT_TO_BIDDER_FOR_OFFER_SUBMITTED_ON_THEIR_BEHALF = 'NOTIFICATION_SENT_TO_BIDDER_FOR_OFFER_SUBMITTED_ON_THEIR_BEHALF',
  NOTIFICATION_SENT_TO_BIDDER_FOR_BID_SUBMITTED_ON_THEIR_BEHALF = 'NOTIFICATION_SENT_TO_BIDDER_FOR_BID_SUBMITTED_ON_THEIR_BEHALF',
  INVITED_TO_BIDDING_ROUND = 'INVITED_TO_BIDDING_ROUND',
  NOTIFICATION_SENT_BIDDER_INVITED_BIDDING_ROUND = 'NOTIFICATION_SENT_BIDDER_INVITED_BIDDING_ROUND',
  WORK_DESCRIPTION_EDITED = 'WORK_DESCRIPTION_EDITED',
  TENDER_APPROVAL_CREATED = 'TENDER_APPROVAL_CREATED',
  TENDER_APPROVAL_DELETED = 'TENDER_APPROVAL_DELETED',
  BID_CREATED = 'BID_CREATED',
  BID_CHANGED = 'BID_CHANGED',
  BID_DELETED = 'BID_DELETED',
}

export interface LogDto {
  date: Moment;
  clientName?: string;
  olmeroId?: string;
  memberFirstname: string;
  memberLastname: string;
  companyName: string;
  companyDepartment?: string;
  companyCity?: string;
  changeMessage?: string;
  rejectionReason?: string;
  logType: string;
  participant?: ParticipantLogDto;
  publicationScheduledFor?: Moment;
  changedFields?: ChangedProjectField[];
  newSubmissionDate?: string;
  oldSubmissionDate?: string;
  tenderContactPerson?: TenderContactPersonDto;
  attachmentUid?: string;
  attachmentName?: string;
}

export class Log implements LogDto {
  date: Moment;
  clientName?: string;
  olmeroId?: string;
  memberFirstname: string;
  memberLastname: string;
  companyName: string;
  companyDepartment?: string;
  companyCity?: string;
  changeMessage?: string;
  rejectionReason?: string;
  logType: LogType;
  participant?: ParticipantLog;
  publicationScheduledFor?: Moment;
  changedFields?: ChangedProjectField[];
  id?: number;
  offerId?: string;
  newSubmissionDate?: string;
  oldSubmissionDate?: string;
  tenderContactPerson?: TenderContactPerson;
  attachmentUid?: string;
  attachmentName?: string;

  constructor(json?: LogDto) {
    if (json) {
      Object.assign(this, json);

      if (json.participant) {
        this.participant = new ParticipantLog(json.participant);
      }

      if (json.tenderContactPerson) {
        this.tenderContactPerson = new TenderContactPerson(json.tenderContactPerson);
      }

      this.deserializeDates(json);
    }
  }

  isSameGroup(logType: LogType): boolean {
    if (this.logType === LogType.MODIFIED && logType === LogType.WORK_DESCRIPTION_EDITED) {
      return true;
    }

    if (this.logType === LogType.WORK_DESCRIPTION_EDITED && logType === LogType.MODIFIED) {
      return true;
    }

    return this.logType === logType;
  }

  getCompanyDisplay(): string {
    return [this.companyName, this.companyDepartment, this.companyCity].filter(Boolean).join(', ');
  }

  getUserDisplay(): string {
    return `${this.memberFirstname ? this.memberFirstname : ''} ${this.memberLastname ? this.memberLastname : ''}`.trim();
  }

  private deserializeDates(json: LogDto): void {
    if (json.date) {
      this.date = moment(json.date);
    }

    if (json.publicationScheduledFor) {
      this.publicationScheduledFor = moment(json.publicationScheduledFor);
    }
  }
}
