/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { typeOfWorksTexts } from './default-texts/type-of-works';

@Injectable({ providedIn: 'root' })
export class DefaultTextsService {
  // @formatter:off
  private i18nText: Record<string, any> = {
    ...typeOfWorksTexts,
    pp_core_add_contact_title: $localize`:@@pp_core_add_contact_title:Add contact`,
    pp_core_add_member_error: $localize`:@@pp_core_add_member_error:Participant could not be added.`,
    pp_core_view_member_no_rights: $localize`:@@pp_core_view_member_no_rights:No rights to view participant.`,
    pp_core_view_member_error: $localize`:@@pp_core_view_member_error:Failed to view participant.`,
    pp_core_add_member_sucess: $localize`:@@pp_core_add_member_sucess:Participant has been added.`,
    pp_core_add_member_button: $localize`:@@pp_core_add_member_button:Add`,
    pp_core_remove_member_button: $localize`:@@pp_core_remove_member_button:Remove`,
    pp_core_add_new_member: $localize`:@@pp_core_add_new_member:Create participant`,
    pp_core_address_title_m: $localize`:@@pp_core_address_title_m:Mr`,
    pp_core_address_title_w: $localize`:@@pp_core_address_title_w:Ms`,
    pp_core_authority_attach_self_to_all_projects_name: $localize`:@@pp_core_authority_attach_self_to_all_projects_name:Add myself as participant`,
    pp_core_authority_be_project_manager_name: $localize`:@@pp_core_authority_be_project_manager_name:Be selected as project manager`,
    pp_core_authority_configure_new_projects_name: $localize`:@@pp_core_authority_configure_new_projects_name:Configure new projects`,
    pp_core_authority_create_client_branch_name: $localize`:@@pp_core_authority_create_client_branch_name:Create branch`,
    pp_core_authority_create_client_members_name: $localize`:@@pp_core_authority_create_client_members_name:Create participants`,
    pp_core_authority_create_dispo_inquiries_name: $localize`:@@pp_core_authority_create_dispo_inquiries_name:Create logistics inquiries`,
    pp_core_authority_create_entries_name: $localize`:@@pp_core_authority_create_entries_name:Create list entries`,
    pp_core_authority_create_external_members_name: $localize`:@@pp_core_authority_create_external_members_name:Create external participants`,
    pp_core_authority_create_internal_members_name: $localize`:@@pp_core_authority_create_internal_members_name:Create internal participants`,
    pp_core_authority_create_projects_name: $localize`:@@pp_core_authority_create_projects_name:Create project`,
    pp_core_authority_create_repro_orders_name: $localize`:@@pp_core_authority_create_repro_orders_name:Create plan print orders`,
    pp_core_authority_create_tender_name: $localize`:@@pp_core_authority_create_tender_name:Create tenders`,
    pp_core_authority_deactivate_client_name: $localize`:@@pp_core_authority_deactivate_client_name:Deactivate customer`,
    pp_core_authority_delete_all_entries_name: $localize`:@@pp_core_authority_delete_all_entries_name:Delete all list entries`,
    pp_core_authority_delete_all_files_name: $localize`:@@pp_core_authority_delete_all_files_name:Delete all documents`,
    pp_core_authority_delete_own_files_name: $localize`:@@pp_core_authority_delete_own_files_name:Delete own documents`,
    pp_core_authority_detach_external_members_name: $localize`:@@pp_core_authority_detach_external_members_name:Remove external participants`,
    pp_core_authority_detach_internal_members_name: $localize`:@@pp_core_authority_detach_internal_members_name:Remove internal participants`,
    pp_core_authority_disable_branch_name: $localize`:@@pp_core_authority_disable_branch_name:Disable branch`,
    pp_core_authority_disable_client_members_name: $localize`:@@pp_core_authority_disable_client_members_name:Deactivate participants`,
    pp_core_authority_download_files_name: $localize`:@@pp_core_authority_download_files_name:Download documents`,
    pp_core_authority_download_repro_files_name: $localize`:@@pp_core_authority_download_repro_files_name:View all plan print orders`,
    pp_core_authority_edit_client_branch_name: $localize`:@@pp_core_authority_edit_client_branch_name:Edit branch`,
    pp_core_authority_edit_client_member_rights_name: $localize`:@@pp_core_authority_edit_client_member_rights_name:Edit participant rights`,
    pp_core_authority_edit_client_members_name: $localize`:@@pp_core_authority_edit_client_members_name:Edit participants`,
    pp_core_authority_edit_client_name: $localize`:@@pp_core_authority_edit_client_name:Edit customer`,
    pp_core_authority_edit_dispo_inquiries_name: $localize`:@@pp_core_authority_edit_dispo_inquiries_name:Edit logistics inquiries`,
    pp_core_authority_edit_external_members_name: $localize`:@@pp_core_authority_edit_external_members_name:Edit external participants`,
    pp_core_authority_edit_groups_name: $localize`:@@pp_core_authority_edit_groups_name:Edit groups`,
    pp_core_authority_edit_internal_member_rights_name: $localize`:@@pp_core_authority_edit_internal_member_rights_name:Edit internal participant rights`,
    pp_core_authority_edit_internal_members_name: $localize`:@@pp_core_authority_edit_internal_members_name:Edit internal participant information`,
    pp_core_authority_edit_project_configuration_name: $localize`:@@pp_core_authority_edit_project_configuration_name:Configure project`,
    pp_core_authority_edit_project_member_rights_name: $localize`:@@pp_core_authority_edit_project_member_rights_name:Edit project participant rights`,
    pp_core_authority_edit_project_name: $localize`:@@pp_core_authority_edit_project_name:Edit project`,
    pp_core_authority_edit_project_settings_name: $localize`:@@pp_core_authority_edit_project_settings_name:Edit project settings`,
    pp_core_authority_edit_project_status_name: $localize`:@@pp_core_authority_edit_project_status_name:Edit project status`,
    pp_core_authority_edit_project_template_name: $localize`:@@pp_core_authority_edit_project_template_name:Edit project tender template`,
    pp_core_authority_edit_tender_name: $localize`:@@pp_core_authority_edit_tender_name:Edit tender`,
    pp_core_authority_edit_tender_rights_name: $localize`:@@pp_core_authority_edit_tender_rights_name:Edit the participant rights for tenders`,
    pp_core_authority_manage_new_projects_name: $localize`:@@pp_core_authority_manage_new_projects_name:Manage new projects`,
    pp_core_authority_protect_client_rights_name: $localize`:@@pp_core_authority_protect_client_rights_name:Protect participant rights`,
    pp_core_authority_receive_entries_name: $localize`:@@pp_core_authority_receive_entries_name:Receive list entries`,
    pp_core_authority_receive_files_name: $localize`:@@pp_core_authority_receive_files_name:Receive documents`,
    pp_core_authority_receive_repro_prints_name: $localize`:@@pp_core_authority_receive_repro_prints_name:Receive paper documents`,
    pp_core_authority_register_global_search_name: $localize`:@@pp_core_authority_register_global_search_name:Register self for the global participant search`,
    pp_core_authority_release_tender_name: $localize`:@@pp_core_authority_release_tender_name:Release tender`,
    pp_core_authority_type_client_name: $localize`:@@pp_core_authority_type_client_name:Customer rights`,
    pp_core_authority_type_project_name: $localize`:@@pp_core_authority_type_project_name:Project rights`,
    pp_core_authority_upload_files_name: $localize`:@@pp_core_authority_upload_files_name:Upload documents`,
    pp_core_authority_view_all_projects_name: $localize`:@@pp_core_authority_view_all_projects_name:View all projects`,
    pp_core_authority_view_client_branches_name: $localize`:@@pp_core_authority_view_client_branches_name:View list of branches`,
    pp_core_authority_view_client_members_name: $localize`:@@pp_core_authority_view_client_members_name:View participant list`,
    pp_core_authority_view_dispo_inquiries_name: $localize`:@@pp_core_authority_view_dispo_inquiries_name:View logistics inquiries`,
    pp_core_authority_view_entries_name: $localize`:@@pp_core_authority_view_entries_name:View list entries`,
    pp_core_authority_view_files_name: $localize`:@@pp_core_authority_view_files_name:View documents`,
    pp_core_authority_view_project_list_name: $localize`:@@pp_core_authority_view_project_list_name:View project list`,
    pp_core_authority_view_project_name: $localize`:@@pp_core_authority_view_project_name:View project`,
    pp_core_authority_view_repro_orders_name: $localize`:@@pp_core_authority_view_repro_orders_name:View plan print orders`,
    pp_core_authority_view_tender_list_name: $localize`:@@pp_core_authority_view_tender_list_name:View list of tenders`,
    pp_core_authority_view_tender_name: $localize`:@@pp_core_authority_view_tender_name:View tender`,
    pp_core_authority_edit_tender_template_name: $localize`:@@pp_core_authority_edit_tender_template_name:Edit tender template`,
    pp_core_bidder_accept_application_hint: $localize`:@@pp_core_bidder_accept_application_hint:The company is invited to tender and receives the notification that the application has been accepted.`,
    pp_core_bidder_application_accepted: $localize`:@@pp_core_bidder_application_accepted:Application accepted`,
    pp_core_bidder_application_accepted_save: $localize`:@@pp_core_bidder_application_accepted_save:Accept`,
    pp_core_bidder_application_rejected: $localize`:@@pp_core_bidder_application_rejected:Application rejected`,
    pp_core_bidder_application_rejected_save: $localize`:@@pp_core_bidder_application_rejected_save:Reject`,
    pp_core_bidder_deactived_hint: $localize`:@@pp_core_bidder_deactived_hint:The membership of this company in OLMeRO network has been terminated.`,
    pp_core_bidder_detail_no_inspection_date: $localize`:@@pp_core_bidder_detail_no_inspection_date:No`,
    pp_core_bidder_extend_submission_date_done: $localize`:@@pp_core_bidder_extend_submission_date_done:Submission deadline for offers extended`,
    pp_core_bidder_extend_submission_date_hint_1: $localize`:@@pp_core_bidder_extend_submission_date_hint_1:Extension by one day`,
    pp_core_bidder_extend_submission_date_hint_n: $localize`:@@pp_core_bidder_extend_submission_date_hint_n:Extension by ?days? days`,
    pp_core_bidder_extend_submission_date_save: $localize`:@@pp_core_bidder_extend_submission_date_save:Extend`,
    pp_core_bidder_extend_submission_date_submission_date: $localize`:@@pp_core_bidder_extend_submission_date_submission_date:Submit offer by`,
    pp_core_bidder_extend_submission_date_tender_format_datetime: $localize`:@@pp_core_bidder_extend_submission_date_tender_format_datetime:dd.MM.y 'at' HH:mm`,
    pp_core_bidder_extend_submission_date_tender_submission_date: $localize`:@@pp_core_bidder_extend_submission_date_tender_submission_date:The submission deadline for the offer is ?tender_submission_date?. If you wish to grant an extension to the company, you can do so here.`,
    pp_core_bidder_extend_submission_date_validation: $localize`:@@pp_core_bidder_extend_submission_date_validation:Date must be in the future after the submission deadline for the offer.`,
    pp_core_bidder_reject_application_inform: $localize`:@@pp_core_bidder_reject_application_inform:Notification`,
    pp_core_bidder_reject_application_inform_hint: $localize`:@@pp_core_bidder_reject_application_inform_hint:By default the company is sent a notification that the application has been rejected. If you do not want this, please deactivate the notification.`,
    pp_core_bidder_reject_application_inform_text: $localize`:@@pp_core_bidder_reject_application_inform_text:send`,
    pp_core_bidder_reject_application_message: $localize`:@@pp_core_bidder_reject_application_message:Explanation`,
    pp_core_bidder_reject_application_message_placeholder: $localize`:@@pp_core_bidder_reject_application_message_placeholder:If you wish, here you can enter a reason for rejecting this company. This reason is sent to the company together with the notification that the application has been rejected.`,
    pp_core_breadcrumb_clients: $localize`:@@pp_core_breadcrumb_clients:Customers`,
    pp_core_breadcrumb_companies: $localize`:@@pp_core_breadcrumb_companies:Companies`,
    pp_core_breadcrumb_edit: $localize`:@@pp_core_breadcrumb_edit:Edit`,
    edit_settings: $localize`:@@edit_settings:Edit Settings`,
    pp_core_breadcrumb_edit_applications: $localize`:@@pp_core_breadcrumb_edit_applications:Edit applications`,
    pp_core_breadcrumb_reject_contractors: $localize`:@@pp_core_breadcrumb_reject_contractors:Reject contractors`,
    pp_core_breadcrumb_create_project_geometry: $localize`:@@pp_core_breadcrumb_create_project_geometry:Create project geometry`,
    pp_core_breadcrumb_view_project_geometry: $localize`:@@pp_core_breadcrumb_view_project_geometry:Project geometry`,
    pp_core_button_accept: $localize`:@@pp_core_button_accept:Accept`,
    pp_core_button_delete: $localize`:@@pp_core_button_delete:Delete`,
    pp_core_button_remove: $localize`:@@pp_core_button_remove:Remove`,
    pp_core_button_apply: $localize`:@@pp_core_button_apply:Apply`,
    pp_core_breadcrumb_tender_list: $localize`:@@pp_core_breadcrumb_tender_list:Tenders`,
    pp_core_button_cancel: $localize`:@@pp_core_button_cancel:Discard`,
    pp_core_button_close: $localize`:@@pp_core_button_close:Close`,
    pp_core_button_create_tender_template: $localize`:@@pp_core_button_create_tender_template:Create project tender template`,
    pp_core_button_details_accept: $localize`:@@pp_core_button_details_accept:Accept application`,
    pp_core_button_details_activate: $localize`:@@pp_core_button_details_activate:Activate`,
    pp_core_button_details_cancel_all: $localize`:@@pp_core_button_details_cancel_all:Reject all contractors`,
    pp_core_button_details_compare_devis: $localize`:@@pp_core_button_details_compare_devis:Specification comparison`,
    pp_core_button_details_compare_offer: $localize`:@@pp_core_button_details_compare_offer:Offer comparison`,
    pp_core_button_details_new_compare_offer: $localize`:@@pp_core_button_details_new_compare_offer:NEW Offer comparison`,
    pp_core_button_details_reject_contractors: $localize`:@@pp_core_button_details_reject_contractors:Reject contractors`,
    pp_core_button_details_start_bidding_round: $localize`:@@pp_core_button_details_start_bidding_round:Start bidding round`,
    pp_core_button_details_create_approval: $localize`:@@pp_core_button_details_create_approval:Create Approval`,
    pp_core_button_details_contracting_list: $localize`:@@pp_core_button_details_contracting_list:Contract award list`,
    pp_core_button_details_create_new_project: $localize`:@@pp_core_button_details_create_new_project:Create project`,
    pp_core_button_details_disable: $localize`:@@pp_core_button_details_disable:Disable`,
    pp_core_button_details_edit: $localize`:@@pp_core_button_details_edit:Edit`,
    pp_core_button_details_edit_more: $localize`:@@pp_core_button_details_edit_more:Edit More Details`,
    pp_core_button_details_replace: $localize`:@@pp_core_button_details_replace:Replace`,
    pp_core_button_details_deactivate: $localize`:@@pp_core_button_details_deactivate:Deactivate`,
    pp_core_button_details_create_project_geometry: $localize`:@@pp_core_button_details_create_project_geometry:Create project geometry`,
    pp_core_button_details_view_project_geometry: $localize`:@@pp_core_button_details_view_project_geometry:Project geometry`,
    pp_core_button_details_close: $localize`:@@pp_core_button_details_close:Close`,
    pp_core_button_details_delete: $localize`:@@pp_core_button_details_delete:Delete`,
    pp_core_button_details_extend_submission_date: $localize`:@@pp_core_button_details_extend_submission_date:Extend submission deadline for offer`,
    pp_core_button_details_impersonate: $localize`:@@pp_core_button_details_impersonate:Register as...`,
    pp_core_button_details_invite_bidder: $localize`:@@pp_core_button_details_invite_bidder:Invite company`,
    pp_core_button_details_edit_applications: $localize`:@@pp_core_button_details_edit_applications:Edit applications`,
    pp_core_button_details_mark_project_off: $localize`:@@pp_core_button_details_mark_project_off:Deselect`,
    pp_core_button_details_mark_project_on: $localize`:@@pp_core_button_details_mark_project_on:Select project`,
    pp_core_button_details_members: $localize`:@@pp_core_button_details_members:Participant list`,
    pp_core_button_details_order_archive: $localize`:@@pp_core_button_details_order_archive:Order project archive`,
    pp_core_button_details_password_reset: $localize`:@@pp_core_button_details_password_reset:Reset password`,
    pp_core_button_details_permissions: $localize`:@@pp_core_button_details_permissions:Manage participant rights`,
    pp_core_button_details_project_list: $localize`:@@pp_core_button_details_project_list:Project list`,
    pp_core_button_details_reject: $localize`:@@pp_core_button_details_reject:Reject application`,
    pp_core_button_details_reject_bidder: $localize`:@@pp_core_button_details_reject_bidder:Remove company`,
    pp_core_button_details_remove_contractor: $localize`:@@pp_core_button_details_remove_contractor:Remove Contractor`,
    pp_core_button_details_renew_invitation: $localize`:@@pp_core_button_details_renew_invitation:Resend invitation`,
    'pp_core_button_details_section-bidder-application': $localize`:@@pp_core_button_details_section-bidder-application:Application`,
    'pp_core_button_details_section-bidder-contact': $localize`:@@pp_core_button_details_section-bidder-contact:Contact`,
    'pp_core_button_details_section-bidder-details': $localize`:@@pp_core_button_details_section-bidder-details:Company`,
    'pp_core_button_details_section-bidder-offers': $localize`:@@pp_core_button_details_section-bidder-offers:Offers`,
    'pp_core_button_details_section-bidder-participation': $localize`:@@pp_core_button_details_section-bidder-participation:Participation`,
    pp_core_button_details_sia_view: $localize`:@@pp_core_button_details_sia_view:View`,
    pp_core_project_main_contact: $localize`:@@pp_core_project_main_contact:Main contact`,
    pp_core_button_details_tender_export_approval: $localize`:@@pp_core_button_details_tender_export_approval:Download Approval`,
    pp_core_button_details_tender_export_all: $localize`:@@pp_core_button_details_tender_export_all:Total export (XLS)`,
    pp_core_button_details_tender_export_offers: $localize`:@@pp_core_button_details_tender_export_offers:Export offer (ZIP)`,
    pp_core_button_details_tender_export_subs: $localize`:@@pp_core_button_details_tender_export_subs:Export contractors (CSV)`,
    pp_core_button_details_tender_export_title: $localize`:@@pp_core_button_details_tender_export_title:Exports`,
    pp_core_button_details_tender_list: $localize`:@@pp_core_button_details_tender_list:Tender list`,
    pp_core_button_details_tender_template: $localize`:@@pp_core_button_details_tender_template:Edit tender template`,
    pp_core_button_details_will_not_offer: $localize`:@@pp_core_button_details_will_not_offer:Deregister companies`,
    pp_core_button_details_will_offer: $localize`:@@pp_core_button_details_will_offer:Company will submit an offer`,
    pp_core_button_details_yellow_pages: $localize`:@@pp_core_button_details_yellow_pages:Entry in the directory`,
    pp_core_button_form_cancel: $localize`:@@pp_core_button_form_cancel:Discard`,
    pp_core_button_form_save: $localize`:@@pp_core_button_form_save:Save`,
    pp_core_bidder_details_internal_comments_tooltip: $localize`:@@pp_core_bidder_details_internal_comments_tooltip:These are internal comments related to the contractor’s participation in this tender. The comments can not be seen by the contractor`,
    pp_core_button_permission_for_new_projects: $localize`:@@pp_core_button_permission_for_new_projects:with regard to new projects`,
    pp_core_button_save: $localize`:@@pp_core_button_save:Save`,
    pp_core_ch16_email_or_fax: $localize`:@@pp_core_ch16_email_or_fax:At least one of the two fields "E-mail" or "Fax" must be filled in.`,
    pp_core_clients: $localize`:@@pp_core_clients:Customers`,
    pp_core_clients_add: $localize`:@@pp_core_clients_add:Add customers`,
    pp_core_clients_branches: $localize`:@@pp_core_clients_branches:Branches`,
    pp_core_clients_branches_add: $localize`:@@pp_core_clients_branches_add:Add branch`,
    pp_core_clients_details: $localize`:@@pp_core_clients_details:Detail view`,
    pp_core_clients_edit: $localize`:@@pp_core_clients_edit:Edit customer`,
    pp_core_clients_members: $localize`:@@pp_core_clients_members:Participant`,
    pp_core_clients_members_add: $localize`:@@pp_core_clients_members_add:Add participants`,
    pp_core_clients_members_select: $localize`:@@pp_core_clients_members_select:Select participants`,
    pp_core_clients_members_details: $localize`:@@pp_core_clients_members_details:Detail view`,
    pp_core_clients_members_overview: $localize`:@@pp_core_clients_members_overview:Summary`,
    pp_core_clients_members_permissions: $localize`:@@pp_core_clients_members_permissions:Rights`,
    pp_core_clients_module_branch_admin: $localize`:@@pp_core_clients_module_branch_admin:Branch management`,
    pp_core_clients_module_contractor: $localize`:@@pp_core_clients_module_contractor:Bidder module`,
    pp_core_clients_module_directory: $localize`:@@pp_core_clients_module_directory:Directory`,
    pp_core_clients_module_members: $localize`:@@pp_core_clients_module_members:Participant management`,
    pp_core_clients_module_publisher: $localize`:@@pp_core_clients_module_publisher:Issuer module`,
    pp_core_clients_module_repro: $localize`:@@pp_core_clients_module_repro:PP`,
    pp_core_clients_module_smart: $localize`:@@pp_core_clients_module_smart:SMART`,
    pp_core_clients_permissions: $localize`:@@pp_core_clients_permissions:Manage participants`,
    pp_core_tender_applications_accepted_success: $localize`:@@pp_core_tender_applications_accepted_success:Application has been successfully accepted.`,
    pp_core_tender_applications_accepted_error: $localize`:@@pp_core_tender_applications_accepted_error:Application cannot be accepted.`,
    pp_core_tender_applications_rejected_success: $localize`:@@pp_core_tender_applications_rejected_success:Application has been successfully rejected.`,
    pp_core_tender_applications_rejected_error: $localize`:@@pp_core_tender_applications_rejected_error:Application cannot be rejected.`,
    pp_core_tender_applications_header_selection: $localize`:@@pp_core_tender_applications_header_selection:Selection`,
    pp_core_tender_applications_header_company: $localize`:@@pp_core_tender_applications_header_company:Company`,
    pp_core_tender_applications_header_receipt: $localize`:@@pp_core_tender_applications_header_receipt:Receipt`,
    pp_core_tender_applications_header_view_details: $localize`:@@pp_core_tender_applications_header_view_details:View Details`,
    pp_core_tender_applications_details_application_text: $localize`:@@pp_core_tender_applications_details_application_text:Application`,
    pp_core_tender_applications_details_attachments: $localize`:@@pp_core_tender_applications_details_attachments:Attachments`,
    pp_core_tender_applications_details_email: $localize`:@@pp_core_tender_applications_details_email:Email`,
    pp_core_tender_applications_details_phone: $localize`:@@pp_core_tender_applications_details_phone:Phone`,
    pp_core_tender_applications_select: $localize`:@@pp_core_tender_applications_select:Select applications`,
    pp_core_tender_applications_state_accept: $localize`:@@pp_core_tender_applications_state_accept:Accept`,
    pp_core_tender_applications_state_reject: $localize`:@@pp_core_tender_applications_state_reject:Reject`,
    pp_core_tender_applications_state_later: $localize`:@@pp_core_tender_applications_state_later:Later`,
    pp_core_tender_applications_overview: $localize`:@@pp_core_tender_applications_overview:Summary`,
    pp_core_tender_applications_select_warning: $localize`:@@pp_core_tender_applications_select_warning:Please accept or reject at least one application`,
    pp_core_tender_applications_select_selection_info: $localize`:@@pp_core_tender_applications_select_selection_info:Selection: Please select the companies whose applications you wish to accept or reject.`,
    pp_core_tender_applications_select_later_info: $localize`:@@pp_core_tender_applications_select_later_info:Later: Applications that you do not yet want to process can be processed at a later date by selecting Later.`,
    pp_core_tender_applications_summary_accepted: $localize`:@@pp_core_tender_applications_summary_accepted:Applications to be accepted`,
    pp_core_tender_applications_summary_rejected: $localize`:@@pp_core_tender_applications_summary_rejected:Applications to be rejected`,
    pp_core_tender_applications_summary_rejected_action: $localize`:@@pp_core_tender_applications_summary_rejected_action:A notification of rejection is sent to the companies.`,
    pp_core_tender_applications_summary_accepted_action: $localize`:@@pp_core_tender_applications_summary_accepted_action:Companies will be invited to tender and will receive a notification of application acceptance.`,
    pp_core_tender_applications_summary_send_notifications: $localize`:@@pp_core_tender_applications_summary_send_notifications:Send notifications`,
    pp_core_tender_applications_summary_multi_success_message: $localize`:@@pp_core_tender_applications_summary_multi_success_message:?quantity? applications were edited.`,
    pp_core_tender_applications_summary_single_success_message: $localize`:@@pp_core_tender_applications_summary_single_success_message:One application was edited.`,
    pp_core_project_geometry_objects: $localize`:@@pp_core_project_geometry_objects:Objects`,
    pp_core_project_geometry_subobjects: $localize`:@@pp_core_project_geometry_subobjects:Sub-objects`,
    pp_core_project_geometry_levels: $localize`:@@pp_core_project_geometry_levels:Levels`,
    pp_core_project_geometry_success_message: $localize`:@@pp_core_project_geometry_success_message:Project geometry successfully saved.`,
    pp_core_project_geometry_object_label_placeholder: $localize`:@@pp_core_project_geometry_object_label_placeholder:Identifier *`,
    pp_core_project_geometry_object_desc_placeholder: $localize`:@@pp_core_project_geometry_object_desc_placeholder:Description *`,
    pp_core_project_geometry_required_message: $localize`:@@pp_core_project_geometry_required_message:Enter a value for identifier and description.`,
    pp_core_project_geometry_contains_dash_message: $localize`:@@pp_core_project_geometry_contains_dash_message:Dashes are not allowed for the identifier.`,
    pp_core_country_albania: $localize`:@@pp_core_country_albania:Albania`,
    pp_core_country_andorra: $localize`:@@pp_core_country_andorra:Andorra`,
    pp_core_country_austria: $localize`:@@pp_core_country_austria:Austria`,
    pp_core_country_belgium: $localize`:@@pp_core_country_belgium:Belgium`,
    pp_core_country_bosnia_and_herzegovina: $localize`:@@pp_core_country_bosnia_and_herzegovina:Bosnia and Herzegovina`,
    pp_core_country_bulgaria: $localize`:@@pp_core_country_bulgaria:Bulgaria`,
    pp_core_country_croatia: $localize`:@@pp_core_country_croatia:Croatia`,
    pp_core_country_czech_republic: $localize`:@@pp_core_country_czech_republic:Czech Republic`,
    pp_core_country_denmark: $localize`:@@pp_core_country_denmark:Denmark`,
    pp_core_country_estonia: $localize`:@@pp_core_country_estonia:Estonia`,
    pp_core_country_finland: $localize`:@@pp_core_country_finland:Finland`,
    pp_core_country_france: $localize`:@@pp_core_country_france:France`,
    pp_core_country_germany: $localize`:@@pp_core_country_germany:Germany`,
    pp_core_country_greece: $localize`:@@pp_core_country_greece:Greece`,
    pp_core_country_hungary: $localize`:@@pp_core_country_hungary:Hungary`,
    pp_core_country_ireland: $localize`:@@pp_core_country_ireland:Ireland`,
    pp_core_country_island: $localize`:@@pp_core_country_island:Island`,
    pp_core_country_italy: $localize`:@@pp_core_country_italy:Italy`,
    pp_core_country_lativa: $localize`:@@pp_core_country_lativa:Lativa`,
    pp_core_country_liechtenstein: $localize`:@@pp_core_country_liechtenstein:Liechtenstein`,
    pp_core_country_lithuania: $localize`:@@pp_core_country_lithuania:Lithuania`,
    pp_core_country_luxembourg: $localize`:@@pp_core_country_luxembourg:Luxembourg`,
    pp_core_country_macedonia: $localize`:@@pp_core_country_macedonia:Macedonia`,
    pp_core_country_malta: $localize`:@@pp_core_country_malta:Malta`,
    pp_core_country_moldova: $localize`:@@pp_core_country_moldova:Moldova`,
    pp_core_country_montenegro: $localize`:@@pp_core_country_montenegro:Montenegro`,
    pp_core_country_netherlands: $localize`:@@pp_core_country_netherlands:Netherlands`,
    pp_core_country_norway: $localize`:@@pp_core_country_norway:Norway`,
    pp_core_country_poland: $localize`:@@pp_core_country_poland:Poland`,
    pp_core_country_portugal: $localize`:@@pp_core_country_portugal:Portugal`,
    pp_core_country_principality_of_monaco: $localize`:@@pp_core_country_principality_of_monaco:Principality of Monaco`,
    pp_core_country_romania: $localize`:@@pp_core_country_romania:Romania`,
    pp_core_country_san_marino: $localize`:@@pp_core_country_san_marino:San Marino`,
    pp_core_country_serbia: $localize`:@@pp_core_country_serbia:Serbia`,
    pp_core_country_slovakia: $localize`:@@pp_core_country_slovakia:Slovakia`,
    pp_core_country_slovenia: $localize`:@@pp_core_country_slovenia:Slovenia`,
    pp_core_country_spain: $localize`:@@pp_core_country_spain:Spain`,
    pp_core_country_sweden: $localize`:@@pp_core_country_sweden:Sweden`,
    pp_core_country_switzerland: $localize`:@@pp_core_country_switzerland:Switzerland`,
    pp_core_country_turkey: $localize`:@@pp_core_country_turkey:Turkey`,
    pp_core_country_ukraine: $localize`:@@pp_core_country_ukraine:Ukraine`,
    pp_core_country_united_kingdom: $localize`:@@pp_core_country_united_kingdom:United Kingdom`,
    pp_core_country_vatican_city: $localize`:@@pp_core_country_vatican_city:Vatican City`,
    pp_core_deductions_validation: $localize`:@@pp_core_deductions_validation:Please enter either percentage or absolute value!`,
    pp_core_deductions_validation_min: $localize`:@@pp_core_deductions_validation_min:Please enter a positive value!`,
    pp_core_deductions_validation_percent: $localize`:@@pp_core_deductions_validation_percent:Values must be between 0% and 100%!`,
    pp_core_detail_integrated_in: $localize`:@@pp_core_detail_integrated_in:Integrated in`,
    pp_core_detail_label_application: $localize`:@@pp_core_detail_label_application:Application`,
    pp_core_detail_label_attachments: $localize`:@@pp_core_detail_label_attachments:Attachments`,
    pp_core_detail_section_activities: $localize`:@@pp_core_detail_section_activities:Activity`,
    pp_core_detail_section_billing_address: $localize`:@@pp_core_detail_section_billing_address:Billing address`,
    pp_core_detail_section_branches: $localize`:@@pp_core_detail_section_branches:Branches`,
    pp_core_detail_section_calc_visible_info_off: $localize`:@@pp_core_detail_section_calc_visible_info_off:No contractors are allowed to see the "Calculation" information.`,
    pp_core_detail_section_calc_visible_info_on: $localize`:@@pp_core_detail_section_calc_visible_info_on:Contractors are allowed to see the "Calculation" information.`,
    pp_core_detail_section_client_profile: $localize`:@@pp_core_detail_section_client_profile:Customer profile`,
    pp_core_detail_section_company: $localize`:@@pp_core_detail_section_company:Company`,
    pp_core_detail_section_contractor_info_off: $localize`:@@pp_core_detail_section_contractor_info_off:No contractors are allowed to trigger "Supplier requests" via OLMeRO.`,
    pp_core_detail_section_contractor_info_on: $localize`:@@pp_core_detail_section_contractor_info_on:Contractors are allowed to trigger supplier requests via OLMeRO.`,
    pp_core_detail_section_default_authorities: $localize`:@@pp_core_detail_section_default_authorities:Default customer rights`,
    pp_core_detail_section_details: $localize`:@@pp_core_detail_section_details:Details`,
    pp_core_detail_section_journals: $localize`:@@pp_core_detail_section_journals:Log`,
    pp_core_detail_section_login: $localize`:@@pp_core_detail_section_login:Login`,
    pp_core_detail_section_login_name: $localize`:@@pp_core_detail_section_login_name:Login`,
    pp_core_detail_section_main: $localize`:@@pp_core_detail_section_main:Head office`,
    pp_core_detail_section_masterdata: $localize`:@@pp_core_detail_section_masterdata:Details`,
    pp_core_detail_section_modules: $localize`:@@pp_core_detail_section_modules:Modules`,
    pp_core_detail_section_theme: $localize`:@@pp_core_detail_section_theme:Theme`,
    pp_core_detail_section_settings: $localize`:@@pp_core_detail_section_settings:Settings`,
    pp_core_detail_section_password: $localize`:@@pp_core_detail_section_password:Password`,
    pp_core_detail_section_password_change: $localize`:@@pp_core_detail_section_password_change:Change password`,
    pp_core_detail_section_password_change_success: $localize`:@@pp_core_detail_section_password_change_success:Your password has been changed.`,
    pp_core_detail_section_password_change_cancelled: $localize`:@@pp_core_detail_section_password_change_cancelled:Your password has not been changed.`,
    pp_core_detail_submission_input_yes: $localize`:@@pp_core_detail_submission_input_yes:also allow by post`,
    pp_core_detail_tender_questions_allowed_no: $localize`:@@pp_core_detail_tender_questions_allowed_no:Do not allow bidder questions`,
    pp_core_detail_tender_questions_allowed_yes: $localize`:@@pp_core_detail_tender_questions_allowed_yes:Allow bidder questions`,
    pp_core_edit_project: $localize`:@@pp_core_edit_project:Create project`,
    pp_core_edit_tender_template: $localize`:@@pp_core_edit_tender_template:Create tender template`,
    pp_core_select_client: $localize`:@@pp_core_select_client:Choose a client`,
    pp_core_eh06_no_entries: $localize`:@@pp_core_eh06_no_entries:No existing entries.`,
    pp_core_form_clientoptions_no_more_options: $localize`:@@pp_core_form_clientoptions_no_more_options:There are no further modules that can be added.`,
    pp_core_form_deductions_add_more: $localize`:@@pp_core_form_deductions_add_more:Add`,
    pp_core_form_defaultauthorities_no_more_options: $localize`:@@pp_core_form_defaultauthorities_no_more_options:There are no further customer rights that can be added.`,
    pp_core_form_dismissed: $localize`:@@pp_core_form_dismissed:Data has been dismissed.`,
    pp_core_form_label_contact_person: $localize`:@@pp_core_form_label_contact_person:Contact Person`,
    pp_core_form_label_address: $localize`:@@pp_core_form_label_address:Address`,
    pp_core_form_label_address_additional_contact: $localize`:@@pp_core_form_label_address_additional_contact:Contact person`,
    pp_core_form_label_vat_number: $localize`:@@pp_core_form_label_vat_number:VAT Number`,
    pp_core_form_label_address_email: $localize`:@@pp_core_form_label_address_email:E-mail`,
    pp_core_form_label_address_fax: $localize`:@@pp_core_form_label_address_fax:Fax`,
    pp_core_form_label_address_firstname: $localize`:@@pp_core_form_label_address_firstname:First name`,
    pp_core_form_label_address_function: $localize`:@@pp_core_form_label_address_function:Function`,
    pp_core_form_label_address_language: $localize`:@@pp_core_form_label_address_language:Language`,
    pp_core_form_label_address_lastname: $localize`:@@pp_core_form_label_address_lastname:Last name`,
    pp_core_form_label_address_mobile: $localize`:@@pp_core_form_label_address_mobile:Mobile`,
    pp_core_form_label_address_phone: $localize`:@@pp_core_form_label_address_phone:Phone`,
    pp_core_form_label_address_title: $localize`:@@pp_core_form_label_address_title:Title`,
    pp_core_form_label_address_olmero_id: $localize`:@@pp_core_form_label_address_olmero_id:Olmero ID`,
    pp_core_form_label_attachment_cat_attachment: $localize`:@@pp_core_form_label_attachment_cat_attachment:Attachments`,
    pp_core_form_label_attachment_cat_candidate_information: $localize`:@@pp_core_form_label_attachment_cat_candidate_information:Candidate information`,
    pp_core_form_label_attachment_cat_general_terms: $localize`:@@pp_core_form_label_attachment_cat_general_terms:General terms`,
    pp_core_form_label_attachment_cat_new: $localize`:@@pp_core_form_label_attachment_cat_new:No category`,
    pp_core_form_label_attachment_cat_new_attachment: $localize`:@@pp_core_form_label_attachment_cat_new_attachment:New attachments`,
    pp_core_form_label_attachment_cat_plan: $localize`:@@pp_core_form_label_attachment_cat_plan:Plans`,
    pp_core_form_label_attachment_cat_special_terms: $localize`:@@pp_core_form_label_attachment_cat_special_terms:Special terms`,
    pp_core_form_label_attachment_cat_work_description: $localize`:@@pp_core_form_label_attachment_cat_work_description:Work description`,
    pp_core_form_label_attachment_category: $localize`:@@pp_core_form_label_attachment_category:Category`,
    pp_core_form_label_attachment_description: $localize`:@@pp_core_form_label_attachment_description:Description`,
    pp_core_form_label_attachment_name: $localize`:@@pp_core_form_label_attachment_name:Attachment`,
    pp_core_form_label_bidder_participate_application_date: $localize`:@@pp_core_form_label_bidder_participate_application_date:Application dated`,
    pp_core_form_label_bidder_participate_inspection_date: $localize`:@@pp_core_form_label_bidder_participate_inspection_date:Inspection`,
    pp_core_form_label_bidder_participate_offer_date: $localize`:@@pp_core_form_label_bidder_participate_offer_date:Submit offer by`,
    pp_core_form_label_bidder_participate_rejected_date: $localize`:@@pp_core_form_label_bidder_participate_rejected_date:Rejected on`,
    pp_core_form_label_bidder_participate_rejection_message: $localize`:@@pp_core_form_label_bidder_participate_rejection_message:Reason for rejection`,
    pp_core_form_label_bidder_participate_unsubscribe_date: $localize`:@@pp_core_form_label_bidder_participate_unsubscribe_date:Unsubscribed on`,
    pp_core_form_label_bidder_participate_unsubscribed_text: $localize`:@@pp_core_form_label_bidder_participate_unsubscribed_text:Reason`,
    pp_core_form_label_bidder_delivery_notification: $localize`:@@pp_core_form_label_bidder_delivery_notification:Delivery of  notification`,
    pp_core_form_label_bidder_error_message: $localize`:@@pp_core_form_label_bidder_error_message:Error message`,
    pp_core_form_label_billing_type: $localize`:@@pp_core_form_label_billing_type:Billing model`,
    pp_core_form_label_billing_type_ap: $localize`:@@pp_core_form_label_billing_type_ap:AP`,
    pp_core_form_label_billing_type_none: $localize`:@@pp_core_form_label_billing_type_none:none`,
    pp_core_form_label_billing_type_pp: $localize`:@@pp_core_form_label_billing_type_pp:PP`,
    pp_core_form_label_branch_type: $localize`:@@pp_core_form_label_branch_type:Type`,
    pp_core_form_label_city: $localize`:@@pp_core_form_label_city:Place`,
    pp_core_form_label_clientoptions_add_more: $localize`:@@pp_core_form_label_clientoptions_add_more:Add`,
    pp_core_form_label_clientoptions_title_add_more: $localize`:@@pp_core_form_label_clientoptions_title_add_more:Add module`,
    pp_core_form_label_company: $localize`:@@pp_core_form_label_company:Name`,
    pp_core_form_label_country: $localize`:@@pp_core_form_label_country:Country`,
    pp_core_form_label_deductions_discount: $localize`:@@pp_core_form_label_deductions_discount:Discount period`,
    pp_core_form_label_deductions_discount_help: $localize`:@@pp_core_form_label_deductions_discount_help:The discount period is specified in number of days. No specification means that the discount period is indefinite.`,
    pp_core_form_label_deductions_hint: $localize`:@@pp_core_form_label_deductions_hint:Please specify either a percentage of the offer sum or a lump sum as a deduction.`,
    pp_core_form_label_deductions_vat: $localize`:@@pp_core_form_label_deductions_vat:VAT rate`,
    pp_core_form_label_defaultauthorities_add_more: $localize`:@@pp_core_form_label_defaultauthorities_add_more:Add`,
    pp_core_form_label_defaultauthorities_title_add_more: $localize`:@@pp_core_form_label_defaultauthorities_title_add_more:Add default customer rights`,
    pp_core_form_label_department: $localize`:@@pp_core_form_label_department:Department`,
    pp_core_form_label_domain: $localize`:@@pp_core_form_label_domain:Domain`,
    pp_core_form_label_olmero_id: $localize`:@@pp_core_form_label_olmero_id:OLMeRO ID`,
    pp_core_form_label_project_building_site: $localize`:@@pp_core_form_label_project_building_site:Building site`,
    pp_core_form_label_project_building_site_address: $localize`:@@pp_core_form_label_project_building_site_address:Building site address`,
    pp_core_form_label_project_building_type_add: $localize`:@@pp_core_form_label_project_building_type_add:add`,
    pp_core_form_label_project_building_types: $localize`:@@pp_core_form_label_project_building_types:Building type`,
    pp_core_form_label_project_building_types_add: $localize`:@@pp_core_form_label_project_building_types_add:Select Building Types`,
    pp_core_form_label_project_construction_address: $localize`:@@pp_core_form_label_project_construction_address:Building site`,
    pp_core_form_label_project_construction_end: $localize`:@@pp_core_form_label_project_construction_end:End of construction`,
    pp_core_form_label_project_construction_start: $localize`:@@pp_core_form_label_project_construction_start:Start of construction`,
    pp_core_form_label_project_construction_volume: $localize`:@@pp_core_form_label_project_construction_volume:Order volume`,
    pp_core_form_label_project_constructor: $localize`:@@pp_core_form_label_project_constructor:Construction owner`,
    pp_core_form_label_project_constructor_address: $localize`:@@pp_core_form_label_project_constructor_address:Construction owner address`,
    pp_core_form_label_project_constructor_department: $localize`:@@pp_core_form_label_project_constructor_department:Construction owner department`,
    pp_core_form_label_project_constructor_name: $localize`:@@pp_core_form_label_project_constructor_name:Construction owner name`,
    pp_core_form_label_project_contact_person_add: $localize`:@@pp_core_form_label_project_contact_person_add:Add`,
    pp_core_form_label_project_contact_person_function: $localize`:@@pp_core_form_label_project_contact_person_function:Function`,
    pp_core_form_label_project_contact_person_person: $localize`:@@pp_core_form_label_project_contact_person_person:Person`,
    pp_core_form_label_project_description: $localize`:@@pp_core_form_label_project_description:Project description`,
    pp_core_form_label_project_name: $localize`:@@pp_core_form_label_project_name:Project name`,
    pp_core_form_label_project_new_offers_period: $localize`:@@pp_core_form_label_project_new_offers_period:Within the last 5 days`,
    pp_core_form_label_project_number: $localize`:@@pp_core_form_label_project_number:Project number`,
    pp_core_form_label_project_object_type: $localize`:@@pp_core_form_label_project_object_type:Object type`,
    pp_core_form_label_project_phase: $localize`:@@pp_core_form_label_project_phase:Phase`,
    pp_core_form_label_project_tender_calculation_visible: $localize`:@@pp_core_form_label_project_tender_calculation_visible:Contractors are allowed to see the "Calculation" information.`,
    pp_core_form_label_project_tender_informations_allowed: $localize`:@@pp_core_form_label_project_tender_informations_allowed:Supplier questions`,
    pp_core_form_label_project_tender_informations_allowed_hint: $localize`:@@pp_core_form_label_project_tender_informations_allowed_hint:On the OLMeRO platform, contractors are able to invite potential suppliers to support them with attractive offers and advice when preparing the offer, so as to promote competition for the benefit of low offer prices. However, it should be pointed out that with this, contractor information regarding the respective tender, the tender will be visible for suppliers.`,
    pp_core_form_label_project_tender_informations_allowed_text: $localize`:@@pp_core_form_label_project_tender_informations_allowed_text:Contractors involved in a tender are allowed to trigger "Supplier requests" via OLMeRO.`,
    pp_core_form_label_save_error: $localize`:@@pp_core_form_label_save_error:Save error`,
    pp_core_form_label_state: $localize`:@@pp_core_form_label_state:States`,
    pp_core_form_label_last_login: $localize`:@@pp_core_form_label_last_login:Last Login`,
    pp_core_form_label_state_applied: $localize`:@@pp_core_form_label_state_applied:applied`,
    pp_core_form_label_state_invited: $localize`:@@pp_core_form_label_state_invited:Open`,
    pp_core_form_label_state_offered: $localize`:@@pp_core_form_label_state_offered:offered`,
    pp_core_form_label_state_rejected: $localize`:@@pp_core_form_label_state_rejected:rejected`,
    pp_core_form_label_state_offer_rejected: $localize`:@@pp_core_form_label_state_offer_rejected:rejected`,
    pp_core_form_label_state_application_rejected: $localize`:@@pp_core_form_label_state_application_rejected:application rejected`,
    pp_core_form_label_state_signed_off: $localize`:@@pp_core_form_label_state_signed_off:signed off`,
    pp_core_form_label_state_will_offer: $localize`:@@pp_core_form_label_state_will_offer:will submit an offer`,
    pp_core_form_label_state_bidding_round: $localize`:@@pp_core_form_label_state_bidding_round:bidding round`,
    pp_core_form_label_street: $localize`:@@pp_core_form_label_street:Street and number`,
    pp_core_form_label_tender_accepting_date: $localize`:@@pp_core_form_label_tender_accepting_date:To be awarded on`,
    pp_core_form_label_tender_application_date: $localize`:@@pp_core_form_label_tender_application_date:Submit application by`,
    pp_core_form_label_tender_application_date_error: $localize`:@@pp_core_form_label_tender_application_date_error:"Submit application by" should precede "Feedback by" and "Submit offer by".`,
    pp_core_form_label_tender_change_message: $localize`:@@pp_core_form_label_tender_change_message:Changes`,
    pp_core_form_label_tender_rejection_message: $localize`:@@pp_core_form_label_tender_rejection_message:Rejection message`,
    pp_core_form_label_tender_change_message_placeholder: $localize`:@@pp_core_form_label_tender_change_message_placeholder:Describe the changes you have made.`,
    pp_core_form_label_tender_comment: $localize`:@@pp_core_form_label_tender_comment:Comment on the tender`,
    pp_core_form_label_tender_description: $localize`:@@pp_core_form_label_tender_description:Technical specification`,
    pp_core_form_label_tender_description_hint: $localize`:@@pp_core_form_label_tender_description_hint:First, enter the BKP no. (building estimate no.), followed by an explanatory technical specification here.`,
    pp_core_form_label_tender_description_placeholder: $localize`:@@pp_core_form_label_tender_description_placeholder:BKP 281.7 Wooden flooring: 550 m2 beech parquet floor`,
    pp_core_form_label_tender_feedback_date: $localize`:@@pp_core_form_label_tender_feedback_date:Feedback by`,
    pp_core_form_label_tender_feedback_date_error_with_application_date: $localize`:@@pp_core_form_label_tender_feedback_date_error_with_application_date:"Feedback by" must be after "Submit application by" and before "Submit offer by"`,
    pp_core_form_label_tender_feedback_date_error_without_application_date: $localize`:@@pp_core_form_label_tender_feedback_date_error_without_application_date:"Feedback by" must be before "Submit offer by"`,
    pp_core_form_label_tender_feedback_date_hint: $localize`:@@pp_core_form_label_tender_feedback_date_hint:Feedback by invited contractor as to whether they will submit an offer or not.`,
    pp_core_form_label_tender_kind: $localize`:@@pp_core_form_label_tender_kind:Private tender`,
    pp_core_form_label_tender_kind_hint: $localize`:@@pp_core_form_label_tender_kind_hint:Tenders by principals under private law who are not subject to the public procurement regulations (e.g. the Swiss Federal Act on Public Procurement [BoeB] or the Swiss Ordinance on Public Procurement [VoeB]).`,
    pp_core_form_label_tender_kind_invite: $localize`:@@pp_core_form_label_tender_kind_invite:Invitation process`,
    pp_core_form_label_tender_kind_invite_hint: $localize`:@@pp_core_form_label_tender_kind_invite_hint:Only explicitly invited contractors are notified of your tender and can access your tender documents.`,
    pp_core_form_label_tender_kind_openly: $localize`:@@pp_core_form_label_tender_kind_openly:Open process`,
    pp_core_form_label_tender_kind_openly_hint: $localize`:@@pp_core_form_label_tender_kind_openly_hint:All contractors registered with OLMeRO are granted access to your tender documents.`,
    pp_core_form_label_tender_kind_selective: $localize`:@@pp_core_form_label_tender_kind_selective:Selective process`,
    pp_core_form_label_tender_kind_selective_hint: $localize`:@@pp_core_form_label_tender_kind_selective_hint:Only invited contractors, who have been accepted following an application, are granted access to the tender documents.`,
    pp_core_form_label_tender_number: $localize`:@@pp_core_form_label_tender_number:Tender number`,
    pp_core_form_label_offer_inspection: $localize`:@@pp_core_form_label_offer_inspection:Inspection`,
    pp_core_form_label_tender_offer_date: $localize`:@@pp_core_form_label_tender_offer_date:Offers are valid until`,
    pp_core_form_label_tender_phase: $localize`:@@pp_core_form_label_tender_phase:Phase`,
    pp_core_form_label_tender_estimated_budget: $localize`:@@pp_core_form_label_tender_estimated_budget:Tender estimated budget`,
    pp_core_form_label_tender_estimated_budget_hint: $localize`:@@pp_core_form_label_tender_estimated_budget_hint:This value will not be published, it is only used for calculating the rest budget for the project and as indicator during the awarding process`,
    pp_core_form_label_tender_questions_allowed: $localize`:@@pp_core_form_label_tender_questions_allowed:Contractor questions`,
    pp_core_form_label_tender_questions_allowed_hint: $localize`:@@pp_core_form_label_tender_questions_allowed_hint:allow`,
    pp_core_form_label_tender_release: $localize`:@@pp_core_form_label_tender_release:Publication (release)`,
    pp_core_form_label_tender_release_changed: $localize`:@@pp_core_form_label_tender_release_changed:Publication (release)`,
    pp_core_form_label_tender_release_hint: $localize`:@@pp_core_form_label_tender_release_hint:By default, the tender is not published when it is saved. If you wish to publish the tender, please activate the publication (release).`,
    pp_core_form_label_tender_inspection_hint: $localize`:@@pp_core_form_label_tender_inspection_hint:By default, you can view all incoming offers immediately. If you want to allow viewing only after the offer submission deadline, please deactivate the inspection.`,
    pp_core_form_label_tender_release_saved: $localize`:@@pp_core_form_label_tender_release_saved:saved in the cache`,
    pp_core_form_label_tender_release_text: $localize`:@@pp_core_form_label_tender_release_text:Activate publication (release)`,
    pp_core_form_label_tender_inspection_text: $localize`:@@pp_core_form_label_tender_inspection_text:Immediate inspection allowed`,
    pp_core_form_label_tender_reminder_time: $localize`:@@pp_core_form_label_tender_reminder_time:Reminder lead time`,
    pp_core_form_label_tender_reminder_time_hint: $localize`:@@pp_core_form_label_tender_reminder_time_hint:Automatic reminder of the invited bidders to submit their offer in hours prior to the submission deadline. No specification means that no automatic reminder will be sent.`,
    pp_core_form_label_tender_reminder_time_unit: $localize`:@@pp_core_form_label_tender_reminder_time_unit:Hours`,
    pp_core_form_label_tender_skills: $localize`:@@pp_core_form_label_tender_skills:Type of work`,
    pp_core_form_label_tender_skills_add_more: $localize`:@@pp_core_form_label_tender_skills_add_more:Add`,
    pp_core_form_label_tender_skills_minskills: $localize`:@@pp_core_form_label_tender_skills_minskills:You must add at least one type of work!`,
    pp_core_form_label_tender_submission_address: $localize`:@@pp_core_form_label_tender_submission_address:Submission address for offers`,
    pp_core_form_label_tender_submission_company: $localize`:@@pp_core_form_label_tender_submission_company:Company name`,
    pp_core_form_label_tender_submission_date: $localize`:@@pp_core_form_label_tender_submission_date:Submit offer by`,
    pp_core_form_label_tender_submission_department: $localize`:@@pp_core_form_label_tender_submission_department:Department/branch`,
    pp_core_form_label_tender_submission_input: $localize`:@@pp_core_form_label_tender_submission_input:Offer submission`,
    pp_core_form_label_tender_submission_input_hint: $localize`:@@pp_core_form_label_tender_submission_input_hint:also allow by post`,
    pp_core_form_label_tender_workstart: $localize`:@@pp_core_form_label_tender_workstart:Start of work on`,
    pp_core_form_label_theme: $localize`:@@pp_core_form_label_theme:Theme`,
    pp_core_form_label_upload_hint: $localize`:@@pp_core_form_label_upload_hint:Drag and drop documents onto this field or upload them from your hard drive.`,
    pp_core_form_label_zip: $localize`:@@pp_core_form_label_zip:Postcode`,
    pp_core_form_label_zip_city: $localize`:@@pp_core_form_label_zip_city:Postcode/town`,
    pp_core_form_label_types_of_work: $localize`:@@pp_core_form_label_types_of_work:Types of Work`,
    pp_core_form_label_areas_of_work: $localize`:@@pp_core_form_label_areas_of_work:Areas of Work`,
    pp_core_form_min_building_type: $localize`:@@pp_core_form_min_building_type:You must add at least one type of building!`,
    pp_core_form_min_contact: $localize`:@@pp_core_form_min_contact:You must add at least one contact person!`,
    pp_core_form_min_contact_template: $localize`:@@pp_core_form_min_contact_template:You must add at least one contact person from the issuing company.`,
    pp_core_form_msg_unknown_error: $localize`:@@pp_core_form_msg_unknown_error:Unknown error`,
    pp_core_form_no_changes: $localize`:@@pp_core_form_no_changes:Nothing to save as no changes have been made.`,
    pp_core_form_process_validation_errors: $localize`:@@pp_core_form_process_validation_errors:Please correct all input errors.`,
    pp_core_form_project_contact_person_function_contact_person: $localize`:@@pp_core_form_project_contact_person_function_contact_person:Contact person`,
    pp_core_form_saved: $localize`:@@pp_core_form_saved:The data entered has been saved.`,
    pp_core_form_tender_approval_date: $localize`:@@pp_core_form_tender_approval_date:on`,
    pp_core_form_tender_approval_date_at: $localize`:@@pp_core_form_tender_approval_date_at:at`,
    pp_core_form_tender_approval_immediately: $localize`:@@pp_core_form_tender_approval_immediately:immediately`,
    pp_core_form_tender_approval_saved: $localize`:@@pp_core_form_tender_approval_saved:saved in the cache (not released)`,
    pp_core_form_text_theme_description: $localize`:@@pp_core_form_text_theme_description:The branding currently only has an impact on the PP module.`,
    pp_core_form_validation_errors: $localize`:@@pp_core_form_validation_errors:Cannot save! Please correct all input errors.`,
    pp_core_global_alert_password_reset_done: $localize`:@@pp_core_global_alert_password_reset_done:E-mail has been sent to the participant.`,
    pp_core_global_alert_password_reset_error: $localize`:@@pp_core_global_alert_password_reset_error:E-mail could not be sent.`,
    pp_core_global_alert_user_switched: $localize`:@@pp_core_global_alert_user_switched:Logged in as: ?user?`,
    pp_core_global_format_date: $localize`:@@pp_core_global_format_date:dd.MM.y`,
    pp_core_global_format_datetime: $localize`:@@pp_core_global_format_datetime:dd.MM.y HH:mm`,
    pp_core_global_language_de: $localize`:@@pp_core_global_language_de:German`,
    pp_core_global_language_fr: $localize`:@@pp_core_global_language_fr:French`,
    pp_core_global_language_it: $localize`:@@pp_core_global_language_it:Italian`,
    pp_core_global_language_en: $localize`:@@pp_core_global_language_en:English`,
    pp_core_global_forbidden: $localize`:@@pp_core_global_forbidden:You have no rights to access this page.`,
    pp_core_global_not_found: $localize`:@@pp_core_global_not_found:Page not found!`,
    pp_core_global_unauthorized: $localize`:@@pp_core_global_unauthorized:Please log in again!`,
    pp_core_global_under_construction: $localize`:@@pp_core_global_under_construction:Page under construction.`,
    pp_core_global_server_error: $localize`:@@pp_core_global_server_error:An unexpected error has occurred`,
    pp_core_global_server_error_sorry: $localize`:@@pp_core_global_server_error_sorry:Sorry - this wasn\'t supposed to happen...`,
    pp_core_global_server_error_support: $localize`:@@pp_core_global_server_error_support:We\'ve got this incident logged now and are already working on fixing the problem. If you need immediate help from our customer service team, please contact our Hotline ?hotline? or international: ?international_hotline?. Accessible from monday to friday from ?morning_hours? and ?evening_hours? o\'clock. We apologize for the inconvenience.`,
    pp_core_global_unknown_error_snackbar: $localize`:@@pp_core_global_unknown_error_snackbar:An unexpected error has occurred. Please try again.`,
    pp_core_invite_bidder_add_client: $localize`:@@pp_core_invite_bidder_add_client:Add company`,
    pp_core_invite_bidder_ah20_1_no_clients_added: $localize`:@@pp_core_invite_bidder_ah20_1_no_clients_added:No companies added yet.`,
    pp_core_invite_bidder_ah20_2_selection: $localize`:@@pp_core_invite_bidder_ah20_2_selection:The companies are invited to tender.`,
    pp_core_invite_bidder_ah20_3_invite: $localize`:@@pp_core_invite_bidder_ah20_3_invite:All companies selected and entered in the private directory automatically receive an invitation for an already published tender.`,
    pp_core_invite_bidder_ah20_4_create: $localize`:@@pp_core_invite_bidder_ah20_4_create:If you wish to invite companies that are not registered with OLMeRO, you can create and invite these companies now. Subsequently, these companies will be available for future invitations in your private directory but cannot be found by third-party companies.`,
    pp_core_invite_bidder_ah20_6_duplicate_email: $localize`:@@pp_core_invite_bidder_ah20_6_duplicate_email:There is already a company with this e-mail address.`,
    pp_core_invite_bidder_ah20_6_duplicate_fax: $localize`:@@pp_core_invite_bidder_ah20_6_duplicate_fax:There is already a company with this fax number.`,
    pp_core_invite_bidder_ah20_6_duplicate_select_client: $localize`:@@pp_core_invite_bidder_ah20_6_duplicate_select_client:Select this company?`,
    pp_core_invite_bidder_ah20_7_client_is_created: $localize`:@@pp_core_invite_bidder_ah20_7_client_is_created:Company is created in the private directory.`,
    pp_core_invite_bidder_ah20_8_success1: $localize`:@@pp_core_invite_bidder_ah20_8_success1:Another company has been invited.`,
    pp_core_invite_bidder_ah20_8_success2: $localize`:@@pp_core_invite_bidder_ah20_8_success2:?bidder_count? more companies have been invited.`,
    pp_core_invite_bidder_ah20_no_clients_chosen: $localize`:@@pp_core_invite_bidder_ah20_no_clients_chosen:No companies have been selected yet.`,
    pp_core_invite_bidder_choose_client: $localize`:@@pp_core_invite_bidder_choose_client:Select company`,
    pp_core_invite_bidder_choose_client_placeholder: $localize`:@@pp_core_invite_bidder_choose_client_placeholder:Name, department, postcode, town`,
    pp_core_invite_bidder_choose_client_skill: $localize`:@@pp_core_invite_bidder_choose_client_skill:Type of work`,
    pp_core_invite_bidder_choose_reset_the_type_of_work: $localize`:@@pp_core_invite_bidder_choose_reset_the_type_of_work:Reset the type of work`,
    pp_core_invite_bidder_chosen_clients: $localize`:@@pp_core_invite_bidder_chosen_clients:Selected companies`,
    pp_core_invite_bidder_company: $localize`:@@pp_core_invite_bidder_company:Company`,
    pp_core_invite_bidder_create_client: $localize`:@@pp_core_invite_bidder_create_client:Create company`,
    pp_core_invite_bidder_duplicate_invited: $localize`:@@pp_core_invite_bidder_duplicate_invited:Company has already been invited`,
    pp_core_invite_bidder_already_existing_on_platform: $localize`:@@pp_core_invite_bidder_already_existing_on_platform:There is already a company with this e-mail address.`,
    pp_core_invite_bidder_duplicated_new_contractor_on_form: $localize`:@@pp_core_invite_bidder_duplicated_new_contractor_on_form:There is already a new contractor with this email address in the form.`,
    invite_bidder_already_selected: $localize`:@@invite_bidder_already_selected:Company has already been selected`,
    pp_core_invite_bidder_new_client: $localize`:@@pp_core_invite_bidder_new_client:New company`,
    pp_core_invite_bidder_overview: $localize`:@@pp_core_invite_bidder_overview:Summary`,
    pp_core_invite_bidder_overview_title: $localize`:@@pp_core_invite_bidder_overview_title:Company`,
    pp_core_invite_bidder_profile: $localize`:@@pp_core_invite_bidder_profile:Profile`,
    pp_core_invite_bidder_error: $localize`:@@pp_core_invite_bidder_error:This company is blacklisted and can not be invited. Please remove it from the selection`,
    pp_core_invite_bidder_save: $localize`:@@pp_core_invite_bidder_save:Invite company`,
    pp_core_invite_bidder_seal_of_approval: $localize`:@@pp_core_invite_bidder_seal_of_approval:Seal`,
    pp_core_invite_bidder_title: $localize`:@@pp_core_invite_bidder_title:Invite company`,
    pp_core_invite_bidder_search_company: $localize`:@@pp_core_invite_bidder_search_company:Search company`,
    pp_core_label_project_contract_volume_from_250m: $localize`:@@pp_core_label_project_contract_volume_from_250m:more than 250 million`,
    pp_core_label_project_contract_volume_not_provided: $localize`:@@pp_core_label_project_contract_volume_not_provided:not specified`,
    pp_core_label_project_contract_volume_up_to_100k: $localize`:@@pp_core_label_project_contract_volume_up_to_100k:up to 100,000`,
    pp_core_label_project_contract_volume_up_to_100m: $localize`:@@pp_core_label_project_contract_volume_up_to_100m:up to 100 million`,
    pp_core_label_project_contract_volume_up_to_10m: $localize`:@@pp_core_label_project_contract_volume_up_to_10m:up to 10 million`,
    pp_core_label_project_contract_volume_up_to_150m: $localize`:@@pp_core_label_project_contract_volume_up_to_150m:up to 150 million`,
    pp_core_label_project_contract_volume_up_to_15m: $localize`:@@pp_core_label_project_contract_volume_up_to_15m:up to 15 million`,
    pp_core_label_project_contract_volume_up_to_1m: $localize`:@@pp_core_label_project_contract_volume_up_to_1m:up to 1 million`,
    pp_core_label_project_contract_volume_up_to_200m: $localize`:@@pp_core_label_project_contract_volume_up_to_200m:up to 200 million`,
    pp_core_label_project_contract_volume_up_to_20m: $localize`:@@pp_core_label_project_contract_volume_up_to_20m:up to 20 million`,
    pp_core_label_project_contract_volume_up_to_250m: $localize`:@@pp_core_label_project_contract_volume_up_to_250m:up to 250 million`,
    pp_core_label_project_contract_volume_up_to_25m: $localize`:@@pp_core_label_project_contract_volume_up_to_25m:up to 25 million`,
    pp_core_label_project_contract_volume_up_to_30m: $localize`:@@pp_core_label_project_contract_volume_up_to_30m:up to 30 million`,
    pp_core_label_project_contract_volume_up_to_45m: $localize`:@@pp_core_label_project_contract_volume_up_to_45m:up to 45 million`,
    pp_core_label_project_contract_volume_up_to_500k: $localize`:@@pp_core_label_project_contract_volume_up_to_500k:up to 500,000`,
    pp_core_label_project_contract_volume_up_to_50m: $localize`:@@pp_core_label_project_contract_volume_up_to_50m:up to 50 million`,
    pp_core_label_project_contract_volume_up_to_5m: $localize`:@@pp_core_label_project_contract_volume_up_to_5m:up to 5 million`,
    pp_core_label_project_contract_volume_up_to_60m: $localize`:@@pp_core_label_project_contract_volume_up_to_60m:up to 60 million`,
    pp_core_label_project_contract_volume_up_to_70m: $localize`:@@pp_core_label_project_contract_volume_up_to_70m:up to 70 million`,
    pp_core_label_project_contract_volume_up_to_80m: $localize`:@@pp_core_label_project_contract_volume_up_to_80m:up to 80 million`,
    pp_core_label_project_contract_volume_up_to_90m: $localize`:@@pp_core_label_project_contract_volume_up_to_90m:up to 90 million`,
    pp_core_label_project_object_type_construct: $localize`:@@pp_core_label_project_object_type_construct:New construction`,
    pp_core_label_project_object_type_reconstruct: $localize`:@@pp_core_label_project_object_type_reconstruct:Reconstruction`,
    pp_core_label_project_object_type_renovate: $localize`:@@pp_core_label_project_object_type_renovate:Renovation`,
    pp_core_label_project_object_type_restorate: $localize`:@@pp_core_label_project_object_type_restorate:Restoration`,
    pp_core_label_project_tender_type_calc: $localize`:@@pp_core_label_project_tender_type_calc:Calculation`,
    pp_core_label_project_tender_type_execute: $localize`:@@pp_core_label_project_tender_type_execute:Execution`,
    pp_core_label_new: $localize`:@@pp_core_label_new:New`,
    pp_core_list_filter: $localize`:@@pp_core_list_filter:Filter`,
    pp_core_list_filter_account: $localize`:@@pp_core_list_filter_account:Company`,
    pp_core_list_filter_area: $localize`:@@pp_core_list_filter_area:Area of work`,
    pp_core_list_filter_area_only: $localize`:@@pp_core_list_filter_area_only:Only companies with a suitable area of work`,
    pp_core_list_filter_branch: $localize`:@@pp_core_list_filter_branch:Branch`,
    pp_core_list_filter_company: $localize`:@@pp_core_list_filter_company:Company`,
    'pp_core_list_filter_favourite-only': $localize`:@@pp_core_list_filter_favourite-only:Visibility`,
    'pp_core_list_filter_favourite-only_false': $localize`:@@pp_core_list_filter_favourite-only_false:All projects`,
    'pp_core_list_filter_favourite-only_true': $localize`:@@pp_core_list_filter_favourite-only_true:Current projects`,
    pp_core_list_filter_kind: $localize`:@@pp_core_list_filter_kind:Type`,
    pp_core_list_no_more_results_found: $localize`:@@pp_core_list_no_more_results_found:No more results found`,
    pp_core_list_is_the_company_you_were_looking_for_not_listed: $localize`:@@pp_core_list_is_the_company_you_were_looking_for_not_listed:Is the company you were looking for not listed?`,
    pp_core_list_no_results_found: $localize`:@@pp_core_list_no_results_found:No results found`,
    pp_core_list_without_restriction: $localize`:@@pp_core_list_without_restriction:Without restriction of Area of Work and Type of Work we find the following companies:`,
    pp_core_list_filter_kind_e: $localize`:@@pp_core_list_filter_kind_e:Private companies`,
    pp_core_list_filter_kind_h: $localize`:@@pp_core_list_filter_kind_h:Head office`,
    pp_core_list_filter_kind_n: $localize`:@@pp_core_list_filter_kind_n:Branch`,
    pp_core_list_filter_member: $localize`:@@pp_core_list_filter_member:Participant`,
    pp_core_list_filter_modules: $localize`:@@pp_core_list_filter_modules:Modules`,
    pp_core_list_filter_modules_branch_admin: $localize`:@@pp_core_list_filter_modules_branch_admin:Branch management`,
    pp_core_list_filter_modules_contractor: $localize`:@@pp_core_list_filter_modules_contractor:Bidder module`,
    pp_core_list_filter_modules_directory: $localize`:@@pp_core_list_filter_modules_directory:Directory`,
    pp_core_list_filter_modules_members: $localize`:@@pp_core_list_filter_modules_members:Participant management`,
    pp_core_list_filter_modules_publisher: $localize`:@@pp_core_list_filter_modules_publisher:Issuer module`,
    pp_core_list_filter_modules_repro: $localize`:@@pp_core_list_filter_modules_repro:PP`,
    pp_core_list_filter_modules_supplier: $localize`:@@pp_core_list_filter_modules_supplier:Supplier Module`,
    pp_core_list_filter_project: $localize`:@@pp_core_list_filter_project:Project`,
    pp_core_list_filter_search: $localize`:@@pp_core_list_filter_search:Search`,
    pp_core_list_filter_site: $localize`:@@pp_core_list_filter_site:Building site`,
    pp_core_list_filter_status: $localize`:@@pp_core_list_filter_status:Status`,
    pp_core_list_filter_status_active: $localize`:@@pp_core_list_filter_status_active:active`,
    pp_core_list_filter_status_active_date: $localize`:@@pp_core_list_filter_status_active_date:Release`,
    pp_core_list_filter_status_deleted: $localize`:@@pp_core_list_filter_status_deleted:deleted`,
    pp_core_list_filter_status_disabled: $localize`:@@pp_core_list_filter_status_disabled:disabled`,
    pp_core_list_filter_tender: $localize`:@@pp_core_list_filter_tender:Tender`,
    pp_core_list_filter_tender_publishing_status: $localize`:@@pp_core_list_filter_tender_publishing_status:Publishing status`,
    pp_core_list_filter_tender_state: $localize`:@@pp_core_list_filter_tender_state:Tender status`,
    pp_core_list_filter_tender_state_completed: $localize`:@@pp_core_list_filter_tender_state_completed:closed`,
    pp_core_list_filter_tender_state_deleted: $localize`:@@pp_core_list_filter_tender_state_deleted:deleted`,
    pp_core_list_filter_tender_state_open: $localize`:@@pp_core_list_filter_tender_state_open:open`,
    pp_core_list_filter_tender_state_saved_to_cache: $localize`:@@pp_core_list_filter_tender_state_saved_to_cache:saved to cache`,
    pp_core_list_label_city: $localize`:@@pp_core_list_label_city:Town`,
    pp_core_list_label_company: $localize`:@@pp_core_list_label_company:Company`,
    pp_core_list_label_company_name: $localize`:@@pp_core_list_label_company_name:Company`,
    pp_core_list_label_country_code: $localize`:@@pp_core_list_label_country_code:Country`,
    pp_core_list_label_department: $localize`:@@pp_core_list_label_department:Department`,
    pp_core_list_label_kind: $localize`:@@pp_core_list_label_kind:Type`,
    pp_core_list_label_member_email: $localize`:@@pp_core_list_label_member_email:E-mail`,
    pp_core_list_label_member_name: $localize`:@@pp_core_list_label_member_name:Name`,
    pp_core_list_label_member_customer_number: $localize`:@@pp_core_list_label_member_customer_number:Customer No.`,
    pp_core_list_label_member_position: $localize`:@@pp_core_list_label_member_position:Function`,
    pp_core_list_label_modules: $localize`:@@pp_core_list_label_modules:Modules`,
    pp_core_list_label_olmero_id: $localize`:@@pp_core_list_label_olmero_id:Olmero-ID`,
    pp_core_list_label_project: $localize`:@@pp_core_list_label_project:Project`,
    pp_core_list_label_project_city: $localize`:@@pp_core_list_label_project_city:Town`,
    pp_core_list_label_project_name: $localize`:@@pp_core_list_label_project_name:Name`,
    pp_core_list_label_project_nr: $localize`:@@pp_core_list_label_project_nr:No.`,
    pp_core_list_label_project_product: $localize`:@@pp_core_list_label_project_product:Product`,
    pp_core_list_label_project_status: $localize`:@@pp_core_list_label_project_status:Status`,
    pp_core_list_label_site_city: $localize`:@@pp_core_list_label_site_city:Town`,
    pp_core_list_label_site_street: $localize`:@@pp_core_list_label_site_street:Street`,
    pp_core_list_label_site_zip: $localize`:@@pp_core_list_label_site_zip:Postcode`,
    pp_core_list_label_status: $localize`:@@pp_core_list_label_status:Status`,
    pp_core_list_label_tender_bkp: $localize`:@@pp_core_list_label_tender_bkp:BKP (category of work)`,
    pp_core_list_label_tender_description: $localize`:@@pp_core_list_label_tender_description:Tender`,
    pp_core_list_label_tender_no: $localize`:@@pp_core_list_label_tender_no:No.`,
    pp_core_list_label_zip: $localize`:@@pp_core_list_label_zip:Postcode`,
    pp_core_list_member_placeholder: $localize`:@@pp_core_list_member_placeholder:Name, e-mail, company`,
    pp_core_list_project_product_am: $localize`:@@pp_core_list_project_product_am:AM`,
    pp_core_list_project_product_bpr_do: $localize`:@@pp_core_list_project_product_bpr_do:BPR-Do`,
    pp_core_list_project_product_bpr_doli: $localize`:@@pp_core_list_project_product_bpr_doli:BPR-DoLi`,
    pp_core_list_project_product_bpr_dolilo: $localize`:@@pp_core_list_project_product_bpr_dolilo:BPR-DoLiLo`,
    pp_core_list_project_product_bpr_dolo: $localize`:@@pp_core_list_project_product_bpr_dolo:BPR-DoLo`,
    pp_core_list_project_product_bpr_li: $localize`:@@pp_core_list_project_product_bpr_li:BPR-Li`,
    pp_core_list_project_product_bpr_lilo: $localize`:@@pp_core_list_project_product_bpr_lilo:BPR-LiLo`,
    pp_core_list_project_product_bpr_lo: $localize`:@@pp_core_list_project_product_bpr_lo:BPR-Lo`,
    pp_core_list_project_product_dpr: $localize`:@@pp_core_list_project_product_dpr:DPR`,
    pp_core_list_project_product_gbp: $localize`:@@pp_core_list_project_product_gbp:GBP`,
    pp_core_list_project_product_rbpda: $localize`:@@pp_core_list_project_product_rbpda:RBPDA`,
    pp_core_list_project_status_active: $localize`:@@pp_core_list_project_status_active:active`,
    pp_core_list_project_status_deleted: $localize`:@@pp_core_list_project_status_deleted:disabled`,
    pp_core_list_title_select: $localize`:@@pp_core_list_title_select:Selection`,
    'pp_core_log_tender_bidder-applied': $localize`:@@pp_core_log_tender_bidder-applied:Application accepted`,
    'pp_core_log_tender_bidder-applied-notification': $localize`:@@pp_core_log_tender_bidder-applied-notification:Acceptance of application sent`,
    'pp_core_log_tender_bidder-calc-with': $localize`:@@pp_core_log_tender_bidder-calc-with:Company will submit an offer`,
    'pp_core_log_tender_bidder-invited': $localize`:@@pp_core_log_tender_bidder-invited:Company invited`,
    'pp_core_log_tender_bidder-invited-notification': $localize`:@@pp_core_log_tender_bidder-invited-notification:Tender invitation sent`,
    'pp_core_log_tender_bidder-reminded': $localize`:@@pp_core_log_tender_bidder-reminded:Reminder sent`,
    'pp_core_log_tender_bidder-removed': $localize`:@@pp_core_log_tender_bidder-removed:Company removed`,
    pp_core_log_tender_cancel: $localize`:@@pp_core_log_tender_cancel:Company rejected`,
    'pp_core_log_tender_cancel-notification': $localize`:@@pp_core_log_tender_cancel-notification:Rejection sent to company`,
    'pp_core_log_tender_create-private-bidder': $localize`:@@pp_core_log_tender_create-private-bidder:Company created`,
    pp_core_log_tender_created: $localize`:@@pp_core_log_tender_created:Tender created`,
    pp_core_log_tender_deleted: $localize`:@@pp_core_log_tender_deleted:Tender deleted`,
    pp_core_log_tender_closed: $localize`:@@pp_core_log_tender_closed:Tender closed`,
    pp_core_log_tender_reopened: $localize`:@@pp_core_log_tender_reopened:Tender reopened.`,
    'pp_core_log_tender_decline-application': $localize`:@@pp_core_log_tender_decline-application:Application rejected`,
    'pp_core_log_tender_decline-application-notification': $localize`:@@pp_core_log_tender_decline-application-notification:Rejection of application sent`,
    'pp_core_log_tender_reject-contractor': $localize`:@@pp_core_log_tender_reject-contractor:Reject bidder`,
    'pp_core_log_tender_reject-contractor-notification': $localize`:@@pp_core_log_tender_reject-contractor-notification:Notification bidder rejected sent`,
    'pp_core_log_tender_extend-submission-date': $localize`:@@pp_core_log_tender_extend-submission-date:Submission deadline for offer extended`,
    'pp_core_log_tender_modification-notification': $localize`:@@pp_core_log_tender_modification-notification:Notification of modification sent`,
    pp_core_log_tender_modified: $localize`:@@pp_core_log_tender_modified:Tender data modified`,
    pp_core_log_tender_published: $localize`:@@pp_core_log_tender_published:Tender published`,
    pp_core_log_tender_unsubscribed: $localize`:@@pp_core_log_tender_unsubscribed:Company unsubscribed`,
    pp_core_log_tender_removed_partipant: $localize`:@@pp_core_log_tender_removed_partipant:Participant removed`,
    pp_core_log_tender_added_partipant: $localize`:@@pp_core_log_tender_added_partipant:Participant added`,
    pp_core_member_external: $localize`:@@pp_core_member_external:external`,
    pp_core_member_status_active: $localize`:@@pp_core_member_status_active:active`,
    pp_core_member_status_deactive: $localize`:@@pp_core_member_status_deactive:disabled`,
    pp_core_members_add_success_project: $localize`:@@pp_core_members_add_success_project:?quantity? participants have been included into your project.`,
    pp_core_member_add_success_project: $localize`:@@pp_core_member_add_success_project:A participant has been included into your project.`,
    pp_core_members_remove_success_project: $localize`:@@pp_core_members_remove_success_project:?quantity? participants have been removed from your project.`,
    pp_core_member_remove_success_project: $localize`:@@pp_core_member_remove_success_project:A participant has been removed from your project.`,
    pp_core_members_add_success_tender: $localize`:@@pp_core_members_add_success_tender:?quantity? participants have been included into your tender.`,
    pp_core_member_add_success_tender: $localize`:@@pp_core_member_add_success_tender:A participant has been included into your tender.`,
    pp_core_members_remove_success_tender: $localize`:@@pp_core_members_remove_success_tender:?quantity? participants have been removed from your tender.`,
    pp_core_member_remove_success_tender: $localize`:@@pp_core_member_remove_success_tender:A participant has been removed from your tender.`,
    pp_core_member_add_row_success: $localize`:@@pp_core_member_add_row_success:Participant successfully added.`,
    pp_core_member_add_row_error: $localize`:@@pp_core_member_add_row_error:Participant cannot be added.`,
    pp_core_member_add_warning: $localize`:@@pp_core_member_add_warning:Please select at least one participant`,
    pp_core_member_remove_row_success: $localize`:@@pp_core_member_remove_row_success:Participant successfully removed.`,
    pp_core_member_remove_row_error: $localize`:@@pp_core_member_remove_row_error:Participant cannot be removed.`,
    pp_core_member_edit_status_deleted: $localize`:@@pp_core_member_edit_status_deleted:deleted`,
    pp_core_menu_admin: $localize`:@@pp_core_menu_admin:Administration`,
    pp_core_menu_admin_activate: $localize`:@@pp_core_menu_admin_activate:Activation`,
    pp_core_menu_admin_client: $localize`:@@pp_core_menu_admin_client:Customers`,
    pp_core_menu_admin_client_old: $localize`:@@pp_core_menu_admin_client_old:Customers`,
    pp_core_menu_admin_debitors: $localize`:@@pp_core_menu_admin_debitors:Debitors`,
    pp_core_menu_admin_export: $localize`:@@pp_core_menu_admin_export:Export`,
    pp_core_menu_admin_export_debitors: $localize`:@@pp_core_menu_admin_export_debitors:Export debitors`,
    pp_core_menu_admin_export_orders: $localize`:@@pp_core_menu_admin_export_orders:Export orders`,
    pp_core_menu_admin_export_projects: $localize`:@@pp_core_menu_admin_export_projects:Export projects`,
    pp_core_menu_admin_member: $localize`:@@pp_core_menu_admin_member:User`,
    pp_core_menu_admin_projects: $localize`:@@pp_core_menu_admin_projects:Projects`,
    pp_core_menu_admin_projects_old: $localize`:@@pp_core_menu_admin_projects_old:Projects`,
    pp_core_menu_admin_repro_center: $localize`:@@pp_core_menu_admin_repro_center:Plan printing centre`,
    pp_core_menu_admin_repro_orders: $localize`:@@pp_core_menu_admin_repro_orders:Plan print orders`,
    pp_core_menu_admin_search: $localize`:@@pp_core_menu_admin_search:Search`,
    pp_core_menu_admin_search_old: $localize`:@@pp_core_menu_admin_search_old:Search (PP)`,
    pp_core_menu_admin_themes: $localize`:@@pp_core_menu_admin_themes:Themes`,
    pp_core_menu_ap: $localize`:@@pp_core_menu_ap:Issuer`,
    pp_core_menu_ap_project: $localize`:@@pp_core_menu_ap_project:Projects`,
    pp_core_menu_dev: $localize`:@@pp_core_menu_dev:Developer`,
    pp_core_menu_dev_api_docs: $localize`:@@pp_core_menu_dev_api_docs:API Docs`,
    pp_core_menu_dev_styleguide: $localize`:@@pp_core_menu_dev_styleguide:Styleguide`,
    pp_core_menu_dev_texts: $localize`:@@pp_core_menu_dev_texts:Texts`,
    pp_core_menu_help: $localize`:@@pp_core_menu_help:Help`,
    pp_core_menu_help_esupport: $localize`:@@pp_core_menu_help_esupport:E-support`,
    pp_core_menu_help_manuals: $localize`:@@pp_core_menu_help_manuals:Help manuals`,
    pp_core_menu_home: $localize`:@@pp_core_menu_home:Home`,
    pp_core_menu_pp: $localize`:@@pp_core_menu_pp:Project platform`,
    pp_core_menu_pp_members: $localize`:@@pp_core_menu_pp_members:Participants`,
    pp_core_menu_pp_projects: $localize`:@@pp_core_menu_pp_projects:Projects`,
    pp_core_no_olmero_id: $localize`:@@pp_core_no_olmero_id:none`,
    pp_core_participant_table_header_name: $localize`:@@pp_core_participant_table_header_name:Name`,
    pp_core_participant_table_header_email: $localize`:@@pp_core_participant_table_header_email:E-mail`,
    pp_core_participant_table_header_company: $localize`:@@pp_core_participant_table_header_company:Company`,
    pp_core_participant_table_header_status: $localize`:@@pp_core_participant_table_header_status:Status`,
    pp_core_permissions_project_template: $localize`:@@pp_core_permissions_project_template:with regard to new projects`,
    pp_core_placeholder: $localize`:@@pp_core_placeholder: Placeholder`,
    pp_core_placeholder_bkp: $localize`:@@pp_core_placeholder_bkp:BKP (category of work)`,
    pp_core_placeholder_name: $localize`:@@pp_core_placeholder_name:Name`,
    pp_core_placeholder_skill: $localize`:@@pp_core_placeholder_skill:BCC, Type of Work`,
    pp_core_skill_selection: $localize`:@@pp_core_skill_selection:Selection`,
    pp_core_process_back: $localize`:@@pp_core_process_back:Back`,
    pp_core_process_cancel: $localize`:@@pp_core_process_cancel:Discard`,
    pp_core_process_cancel_cancel: $localize`:@@pp_core_process_cancel_cancel:Discard`,
    pp_core_process_cancel_hint: $localize`:@@pp_core_process_cancel_hint:Function "?function?" is cancelled and all unsaved entries are discarded.`,
    pp_core_process_cancel_hint2: $localize`:@@pp_core_process_cancel_hint2:Do you want to discard the entries?`,
    pp_core_process_cancel_no_cancel: $localize`:@@pp_core_process_cancel_no_cancel:Do not discard`,
    pp_core_process_delete_entity_title: $localize`:@@pp_core_process_delete_entity_title:Delete entity`,
    pp_core_process_delete_entity_message: $localize`:@@pp_core_process_delete_entity_message:Should the ?entity? be deleted permanently?`,
    pp_core_process_delete_entity_message_empty: $localize`:@@pp_core_process_delete_entity_message_empty:Should the entity be deleted permanently?`,
    pp_core_process_cancel_title: $localize`:@@pp_core_process_cancel_title:Discard entries`,
    pp_core_process_select_all: $localize`:@@pp_core_process_select_all:Select All`,
    pp_core_process_unselect_all: $localize`:@@pp_core_process_unselect_all:Unselect All`,
    pp_core_process_next: $localize`:@@pp_core_process_next:Continue`,
    pp_core_process_save: $localize`:@@pp_core_process_save:Save`,
    pp_core_project_detail_chapter_cockpit: $localize`:@@pp_core_project_detail_chapter_cockpit:Cockpit`,
    pp_core_project_detail_chapter_members: $localize`:@@pp_core_project_detail_chapter_members:Participant`,
    pp_core_project_detail_chapter_project: $localize`:@@pp_core_project_detail_chapter_project:Project`,
    pp_core_project_detail_chapter_picture: $localize`:@@pp_core_project_detail_chapter_picture:Picture`,
    pp_core_project_detail_chapter_protocol: $localize`:@@pp_core_project_detail_chapter_protocol:Log`,
    pp_core_project_detail_chapter_tender_template: $localize`:@@pp_core_project_detail_chapter_tender_template:Tender template`,
    pp_core_project_detail_section_attachments: $localize`:@@pp_core_project_detail_section_attachments:Attachments`,
    pp_core_project_detail_section_contacts: $localize`:@@pp_core_project_detail_section_contacts:Contacts`,
    pp_core_project_detail_section_deductions: $localize`:@@pp_core_project_detail_section_deductions:Deductions`,
    pp_core_project_detail_section_details: $localize`:@@pp_core_project_detail_section_details:Details`,
    pp_core_project_detail_section_members: $localize`:@@pp_core_project_detail_section_members:Participants`,
    pp_core_project_detail_section_new_offer: $localize`:@@pp_core_project_detail_section_new_offer:New offers`,
    pp_core_project_detail_section_open_candidate: $localize`:@@pp_core_project_detail_section_open_candidate:Open applications`,
    pp_core_project_detail_section_protocol: $localize`:@@pp_core_project_detail_section_protocol:Log`,
    pp_core_project_detail_section_saved_tenders: $localize`:@@pp_core_project_detail_section_saved_tenders:Tenders saved to cache`,
    pp_core_project_detail_title_project_num: $localize`:@@pp_core_project_detail_title_project_num:Project No`,
    pp_core_project_detail_title_applications: $localize`:@@pp_core_project_detail_title_applications:Applicants`,
    pp_core_project_detail_title_invited: $localize`:@@pp_core_project_detail_title_invited:Invited`,
    pp_core_project_detail_title_offers: $localize`:@@pp_core_project_detail_title_offers:Offers`,
    pp_core_project_detail_title_rejected: $localize`:@@pp_core_project_detail_title_rejected:Unsubscribed`,
    pp_core_project_detail_title_tenders: $localize`:@@pp_core_project_detail_title_tenders:Tenders`,
    pp_core_protocol_company: $localize`:@@pp_core_protocol_company:Company`,
    pp_core_protocol_date: $localize`:@@pp_core_protocol_date:Date`,
    pp_core_protocol_member: $localize`:@@pp_core_protocol_member:Participant`,
    pp_core_protocol_protocol: $localize`:@@pp_core_protocol_protocol:Description`,
    pp_core_reinvite_success: $localize`:@@pp_core_reinvite_success:The invitation has been resent.`,
    pp_core_session_expired_alert: $localize`:@@pp_core_session_expired_alert:Your session has expired. Please log in again. You will be redirected automatically.`,
    pp_core_settings_account: $localize`:@@pp_core_settings_account:Profile settings`,
    pp_core_settings_company: $localize`:@@pp_core_settings_company:Company settings`,
    pp_core_settings_logout: $localize`:@@pp_core_settings_logout:Log out`,
    pp_core_hotline_phone: $localize`:@@pp_core_hotline_phone:0848 656 376`,
    pp_core_working_hours: $localize`:@@pp_core_working_hours:Mo-Fr 8-12 | 13-17`,
    pp_core_help_page: $localize`:@@pp_core_help_page:Help page`,
    pp_core_tutorials: $localize`:@@pp_core_tutorials:Tutorials`,
    pp_core_member_selection_check_project: $localize`:@@pp_core_member_selection_check_project:Please check the selection. The selected participants will be added to the project and all tenders.`,
    pp_core_member_selection_remove_check_project: $localize`:@@pp_core_member_selection_remove_check_project:Please check your selection. The selected participants will be removed from the project and all tenders.`,
    pp_core_member_selection_check_tender: $localize`:@@pp_core_member_selection_check_tender:Please check the selection. The selected participants will be added to the tender and will receive the default rights.`,
    pp_core_member_selection_remove_check_tender: $localize`:@@pp_core_member_selection_remove_check_tender:Please check your selection. The selected participants will be removed from the tender.`,
    pp_core_tender_no_tenders: $localize`:@@pp_core_tender_no_tenders:At the moment, there are no tenders that match your criteria.`,
    pp_core_tender_completed_hint: $localize`:@@pp_core_tender_completed_hint:The tender has been completed. You can still view the tender, but you cannot make any changes to it.`,
    pp_core_tender_deleted_hint: $localize`:@@pp_core_tender_deleted_hint:The tender has been deleted. You can still view the tender, but you cannot make any changes to it.`,
    pp_core_tender_detail_chapter_contractors_offers: $localize`:@@pp_core_tender_detail_chapter_contractors_offers:Companies and offers`,
    pp_core_tender_detail_chapter_tender: $localize`:@@pp_core_tender_detail_chapter_tender:Tender`,
    pp_core_tender_detail_invited_application: $localize`:@@pp_core_tender_detail_invited_application:Application`,
    pp_core_tender_detail_invited_candidate_date: $localize`:@@pp_core_tender_detail_invited_candidate_date:Receipt`,
    pp_core_tender_detail_invited_company: $localize`:@@pp_core_tender_detail_invited_company:Company`,
    pp_core_tender_detail_invited_offer_date: $localize`:@@pp_core_tender_detail_invited_offer_date:Submit offer by`,
    pp_core_tender_detail_invited_state: $localize`:@@pp_core_tender_detail_invited_state:Status`,
    pp_core_tender_detail_invited_inspection: $localize`:@@pp_core_tender_detail_invited_inspection:Inspection`,
    pp_core_tender_detail_invited_state_unsubscribed: $localize`:@@pp_core_tender_detail_invited_state_unsubscribed:unsubscribed`,
    pp_core_tender_detail_invited_unsubscribed_date: $localize`:@@pp_core_tender_detail_invited_unsubscribed_date:Unsubscribed on`,
    pp_core_tender_detail_offer_company: $localize`:@@pp_core_tender_detail_offer_company:Company`,
    pp_core_tender_detail_offer_notification: $localize`:@@pp_core_tender_detail_offer_notification:Notification`,
    pp_core_tender_detail_offer_notification_sent: $localize`:@@pp_core_tender_detail_offer_notification_sent:Sent`,
    pp_core_tender_detail_offer_notification_failed: $localize`:@@pp_core_tender_detail_offer_notification_failed:Failed`,
    pp_core_tender_detail_offer_notification_pending: $localize`:@@pp_core_tender_detail_offer_notification_pending:Pending`,
    pp_core_tender_detail_offer_conformity_not_testable: $localize`:@@pp_core_tender_detail_offer_conformity_not_testable:not verifiable`,
    pp_core_tender_detail_offer_conformity_sia451: $localize`:@@pp_core_tender_detail_offer_conformity_sia451:SIA 451`,
    pp_core_tender_detail_offer_conformity_variant: $localize`:@@pp_core_tender_detail_offer_conformity_variant:Variant`,
    pp_core_tender_detail_offer_entered_in_order: $localize`:@@pp_core_tender_detail_offer_entered_in_order:Entered in order`,
    pp_core_tender_detail_offer_state_disabled: $localize`:@@pp_core_tender_detail_offer_state_disabled:Disabled`,
    pp_core_tender_detail_offer_state_offer_publish: $localize`:@@pp_core_tender_detail_offer_state_offer_publish:ordered`,
    pp_core_tender_detail_offer_state_offered: $localize`:@@pp_core_tender_detail_offer_state_offered:received`,
    pp_core_tender_detail_offer_state_received: $localize`:@@pp_core_tender_detail_offer_state_received:received`,
    pp_core_tender_detail_offer_state_accepted: $localize`:@@pp_core_tender_detail_offer_state_accepted:accepted`,
    pp_core_tender_detail_offer_state_rejected: $localize`:@@pp_core_tender_detail_offer_state_rejected:rejected`,
    pp_core_tender_detail_offer_state_in_bidding: $localize`:@@pp_core_tender_detail_offer_state_in_bidding:in bidding`,
    pp_core_tender_detail_offer_state_replaced: $localize`:@@pp_core_tender_detail_offer_state_replaced:replaced`,
    pp_core_tender_detail_offer_state_draft: $localize`:@@pp_core_tender_detail_offer_state_draft:draft`,
    pp_core_tender_detail_offer_state_bid_placed: $localize`:@@pp_core_tender_detail_offer_state_bid_placed:bid placed`,
    pp_core_tender_detail_offer_addon_text2: $localize`:@@pp_core_tender_detail_offer_addon_text2: All offer files in SIA 451 format are compliant with the tender. Conformity of any other offer files in different file formats is not verifiable.`,
    pp_core_tender_detail_tab_contractors: $localize`:@@pp_core_tender_detail_tab_contractors:Contractors`,
    pp_core_tender_detail_tab_offers: $localize`:@@pp_core_tender_detail_tab_offers:Offers`,
    pp_core_tender_detail_tab_tender_details: $localize`:@@pp_core_tender_detail_tab_tender_details:Tender Details`,
    pp_core_tender_detail_tab_no_contractors: $localize`:@@pp_core_tender_detail_tab_no_contractors:You have not invited any contractors yet, use the function Invite contractors to invite them.`,
    pp_core_tender_detail_section_approval: $localize`:@@pp_core_tender_detail_section_approval:Release`,
    pp_core_tender_detail_section_attachments: $localize`:@@pp_core_tender_detail_section_attachments:Attachments`,
    pp_core_tender_detail_section_contacts: $localize`:@@pp_core_tender_detail_section_contacts:Contacts`,
    pp_core_tender_detail_section_contender: $localize`:@@pp_core_tender_detail_section_contender:Applicant`,
    pp_core_tender_detail_section_contractors: $localize`:@@pp_core_tender_detail_section_contractors:Companies`,
    pp_core_tender_detail_section_deductions: $localize`:@@pp_core_tender_detail_section_deductions:Deductions`,
    pp_core_tender_detail_section_details: $localize`:@@pp_core_tender_detail_section_details:Details`,
    pp_core_tender_detail_section_invited: $localize`:@@pp_core_tender_detail_section_invited:Invited`,
    pp_core_tender_detail_section_members: $localize`:@@pp_core_tender_detail_section_members:Participants`,
    pp_core_tender_detail_section_offers: $localize`:@@pp_core_tender_detail_section_offers:Offers`,
    pp_core_tender_detail_section_protocol: $localize`:@@pp_core_tender_detail_section_protocol:Log`,
    pp_core_tender_detail_section_unsubscribed: $localize`:@@pp_core_tender_detail_section_unsubscribed:Unsubscribed`,
    pp_core_tender_detail_section_applicants_tender: $localize`:@@pp_core_tender_detail_section_applicants_tender:Tender`,
    pp_core_tender_detail_section_applicants_time_until: $localize`:@@pp_core_tender_detail_section_applicants_time_until:Time until end of application`,
    pp_core_tender_detail_section_applicants_number_of_applicants: $localize`:@@pp_core_tender_detail_section_applicants_number_of_applicants:Number of applicants`,
    pp_core_tender_list_add: $localize`:@@pp_core_tender_list_add:Create tender`,
    pp_core_title_add_skills: $localize`:@@pp_core_title_add_skills:Add types of work`,
    pp_core_title_all_projects: $localize`:@@pp_core_title_all_projects:All projects`,
    pp_core_title_approval: $localize`:@@pp_core_title_approval:Release`,
    pp_core_title_attachments: $localize`:@@pp_core_title_attachments:Attachments`,
    pp_core_title_branch_add: $localize`:@@pp_core_title_branch_add:Add branch`,
    pp_core_title_contacts: $localize`:@@pp_core_title_contacts:Contacts`,
    pp_core_title_create_project: $localize`:@@pp_core_title_create_project:Create project`,
    pp_core_title_deductions: $localize`:@@pp_core_title_deductions:Deductions`,
    pp_core_title_details: $localize`:@@pp_core_title_details:Details`,
    pp_core_title_member_add: $localize`:@@pp_core_title_member_add:Add participant`,
    pp_core_title_member_remove: $localize`:@@pp_core_title_member_remove:Remove participant`,
    pp_core_title_projects: $localize`:@@pp_core_title_projects:Projects`,
    pp_core_title_tender_change_message: $localize`:@@pp_core_title_tender_change_message:Notification of change`,
    pp_core_title_tender_change_message_hint: $localize`:@@pp_core_title_tender_change_message_hint:A notification will be sent to the companies.`,
    pp_core_title_tender_template_edit: $localize`:@@pp_core_title_tender_template_edit:Edit tender template`,
    pp_core_title_tender_template_create: $localize`:@@pp_core_title_tender_template_create:Create project tender template`,
    pp_core_title_tender_create_crbx: $localize`:@@pp_core_title_tender_create_crbx:Create Work Description File`,
    pp_core_tender_create_crbx_label: $localize`:@@pp_core_tender_create_crbx_label:Create Work Description`,
    pp_core_tender_create_crbx_dialog: $localize`:@@pp_core_tender_create_crbx_dialog:Create`,
    pp_core_tender_create_crbx_warning: $localize`:@@pp_core_tender_create_crbx_warning:You need to save the tender first.`,
    pp_core_tender_create_crbx_snackbar: $localize`:@@pp_core_tender_create_crbx_snackbar:Bau4X will be opened in a separate tab. After finishing you work the changes will automatically synced to Olmero.`,
    pp_core_tender_create_crbx_language_label: $localize`:@@pp_core_tender_create_crbx_language_label:File Language`,
    pp_core_tender_create_crbx_language_msg: $localize`:@@pp_core_tender_create_crbx_language_msg:To change the available languages, go to license settings:`,
    pp_core_tender_create_crbx_language_msg_branch: $localize`:@@pp_core_tender_create_crbx_language_msg_branch:To change the available languages, go to license settings of your head office:`,
    pp_core_tender_create_crbx_language_msg_non_admin: $localize`:@@pp_core_tender_create_crbx_language_msg_non_admin:To change the available languages, contact your administrator.`,
    pp_core_tender_create_crbx_language_msg_non_admin_branch: $localize`:@@pp_core_tender_create_crbx_language_msg_non_admin_branch:To change the available languages, contact your your company's head office administrator.`,
    pp_core_tender_create_crbx_no_licenses: $localize`:@@pp_core_tender_create_crbx_no_licenses:You have no CRB licenses configured yet.`,
    pp_core_tender_create_crbx_no_licenses_branch: $localize`:@@pp_core_tender_create_crbx_no_licenses_branch:Your head office has no CRB licenses configured yet.`,
    pp_core_tender_create_crbx_no_licenses_non_admin: $localize`:@@pp_core_tender_create_crbx_no_licenses_non_admin:You have no CRB licenses configured yet. Please contact your company's administrator.`,
    pp_core_tender_create_crbx_no_licenses_non_admin_branch: $localize`:@@pp_core_tender_create_crbx_no_licenses_non_admin_branch:You have no CRB licenses configured yet. Please contact your company's head office administrator.`,
    pp_core_tender_edit_crbx_dialog_title: $localize`:@@pp_core_tender_edit_crbx_dialog_title:Edit Work Description`,
    pp_core_tender_edit_crbx_dialog_content: $localize`:@@pp_core_tender_edit_crbx_dialog_content:Warning: You cannot revert to a previous version of this file after editing, even when discarding the tender edit.`,
    pp_core_tender_edit_crbx_dialog_cancel: $localize`:@@pp_core_tender_edit_crbx_dialog_cancel:Cancel`,
    pp_core_tender_edit_crbx_dialog_open: $localize`:@@pp_core_tender_edit_crbx_dialog_open:Open Editor`,
    pp_core_tender_edit_crbx_dialog_not_again: $localize`:@@pp_core_tender_edit_crbx_dialog_not_again:Do not show again`,
    pp_core_tender_crbx_processing: $localize`:@@pp_core_tender_crbx_processing:This file is synced from Bau4x.`,
    pp_core_upload_apply_olmero: $localize`:@@pp_core_upload_apply_olmero:Apply to Olmero`,
    pp_core_upload_cancel_upload: $localize`:@@pp_core_upload_cancel_upload:Cancel`,
    pp_core_upload_error: $localize`:@@pp_core_upload_error:An error occurred.`,
    pp_core_upload_error_max_upload: $localize`:@@pp_core_upload_error_max_upload:The file is too large.`,
    pp_core_upload_remove_file: $localize`:@@pp_core_upload_remove_file:Remove`,
    pp_core_upload_status_close: $localize`:@@pp_core_upload_status_close:Close`,
    pp_core_upload_status_error: $localize`:@@pp_core_upload_status_error:Error`,
    pp_core_upload_status_queued: $localize`:@@pp_core_upload_status_queued:Wait`,
    pp_core_upload_status_title: $localize`:@@pp_core_upload_status_title:Status`,
    pp_core_upload_upload_confirmation: $localize`:@@pp_core_upload_upload_confirmation:Do you want to cancel the file upload?`,
    pp_core_validation_date_end_after_start: $localize`:@@pp_core_validation_date_end_after_start:The "end of construction" must be after the "start of construction".`,
    pp_core_validation_date_start_before_end: $localize`:@@pp_core_validation_date_start_before_end:The "start of construction" must be before the "end of construction".`,
    pp_core_validation_email_change_info: $localize`:@@pp_core_validation_email_change_info:The e-mail address is also the user name. If the e-mail address is modified, you can only log in once you have verified the new address by clicking the confirmation link sent by e-mail.`,
    pp_core_validation_email_error: $localize`:@@pp_core_validation_email_error:Invalid e-mail! Must be in local@domain format! Umlauts and special characters are not allowed!`,
    pp_core_validation_email_or_fax: $localize`:@@pp_core_validation_email_or_fax:Please specify either "e-mail" or "fax", otherwise the company cannot be notified.`,
    pp_core_validation_email_used_error: $localize`:@@pp_core_validation_email_used_error:This e-mail address is already registered with the platform.`,
    pp_core_validation_empty_error: $localize`:@@pp_core_validation_empty_error:Enter a value`,
    pp_core_validation_phone_error: $localize`:@@pp_core_validation_phone_error:Invalid number! Must have minimum length of 4 characters and start with + or 00!`,
    pp_core_validation_swiss_date_error: $localize`:@@pp_core_validation_swiss_date_error:Invalid date format (DD.MM.YYYY)`,
    pp_core_view_list_list_next_entries: $localize`:@@pp_core_view_list_list_next_entries:Further entries...`,
    pp_core_breadcrumb_preffix_text: $localize`:@@pp_core_breadcrumb_preffix_text:You are here:`,
    pp_core_clients_module_info_package: $localize`:@@pp_core_clients_module_info_package:Info Package`,
    pp_core_clients_module_construction_data: $localize`:@@pp_core_clients_module_construction_data:Construction data`,
    pp_core_clients_module_public_tenders: $localize`:@@pp_core_clients_module_public_tenders:Public tenders`,
    pp_core_clients_module_sia_editor: $localize`:@@pp_core_clients_module_sia_editor:SIA-Editor`,
    pp_core_clients_module_project_list: $localize`:@@pp_core_clients_module_project_list:Project list`,
    pp_core_clients_module_project_handling: $localize`:@@pp_core_clients_module_project_handling:Project handling`,
    pp_core_clients_module_supplier: $localize`:@@pp_core_clients_module_supplier:Supplier`,
    pp_core_clients_module_crb_button_configure: $localize`:@@pp_core_clients_module_crb_button_configure:Configure CRB Licenses`,
    pp_core_clients_module_crb_button_view: $localize`:@@pp_core_clients_module_crb_button_view:View CRB Licenses`,
    pp_core_clients_module_crb_button_to_head_office: $localize`:@@pp_core_clients_module_crb_button_to_head_office:Head office`,
    pp_core_clients_module_crb_message_to_head_office: $localize`:@@pp_core_clients_module_crb_message_to_head_office:To manage your CRB licenses, go to the company profile of the head office.`,
    pp_core_clients_settings_to_head_office: $localize`:@@pp_core_clients_settings_to_head_office:To manage your settings, go to the company profile of the head office.`,
    member_edit_no_assignable_clients: $localize`:@@member_edit_no_assignable_clients:The branch has been deleted, please select an active branch.`,
    signup_confirm_error_allready_set: $localize`:@@signup_confirm_error_allready_set:Your password has already been set. Please call the OLMeRO hotline at 0848-OLMERO (0848 656376) - workdays from 8:00 to 12:00 am and from 1:00 to 5:00 pm.`,
    signup_confirm_error_expired: $localize`:@@signup_confirm_error_expired:Verification of your e-mail address was unsuccessful. Please call the OLMeRO hotline at 0848-OLMERO (0848 656376) - workdays from 8:00 to 12:00 am and from 1:00 to 5:00 pm`,
    signup_confirm_error_unknown: $localize`:@@signup_confirm_error_unknown:Error! Please call the OLMeRO hotline at 0848-OLMERO (0848 656376) - workdays from 8:00 to 12:00 am and from 1:00 to 5:00 pm.`,
    signup_confirm_login: $localize`:@@signup_confirm_login:Login`,
    signup_confirm_password: $localize`:@@signup_confirm_password:Password`,
    signup_confirm_password_info: $localize`:@@signup_confirm_password_info:Your password should consist of at least eight characters and a combination of lower and upper case letters, numbers and special characters`,
    signup_confirm_subtitle: $localize`:@@signup_confirm_subtitle:Your e-mail address is also your login name.&lt;br /&gt;Please enter a password to complete your access details.`,
    signup_confirm_success: $localize`:@@signup_confirm_success:The password has been accepted. You will be redirected to the login page within a few seconds.?timer?&lt;br /&gt;&lt;br /&gt; If you are not redirected automatically, you can call up the &lt;a href="?link?"&gt;login page via this link&lt;/a&gt;.`,
    signup_confirm_title: $localize`:@@signup_confirm_title:Welcome!`,
    signup_validate_phone_error: $localize`:@@signup_validate_phone_error:The format of this phone number is unknown. Please check your entry. Only numbers, spaces and the plus sign are allowed, e.g. +41 848 656 376.`,
    signup_validate_phone_info: $localize`:@@signup_validate_phone_info:For phone numbers outside of Switzerland, the country dialling code must be specified.`,
    masterplan_breadcrumb_masterplans: $localize`:@@masterplan_breadcrumb_masterplans:Masterplans`,
    masterplan_upload_in_progress: $localize`:@@masterplan_upload_in_progress:Loading`,
    masterplan_form_description_required: $localize`:@@masterplan_form_description_required:Description is required.`,
    masterplan_create: $localize`:@@masterplan_create:Create Masterplan`,
    masterplan_replace: $localize`:@@masterplan_replace:Replace Masterplan`,
    masterplan_create_saving_hint: $localize`:@@masterplan_create_saving_hint:Saving data, you will be redirected soon.`,
    masterplan_successfully_saved: $localize`:@@masterplan_successfully_saved:Masterplan successfully saved.`,
    masterplan_unexpected_error: $localize`:@@masterplan_unexpected_error:Could not save one or more masterplans. There was an unknown error.`,
    masterplan_over_100_chars_error: $localize`:@@masterplan_over_100_chars_error:Masterplan(s) could not be uploaded. You are not allowed to upload a masterplan with more than 100 characters.`,
    masterplan_details_name: $localize`:@@masterplan_details_name:Filename`,
    masterplan_details_description: $localize`:@@masterplan_details_description:Description`,
    masterplan_details_plan_information: $localize`:@@masterplan_details_plan_information:Plan Information`,
    masterplan_details_created_by: $localize`:@@masterplan_details_created_by:Created by`,
    pp_client_validation_error_required: $localize`:@@pp_client_validation_error_required:This field is required.`,
    pp_client_validation_error_maxlength: $localize`:@@pp_client_validation_error_maxlength:Only ?numberOfChars? characters are allowed.`,
    pp_server_validation_error_required: $localize`:@@pp_server_validation_error_required:This field is required.`,
    pp_server_validation_error_required_property_value: $localize`:@@pp_server_validation_error_required_property_value:This field is required.`,
    pp_server_validation_error_length: $localize`:@@pp_server_validation_error_length:Text is too long.`,
    pp_server_validation_error_unsatisfied_property_value_maximum_length: $localize`:@@pp_server_validation_error_unsatisfied_property_value_maximum_length:Text is too long.`,
    buildingtypesearch_placeholder: $localize`:@@buildingtypesearch_placeholder: Building type`,
    building_type_free_time: $localize`:@@building_type_free_time:Freizeit, Sport, Erholung`,
    building_type_health: $localize`:@@building_type_health:Fürsorge und Gesundheit`,
    building_type_tourism: $localize`:@@building_type_tourism:Gastgewerbe und Fremdenverkehr`,
    building_type_administration: $localize`:@@building_type_administration:Handel und Verwaltung`,
    building_type_industry: $localize`:@@building_type_industry:Industrie und Gewerbe`,
    building_type_justice: $localize`:@@building_type_justice:Justiz und Polizei`,
    building_type_society: $localize`:@@building_type_society:Kultur und Geselligkeit`,
    building_type_cultural_affairs: $localize`:@@building_type_cultural_affairs:Kultus`,
    building_type_agriculture: $localize`:@@building_type_agriculture:Land- und Forstwirtschaft`,
    building_type_army: $localize`:@@building_type_army:Militär- und Schutzanlagen`,
    building_type_technical_installation: $localize`:@@building_type_technical_installation:Technische Anlagen`,
    building_type_schooling: $localize`:@@building_type_schooling:Unterricht, Bildung und Forschung`,
    building_type_traffic_facilities: $localize`:@@building_type_traffic_facilities:Verkehrsanlagen`,
    building_type_big_apartments: $localize`:@@building_type_big_apartments:Wohnen (ab 3 Wohneinheiten)`,
    building_type_small_apartments: $localize`:@@building_type_small_apartments:Wohnen (bis 2 Wohneinheiten)`,
    building_type_elderly_homes: $localize`:@@building_type_elderly_homes:Alterswohnheime`,
    building_type_elderly_residence: $localize`:@@building_type_elderly_residence:Alterswohnungen, Alterssiedlungen`,
    building_type_family_apartments: $localize`:@@building_type_family_apartments:Einfamilienhaus-Siedlungen`,
    building_type_orphanage: $localize`:@@building_type_orphanage:Kinder- und Jugendheime`,
    building_type_lofts: $localize`:@@building_type_lofts:Lofts`,
    building_type_apartment_blocks: $localize`:@@building_type_apartment_blocks:Mehrfamilienhäuser`,
    building_type_townhouse: $localize`:@@building_type_townhouse:Reihenhäuser`,
    building_type_student_residence: $localize`:@@building_type_student_residence:Studenten- und Lehrlingswohnheime`,
    building_type_terrace_house: $localize`:@@building_type_terrace_house:Terrassenhäuser`,
    building_type_winter_garden: $localize`:@@building_type_winter_garden:Wintergärten und Balkonverglasungen`,
    building_type_farmhouse: $localize`:@@building_type_farmhouse:Bauernhäuser`,
    building_type_temporary_housing: $localize`:@@building_type_temporary_housing:Behelfswohnungen`,
    building_type_twin_houses: $localize`:@@building_type_twin_houses:Doppel-Einfamilienhäuser`,
    building_type_family_house: $localize`:@@building_type_family_house:Einfamilienhäuser`,
    building_type_greenhouse: $localize`:@@building_type_greenhouse:Gewächshäuser`,
    building_type_apartments: $localize`:@@building_type_apartments:Wohnungen`,
    building_type_professional_school: $localize`:@@building_type_professional_school:Berufs- und höhere Fachschulen`,
    building_type_library: $localize`:@@building_type_library:Bibliotheken und Staatsarchive`,
    building_type_research_institute: $localize`:@@building_type_research_institute:Forschungsinstitute`,
    building_type_specialized_pedagogy: $localize`:@@building_type_specialized_pedagogy:Heilpädagogische Schulen/Sonderschulen`,
    building_type_university: $localize`:@@building_type_university:Hochschulen und Universitäten`,
    building_type_kindergarten: $localize`:@@building_type_kindergarten:Kinderhorte und Kindergärten`,
    building_type_high_school: $localize`:@@building_type_high_school:Mittelschulen und Gymnasien`,
    building_type_primary_school: $localize`:@@building_type_primary_school:Primar- und Sekundarschulen`,
    building_type_studios: $localize`:@@building_type_studios:Atelier und Studio`,
    building_type_service_stations: $localize`:@@building_type_service_stations:Autowerkstätten`,
    building_type_craftsmen: $localize`:@@building_type_craftsmen:Betriebs- und Gewerbebauten`,
    building_type_industrial_halls: $localize`:@@building_type_industrial_halls:Industriehallen`,
    building_type_industrial_production_line: $localize`:@@building_type_industrial_production_line:Industrielle Produktionsbauten`,
    building_type_warehouses: $localize`:@@building_type_warehouses:Lagerhallen`,
    building_type_food_production: $localize`:@@building_type_food_production:Lebensmittelproduktion`,
    building_type_cold_storage: $localize`:@@building_type_cold_storage:Mechanisierte Lager und Kühllager`,
    building_type_multi_storey_warehouse: $localize`:@@building_type_multi_storey_warehouse:Mehrgeschossige Lagerbauten`,
    building_type_silos: $localize`:@@building_type_silos:Silobauten und Behälter`,
    building_type_distribution_centers: $localize`:@@building_type_distribution_centers:Verteilzentralen`,
    building_type_fodders: $localize`:@@building_type_fodders:Futterlagerräume, Treibhäuser und Silobauten`,
    building_type_pavillons: $localize`:@@building_type_pavillons:Gartenhäuser / Pavillons`,
    building_type_slaughterhouses: $localize`:@@building_type_slaughterhouses:Schlachthöfe`,
    building_type_sheds: $localize`:@@building_type_sheds:Schuppen und Hütten`,
    building_type_agricultural_production: $localize`:@@building_type_agricultural_production:Stallungen und landwirtschaftliche Produktionsanlagen`,
    building_type_animal_shelters: $localize`:@@building_type_animal_shelters:Tierheime und Veterinärstationen`,
    building_type_animal_hospitals: $localize`:@@building_type_animal_hospitals:Tierspitäler`,
    building_type_disposal_sites: $localize`:@@building_type_disposal_sites:Deponien`,
    building_type_electrical_distribution_systems: $localize`:@@building_type_electrical_distribution_systems:Elektrische Verteilanlagen`,
    building_type_heating_plants: $localize`:@@building_type_heating_plants:Heizzentralen, Fernwärmeanlagen und Kraftwerkbauten`,
    building_type_incinerators: $localize`:@@building_type_incinerators:Kehrichtverbrennungs- und Wiederaufbereitungsanlagen`,
    building_type_masts: $localize`:@@building_type_masts:Masten`,
    building_type_gas_stations: $localize`:@@building_type_gas_stations:Tankanlagen und Tankstellen`,
    building_type_water_system_distribution: $localize`:@@building_type_water_system_distribution:Verteilanlagen für Trinkwasser`,
    building_type_thermal_distribution_systems: $localize`:@@building_type_thermal_distribution_systems:Wärme- und Kälteverteilanlagen`,
    building_type_water_treatment_plants: $localize`:@@building_type_water_treatment_plants:Wasseraufbereitungsanlagen`,
    building_type_banks: $localize`:@@building_type_banks:Banken, Postgebäude und Fernmeldegebäude`,
    building_type_offices: $localize`:@@building_type_offices:Bürobauten`,
    building_type_town_halls: $localize`:@@building_type_town_halls:Gemeindehäuser, Rathäuser und Regierungsgebäude`,
    building_type_shops: $localize`:@@building_type_shops:Ladenbauten`,
    building_type_administration_buildings: $localize`:@@building_type_administration_buildings:Verwaltungsgebäude und Rechenzentren`,
    building_type_malls: $localize`:@@building_type_malls:Warenhäuser, Einkaufszentren und Showrooms`,
    building_type_courthouse: $localize`:@@building_type_courthouse:Gerichtsgebäude`,
    building_type_police_station: $localize`:@@building_type_police_station:Polizeieinsatzgebäude und Untersuchungsgefängnisse`,
    building_type_prisons: $localize`:@@building_type_prisons:Strafvollzugsanstalten `,
    building_type_reintegration_sites: $localize`:@@building_type_reintegration_sites:Wiedereingliederungsstätten`,
    building_type_medical_practices: $localize`:@@building_type_medical_practices:Arztpraxen und Ärztehäuser `,
    building_type_spas: $localize`:@@building_type_spas:Heilbäder und Spezialinstitute `,
    building_type_hospitals: $localize`:@@building_type_hospitals:Krankenhäuser`,
    building_type_rehabilitation_centers: $localize`:@@building_type_rehabilitation_centers:Pflegeheime, Sanatorien und Rehabilitationszentren`,
    building_type_day_centres: $localize`:@@building_type_day_centres:Tagesheime und geschützte Werkstätten`,
    building_type_university_hospitals: $localize`:@@building_type_university_hospitals:Universitätskliniken `,
    building_type_chalets: $localize`:@@building_type_chalets:Berghäuser`,
    building_type_campings: $localize`:@@building_type_campings:Campinganlagen`,
    building_type_hostels: $localize`:@@building_type_hostels:Herbergen, Jugendherbergen und Massenunterkünfte`,
    building_type_hotels: $localize`:@@building_type_hotels:Hotel- und Motelbauten `,
    building_type_canteens: $localize`:@@building_type_canteens:Kantinen`,
    building_type_clubs: $localize`:@@building_type_clubs:Cabanes de club`,
    building_type_bars: $localize`:@@building_type_bars:Raststätten, Cafeterias, Tea-Rooms und Bars`,
    building_type_restoration: $localize`:@@building_type_restoration:Restaurationsbetriebe`,
    building_type_wellness: $localize`:@@building_type_wellness:Wellness`,
    building_type_parks: $localize`:@@building_type_parks:Aussenanlagen, Kinderspielplätze und Parkanlagen `,
    building_type_boat_houses: $localize`:@@building_type_boat_houses:Bootshäuser`,
    building_type_leisure_centers: $localize`:@@building_type_leisure_centers:Freizeitzentren und Jugendhäuser `,
    building_type_pools: $localize`:@@building_type_pools:Hallen- und Freibäder`,
    building_type_jacuzzi: $localize`:@@building_type_jacuzzi:Privatschwimmbäder, Jacuzzi, Wellness`,
    building_type_stables: $localize`:@@building_type_stables:Reithallen `,
    building_type_multipurpose_facilities: $localize`:@@building_type_multipurpose_facilities:Sportanlagen, Turn- und Mehrzweckanlagen `,
    building_type_cloakrooms: $localize`:@@building_type_cloakrooms:Tribünenbauten und Garderobengebäude `,
    building_type_zoos: $localize`:@@building_type_zoos:Zoologische und botanische Gärten, Tierhäuser `,
    building_type_garages: $localize`:@@building_type_garages:Garagen und Unterstände`,
    building_type_train_stations: $localize`:@@building_type_train_stations:Bahnhöfe und Bahnbetriebsbauten, Seilbahnstationen`,
    building_type_bus_stations: $localize`:@@building_type_bus_stations:Busbahnhöfe, Zollanlagen und Wartehallen mit Diensträumen`,
    building_type_airports: $localize`:@@building_type_airports:Flughafenbauten`,
    building_type_parking_garages: $localize`:@@building_type_parking_garages:Parkhäuser und Einstellhallen`,
    building_type_parkings: $localize`:@@building_type_parkings:Parkplätze und Abstellplätze`,
    building_type_post_stations: $localize`:@@building_type_post_stations:Post- und Logistikterminale`,
    building_type_roads_administration: $localize`:@@building_type_roads_administration:Strassenverkehrsgebäude`,
    building_type_underground_garages: $localize`:@@building_type_underground_garages:Tiefgaragen und Unterniveaugaragen`,
    building_type_factories: $localize`:@@building_type_factories:Werkhöfe `,
    building_type_fire_station: $localize`:@@building_type_fire_station:Feuerwehrgebäude `,
    building_type_emergency_hospitals: $localize`:@@building_type_emergency_hospitals:Geschützte Operationsstellen und Notspitäler `,
    building_type_barracks: $localize`:@@building_type_barracks:Kasernen `,
    building_type_military_installations: $localize`:@@building_type_military_installations:Militäranlagen und militärische Schutzanlagen`,
    building_type_civil_protection: $localize`:@@building_type_civil_protection:Öffentliche Zivilschutzanlagen `,
    building_type_infirmaries: $localize`:@@building_type_infirmaries:Sanitätsposten und Sanitätshilfsstellen`,
    building_type_armories: $localize`:@@building_type_armories:Zeughäuser `,
    building_type_civil_protection_training: $localize`:@@building_type_civil_protection_training:Zivilschutz-Ausbildungszentren `,
    building_type_funeral_hall: $localize`:@@building_type_funeral_hall:Abdankungshallen `,
    building_type_castles: $localize`:@@building_type_castles:Burgen & Schlösser`,
    building_type_cemeteries: $localize`:@@building_type_cemeteries:Friedhofanlagen`,
    building_type_churches: $localize`:@@building_type_churches:Kirchen und Kapellen `,
    building_type_parish_houses: $localize`:@@building_type_parish_houses:Kirchgemeindehäuser`,
    building_type_monasteries: $localize`:@@building_type_monasteries:Klöster`,
    building_type_crematoria: $localize`:@@building_type_crematoria:Krematorien`,
    building_type_exhibition_halls: $localize`:@@building_type_exhibition_halls:Ausstellungsbauten `,
    building_type_casinos: $localize`:@@building_type_casinos:Casino`,
    building_type_cinemas: $localize`:@@building_type_cinemas:Kino-, Diskothek- und Saalbauten `,
    building_type_congress_halls: $localize`:@@building_type_congress_halls:Kongresshäuser und Festhallen`,
    building_type_concert_halls: $localize`:@@building_type_concert_halls:Konzertbauten und Theaterbauten`,
    building_type_museums: $localize`:@@building_type_museums:Museen und Kunstgalerien `,
    building_type_music_halls: $localize`:@@building_type_music_halls:Musikpavillons `,
    building_type_radio_television_studios: $localize`:@@building_type_radio_television_studios:Radio-, Fernseh- und Filmstudios `,
    building_type_welfare: $localize`:@@building_type_welfare:Wohlfahrtshäuser, Klubhäuser und Kulturzentren `,
    building_type_hubs: $localize`:@@building_type_hubs:Lager- und Umschlagplätze`,
    building_type_public_toilets: $localize`:@@building_type_public_toilets:Öffentliche WC-Anlagen`,
    building_type_supermarkets: $localize`:@@building_type_supermarkets:Supermärkte`,
    building_type_cellars: $localize`:@@building_type_cellars:Keller`,
    building_type_collection_points: $localize`:@@building_type_collection_points:Sammelstellen`,
    building_type_fitness_centers: $localize`:@@building_type_fitness_centers:Fitnesscenter`,
    building_type_other: $localize`:@@building_type_other:Sonstige`,
    building_type_civil_engineering: $localize`:@@building_type_civil_engineering:Tiefbau`,
    'Arbeitsgattung-Abbrueche': $localize`:@@Arbeitsgattung-Abbrueche:Demolitions`,
    'Arbeitsgattung-Abschrankungen': $localize`:@@Arbeitsgattung-Abschrankungen:Barriers`,
    'Arbeitsgattung-Akustiker': $localize`:@@Arbeitsgattung-Akustiker:Acoustician`,
    'Arbeitsgattung-Allgemeine_Metallbauarbeiten': $localize`:@@Arbeitsgattung-Allgemeine_Metallbauarbeiten:Gen. Metal construction work, internal glazing, protection area components`,
    'Arbeitsgattung-Allgemeine_Sanitaerapparate': $localize`:@@Arbeitsgattung-Allgemeine_Sanitaerapparate:General sanitary installations`,
    'Arbeitsgattung-Allgemeine_Schreinerarbeiten': $localize`:@@Arbeitsgattung-Allgemeine_Schreinerarbeiten:Gen. carpenter work, closets, racks etc.`,
    'Arbeitsgattung-Anker': $localize`:@@Arbeitsgattung-Anker:Armature`,
    'Arbeitsgattung-Apparate_Schwachstrom': $localize`:@@Arbeitsgattung-Apparate_Schwachstrom:Appliances low voltage current`,
    'Arbeitsgattung-Apparate_Starkstrom': $localize`:@@Arbeitsgattung-Apparate_Starkstrom:Appliances high voltage current`,
    'Arbeitsgattung-Architekt': $localize`:@@Arbeitsgattung-Architekt:Architect`,
    'Arbeitsgattung-Aufzuege': $localize`:@@Arbeitsgattung-Aufzuege:Elevators`,
    'Arbeitsgattung-Ausbau_1-Elementwaende': $localize`:@@Arbeitsgattung-Ausbau_1-Elementwaende:Element walls`,
    'Arbeitsgattung-Ausbau_1-Gipserarbeiten': $localize`:@@Arbeitsgattung-Ausbau_1-Gipserarbeiten:Plaster work`,
    'Arbeitsgattung-Ausbau_1-Innere_Abschluesse': $localize`:@@Arbeitsgattung-Ausbau_1-Innere_Abschluesse:Internal completion work`,
    'Arbeitsgattung-Ausbau_1-Metallbauarbeiten': $localize`:@@Arbeitsgattung-Ausbau_1-Metallbauarbeiten:Metal construction work`,
    'Arbeitsgattung-Ausbau_1-Schliessanlagen': $localize`:@@Arbeitsgattung-Ausbau_1-Schliessanlagen:Locking systems`,
    'Arbeitsgattung-Ausbau_1-Schreinerarbeiten': $localize`:@@Arbeitsgattung-Ausbau_1-Schreinerarbeiten:Carpenter work`,
    'Arbeitsgattung-Ausbau_1-Spezialverglasungen_(innere)': $localize`:@@Arbeitsgattung-Ausbau_1-Spezialverglasungen_(innere):Special glazing (internal)`,
    'Arbeitsgattung-Ausbau_2-Bauaustrocknung': $localize`:@@Arbeitsgattung-Ausbau_2-Bauaustrocknung:Drying out of buildings`,
    'Arbeitsgattung-Ausbau_2-Baureinigung': $localize`:@@Arbeitsgattung-Ausbau_2-Baureinigung:Construction site cleaning`,
    'Arbeitsgattung-Ausbau_2-Bodenbelaege': $localize`:@@Arbeitsgattung-Ausbau_2-Bodenbelaege:Floor covers`,
    'Arbeitsgattung-Ausbau_2-Bodenbelaege_aus_Holz': $localize`:@@Arbeitsgattung-Ausbau_2-Bodenbelaege_aus_Holz:Floor covers made of wood`,
    'Arbeitsgattung-Ausbau_2-Bodenbelaege_aus_Kunststein': $localize`:@@Arbeitsgattung-Ausbau_2-Bodenbelaege_aus_Kunststein:Floor covers made of artificial stone`,
    'Arbeitsgattung-Ausbau_2-Bodenbelaege_aus_Kunststoff': $localize`:@@Arbeitsgattung-Ausbau_2-Bodenbelaege_aus_Kunststoff:Floor covers made of plastic, textiles etc.`,
    'Arbeitsgattung-Ausbau_2-Bodenbelaege_aus_Naturstein': $localize`:@@Arbeitsgattung-Ausbau_2-Bodenbelaege_aus_Naturstein:Floor covers made of natural stone`,
    'Arbeitsgattung-Ausbau_2-Bodenbelaege_Plattenarbeiten': $localize`:@@Arbeitsgattung-Ausbau_2-Bodenbelaege_Plattenarbeiten:Floor covers slab work`,
    'Arbeitsgattung-Ausbau_2-Deckenbekleidungen1': $localize`:@@Arbeitsgattung-Ausbau_2-Deckenbekleidungen1:Ceiling coverings made of metal: panels`,
    'Arbeitsgattung-Ausbau_2-Deckenbekleidungen2': $localize`:@@Arbeitsgattung-Ausbau_2-Deckenbekleidungen2:Ceiling coverings made of plaster`,
    'Arbeitsgattung-Ausbau_2-Deckenbekleidungen3': $localize`:@@Arbeitsgattung-Ausbau_2-Deckenbekleidungen3:Ceiling coverings made of mineral fibre`,
    'Arbeitsgattung-Ausbau_2-Deckenbekleidungen4': $localize`:@@Arbeitsgattung-Ausbau_2-Deckenbekleidungen4:Ceiling coverings made of wood and wooden materials`,
    'Arbeitsgattung-Ausbau_2-Deckenbekleidungen5': $localize`:@@Arbeitsgattung-Ausbau_2-Deckenbekleidungen5:Ceiling coverings made of metal: panels and slats`,
    'Arbeitsgattung-Ausbau_2-Deckenbekleidungen': $localize`:@@Arbeitsgattung-Ausbau_2-Deckenbekleidungen:Ceiling coverings`,
    'Arbeitsgattung-Ausbau_2-Doppelboeden': $localize`:@@Arbeitsgattung-Ausbau_2-Doppelboeden:Double floors`,
    'Arbeitsgattung-Ausbau_2-Fugenlose_Bodenbelaege': $localize`:@@Arbeitsgattung-Ausbau_2-Fugenlose_Bodenbelaege:Jointless floor covers`,
    'Arbeitsgattung-Ausbau_2-Gaertnerarbeiten': $localize`:@@Arbeitsgattung-Ausbau_2-Gaertnerarbeiten:Gardening work construction phase 2`,
    'Arbeitsgattung-Ausbau_2-Hafnerarbeiten': $localize`:@@Arbeitsgattung-Ausbau_2-Hafnerarbeiten:Stove fitting work`,
    'Arbeitsgattung-Ausbau_2-Innere_Oberflaechenbehandlungen': $localize`:@@Arbeitsgattung-Ausbau_2-Innere_Oberflaechenbehandlungen:Internal surface treatments`,
    'Arbeitsgattung-Ausbau_2-Plattenarbeiten': $localize`:@@Arbeitsgattung-Ausbau_2-Plattenarbeiten:Wall coverings: slab work`,
    'Arbeitsgattung-Ausbau_2-Sockel': $localize`:@@Arbeitsgattung-Ausbau_2-Sockel:Base`,
    'Arbeitsgattung-Ausbau_2-Tapezierarbeiten': $localize`:@@Arbeitsgattung-Ausbau_2-Tapezierarbeiten:Covering work`,
    'Arbeitsgattung-Ausbau_2-Unterlagsboeden': $localize`:@@Arbeitsgattung-Ausbau_2-Unterlagsboeden:Floor beddings`,
    'Arbeitsgattung-Ausbau_2-Wandbekleidungen': $localize`:@@Arbeitsgattung-Ausbau_2-Wandbekleidungen:Wall coverings made of wood and wooden materials`,
    'Arbeitsgattung-Ausbau_2-Wandbelaege_Wandbekleidungen': $localize`:@@Arbeitsgattung-Ausbau_2-Wandbelaege_Wandbekleidungen:Wall covers, wall claddings`,
    'Arbeitsgattung-Aussteifungen': $localize`:@@Arbeitsgattung-Aussteifungen:Reinforcements`,
    'Arbeitsgattung-Baubiologe': $localize`:@@Arbeitsgattung-Baubiologe:Building biologist`,
    'Arbeitsgattung-Baugrube-Aushub': $localize`:@@Arbeitsgattung-Baugrube-Aushub:Pit excavation`,
    'Arbeitsgattung-Baugrubenabschluesse': $localize`:@@Arbeitsgattung-Baugrubenabschluesse:Pit excavation work completion`,
    'Arbeitsgattung-Baugrunduntersuchungen': $localize`:@@Arbeitsgattung-Baugrunduntersuchungen:Geotechnical investigation`,
    'Arbeitsgattung-Baugrundverbesserungen': $localize`:@@Arbeitsgattung-Baugrundverbesserungen:Geotechnical improvement`,
    'Arbeitsgattung-Bauherr_oeffentliche_Hand': $localize`:@@Arbeitsgattung-Bauherr_oeffentliche_Hand:Public construction owner`,
    'Arbeitsgattung-Bauherr_privat': $localize`:@@Arbeitsgattung-Bauherr_privat:Private construction owner`,
    'Arbeitsgattung-Bauingenieur': $localize`:@@Arbeitsgattung-Bauingenieur:Construction engineer`,
    'Arbeitsgattung-Baunebenleistungen-Bewachung': $localize`:@@Arbeitsgattung-Baunebenleistungen-Bewachung:Surveillance by a third party`,
    'Arbeitsgattung-Bauoekonom': $localize`:@@Arbeitsgattung-Bauoekonom:Site manager`,
    'Arbeitsgattung-Bauphysiker': $localize`:@@Arbeitsgattung-Bauphysiker:Building physicist`,
    'Arbeitsgattung-Bauprovisorien': $localize`:@@Arbeitsgattung-Bauprovisorien:Provisional structures`,
    'Arbeitsgattung-Baureklame': $localize`:@@Arbeitsgattung-Baureklame:Building advertisements`,
    'Arbeitsgattung-Bauwerksdynamik': $localize`:@@Arbeitsgattung-Bauwerksdynamik:Constructional dynamics`,
    'Arbeitsgattung-Bauwerkserhalter': $localize`:@@Arbeitsgattung-Bauwerkserhalter:Structural sustainer`,
    'Arbeitsgattung-Bauwerksinstandsetzung': $localize`:@@Arbeitsgattung-Bauwerksinstandsetzung:Structural repair work`,
    'Arbeitsgattung-Bauwerksuntersuchungen': $localize`:@@Arbeitsgattung-Bauwerksuntersuchungen:Examinations of building structures`,
    'Arbeitsgattung-Bestandesaufnahmem': $localize`:@@Arbeitsgattung-Bestandesaufnahmem:Recording of current situation`,
    'Arbeitsgattung-Betriebsplaner': $localize`:@@Arbeitsgattung-Betriebsplaner:Operational planner`,
    'Arbeitsgattung-Beweissicherung': $localize`:@@Arbeitsgattung-Beweissicherung:Preservation of evidence`,
    'Arbeitsgattung-Bewilligungen-Baugespann': $localize`:@@Arbeitsgattung-Bewilligungen-Baugespann:Approvals, fees`,
    'Arbeitsgattung-Bewilligungen': $localize`:@@Arbeitsgattung-Bewilligungen:Approvals, construction profiles`,
    'Arbeitsgattung-Bohrarbeiten': $localize`:@@Arbeitsgattung-Bohrarbeiten:Drilling and cutting work`,
    'Arbeitsgattung-Brandschutzexperte': $localize`:@@Arbeitsgattung-Brandschutzexperte:Fire protection expert`,
    'Arbeitsgattung-Buehnentechnik': $localize`:@@Arbeitsgattung-Buehnentechnik:Stage technology systems`,
    'Arbeitsgattung-Buero_Bauleitung': $localize`:@@Arbeitsgattung-Buero_Bauleitung:Office site management`,
    'Arbeitsgattung-Demontagen': $localize`:@@Arbeitsgattung-Demontagen:Disassemblies`,
    'Arbeitsgattung-Dokumentationen-Dokumentationen': $localize`:@@Arbeitsgattung-Dokumentationen-Dokumentationen:Documentations`,
    'Arbeitsgattung-Dokumentationen-Fotos': $localize`:@@Arbeitsgattung-Dokumentationen-Fotos:Pictures`,
    'Arbeitsgattung-Dokumentationen-Inserate': $localize`:@@Arbeitsgattung-Dokumentationen-Inserate:Classifieds, advertisements, posters, construction site advertisements`,
    'Arbeitsgattung-Dokumentationen-Modelle': $localize`:@@Arbeitsgattung-Dokumentationen-Modelle:Models`,
    'Arbeitsgattung-Dokumentationen-Muster': $localize`:@@Arbeitsgattung-Dokumentationen-Muster:Samples, material tests`,
    'Arbeitsgattung-Dokumentationen-Vervielfaeltigungen': $localize`:@@Arbeitsgattung-Dokumentationen-Vervielfaeltigungen:Reproductions, plan copies`,
    'Arbeitsgattung-EDV_Spezialist': $localize`:@@Arbeitsgattung-EDV_Spezialist:IT specialist`,
    'Arbeitsgattung-Elekrotafeln': $localize`:@@Arbeitsgattung-Elekrotafeln:Electrical and pneumatic switchboards`,
    'Arbeitsgattung-Elektroingenieur': $localize`:@@Arbeitsgattung-Elektroingenieur:Electrical engineer`,
    'Arbeitsgattung-Energietraeger': $localize`:@@Arbeitsgattung-Energietraeger:Suppliers, energy carriers, storage`,
    'Arbeitsgattung-Energieverbraucher': $localize`:@@Arbeitsgattung-Energieverbraucher:Energy consumer`,
    'Arbeitsgattung-Erdbewegungen': $localize`:@@Arbeitsgattung-Erdbewegungen:Earthmoving`,
    'Arbeitsgattung-Erschliessung-Elektroleitungen': $localize`:@@Arbeitsgattung-Erschliessung-Elektroleitungen:Power lines`,
    'Arbeitsgattung-Erschliessung-Erdarbeiten': $localize`:@@Arbeitsgattung-Erschliessung-Erdarbeiten:Earthworks`,
    'Arbeitsgattung-Erschliessung-Heizungsleitungen': $localize`:@@Arbeitsgattung-Erschliessung-Heizungsleitungen:Heating, ventilation, air conditioning, cooling lines`,
    'Arbeitsgattung-Erschliessung-Kanalisationsleitungen': $localize`:@@Arbeitsgattung-Erschliessung-Kanalisationsleitungen:Sewerage lines`,
    'Arbeitsgattung-Erschliessung-Sanitaerleitungen': $localize`:@@Arbeitsgattung-Erschliessung-Sanitaerleitungen:Sanitary lines development`,
    'Arbeitsgattung-Expertisen': $localize`:@@Arbeitsgattung-Expertisen:Expertises`,
    'Arbeitsgattung-Fachkordinator': $localize`:@@Arbeitsgattung-Fachkordinator:Specialist co-ordinator`,
    'Arbeitsgattung-Fahrtreppen': $localize`:@@Arbeitsgattung-Fahrtreppen:Escalator, moving walkway`,
    'Arbeitsgattung-Fassadenplaner': $localize`:@@Arbeitsgattung-Fassadenplaner:Facade planner`,
    'Arbeitsgattung-Fassadenreinigungsanlagen': $localize`:@@Arbeitsgattung-Fassadenreinigungsanlagen:Facade cleaning system`,
    'Arbeitsgattung-Feststehende_Elementwaende': $localize`:@@Arbeitsgattung-Feststehende_Elementwaende:Fixed element walls`,
    'Arbeitsgattung-Foerderungsanlagen': $localize`:@@Arbeitsgattung-Foerderungsanlagen:Assembly stations`,
    'Arbeitsgattung-Gartengestalter': $localize`:@@Arbeitsgattung-Gartengestalter:Garden planner`,
    'Arbeitsgattung-Gebaeudeleitsystem': $localize`:@@Arbeitsgattung-Gebaeudeleitsystem:Building management system`,
    'Arbeitsgattung-Gebaeudesystemtechnik': $localize`:@@Arbeitsgattung-Gebaeudesystemtechnik:Building system technology`,
    'Arbeitsgattung-Generalplaner': $localize`:@@Arbeitsgattung-Generalplaner:General planner`,
    'Arbeitsgattung-Generalunternehmer': $localize`:@@Arbeitsgattung-Generalunternehmer:General contractor`,
    'Arbeitsgattung-Geometer': $localize`:@@Arbeitsgattung-Geometer:Geometer`,
    'Arbeitsgattung-Geraete-Mobil': $localize`:@@Arbeitsgattung-Geraete-Mobil:Mobile devices and machines`,
    'Arbeitsgattung-Geruestarbeiten-alle': $localize`:@@Arbeitsgattung-Geruestarbeiten-alle:Scaffolding work of all types`,
    'Arbeitsgattung-Grundwasserabdichtung': $localize`:@@Arbeitsgattung-Grundwasserabdichtung:Ground water sealing`,
    'Arbeitsgattung-Grundwassererhebungen': $localize`:@@Arbeitsgattung-Grundwassererhebungen:Ground water elevation`,
    'Arbeitsgattung-Hebeeinrichtungen': $localize`:@@Arbeitsgattung-Hebeeinrichtungen:Lifting equipment`,
    'Arbeitsgattung-HLK_Daemmungen': $localize`:@@Arbeitsgattung-HLK_Daemmungen:Insulation HVC systems`,
    'Arbeitsgattung-HLKK_Ingenieur': $localize`:@@Arbeitsgattung-HLKK_Ingenieur:HVCC engineer`,
    'Arbeitsgattung-Innenarchitekt': $localize`:@@Arbeitsgattung-Innenarchitekt:Interior designer`,
    'Arbeitsgattung-Innentueren_aus_Holz': $localize`:@@Arbeitsgattung-Innentueren_aus_Holz:Internal doors made of wood`,
    'Arbeitsgattung-Innentueren_aus_Metall': $localize`:@@Arbeitsgattung-Innentueren_aus_Metall:Internal doors made of metal`,
    'Arbeitsgattung-Innere_Verglasungen_aus_Holz': $localize`:@@Arbeitsgattung-Innere_Verglasungen_aus_Holz:Internal glazing made of wood`,
    'Arbeitsgattung-Installationen-Elektroanlagen': $localize`:@@Arbeitsgattung-Installationen-Elektroanlagen:Electrical systems`,
    'Arbeitsgattung-Installationen-Heizungen': $localize`:@@Arbeitsgattung-Installationen-Heizungen:Heating, ventilation, air conditioning, cooling systems`,
    'Arbeitsgattung-Installationen-Sanitaeranlagen': $localize`:@@Arbeitsgattung-Installationen-Sanitaeranlagen:Sanitary installations`,
    'Arbeitsgattung-Installationen-Transportanlagen': $localize`:@@Arbeitsgattung-Installationen-Transportanlagen:Transport system installations`,
    'Arbeitsgattung-Instandsetzungsarbeiten': $localize`:@@Arbeitsgattung-Instandsetzungsarbeiten:Repair work`,
    'Arbeitsgattung-Kaelteanlagen': $localize`:@@Arbeitsgattung-Kaelteanlagen:Cooling systems`,
    'Arbeitsgattung-Kamine': $localize`:@@Arbeitsgattung-Kamine:Chimneys and exhaust lines`,
    'Arbeitsgattung-Kleininventar-Beschriftungen': $localize`:@@Arbeitsgattung-Kleininventar-Beschriftungen:Labelling`,
    'Arbeitsgattung-Kleininventar-Container': $localize`:@@Arbeitsgattung-Kleininventar-Container:Container`,
    'Arbeitsgattung-Klimaanlagen': $localize`:@@Arbeitsgattung-Klimaanlagen:Air conditioning systems`,
    'Arbeitsgattung-Korrosion': $localize`:@@Arbeitsgattung-Korrosion:Corrosion`,
    'Arbeitsgattung-Kuecheneinrichtungen': $localize`:@@Arbeitsgattung-Kuecheneinrichtungen:Kitchen facilities`,
    'Arbeitsgattung-Kunstbauten-Ausbau': $localize`:@@Arbeitsgattung-Kunstbauten-Ausbau:Expansion of new structures`,
    'Arbeitsgattung-Kunstbauten-Baugrube': $localize`:@@Arbeitsgattung-Kunstbauten-Baugrube:Excavation pit`,
    'Arbeitsgattung-Kunstbauten-Entwaesserung': $localize`:@@Arbeitsgattung-Kunstbauten-Entwaesserung:Drainage new structures`,
    'Arbeitsgattung-Kunstbauten-Oberbau': $localize`:@@Arbeitsgattung-Kunstbauten-Oberbau:Superstructure new structures`,
    'Arbeitsgattung-Kunstbauten-Tragkonstruktion': $localize`:@@Arbeitsgattung-Kunstbauten-Tragkonstruktion:Support structure`,
    'Arbeitsgattung-Kunstbauten-Transportanlagen': $localize`:@@Arbeitsgattung-Kunstbauten-Transportanlagen:Transport systems new structures`,
    'Arbeitsgattung-Kunstbauten-Werkleitungen': $localize`:@@Arbeitsgattung-Kunstbauten-Werkleitungen:Utility lines and sewerage new structures`,
    'Arbeitsgattung-Leuchten': $localize`:@@Arbeitsgattung-Leuchten:Luminaires and lamps`,
    'Arbeitsgattung-Logistiker': $localize`:@@Arbeitsgattung-Logistiker:Logisticians`,
    'Arbeitsgattung-Lueftungsanlagen': $localize`:@@Arbeitsgattung-Lueftungsanlagen:Ventilation systems`,
    'Arbeitsgattung-Materialtechnologie': $localize`:@@Arbeitsgattung-Materialtechnologie:Material technology`,
    'Arbeitsgattung-Medizinalingenieur': $localize`:@@Arbeitsgattung-Medizinalingenieur:Medical engineer`,
    'Arbeitsgattung-Metallbaufertigteile': $localize`:@@Arbeitsgattung-Metallbaufertigteile:Prefabricated elements for metal construction`,
    'Arbeitsgattung-Metallbauplaner': $localize`:@@Arbeitsgattung-Metallbauplaner:Metal construction planner`,
    'Arbeitsgattung-Moebel-Allgemein': $localize`:@@Arbeitsgattung-Moebel-Allgemein:General furnishing`,
    'Arbeitsgattung-Moebel-Buero': $localize`:@@Arbeitsgattung-Moebel-Buero:Office furniture`,
    'Arbeitsgattung-Moebel-Garderobeneinrichtung': $localize`:@@Arbeitsgattung-Moebel-Garderobeneinrichtung:Cloakroom furniture`,
    'Arbeitsgattung-Moebel-Restaurant': $localize`:@@Arbeitsgattung-Moebel-Restaurant:Restaurant furniture`,
    'Arbeitsgattung-Moebel-Schutzraumausstattung': $localize`:@@Arbeitsgattung-Moebel-Schutzraumausstattung:Protection area equipment`,
    'Arbeitsgattung-MSR_Planer': $localize`:@@Arbeitsgattung-MSR_Planer:I&C planner`,
    'Arbeitsgattung-Parkieranlagen': $localize`:@@Arbeitsgattung-Parkieranlagen:Parking facilities`,
    'Arbeitsgattung-Pfaehle': $localize`:@@Arbeitsgattung-Pfaehle:Poles`,
    'Arbeitsgattung-Provisorien': $localize`:@@Arbeitsgattung-Provisorien:Provisional arrangements`,
    'Arbeitsgattung-Provisorische_Abschluesse': $localize`:@@Arbeitsgattung-Provisorische_Abschluesse:Provisional closures`,
    'Arbeitsgattung-Provisorische_Installation': $localize`:@@Arbeitsgattung-Provisorische_Installation:Provisional installation`,
    'Arbeitsgattung-Pruefingenieure': $localize`:@@Arbeitsgattung-Pruefingenieure:Test engineers`,
    'Arbeitsgattung-QM_Experte': $localize`:@@Arbeitsgattung-QM_Experte:QM expert`,
    'Arbeitsgattung-Rodungen': $localize`:@@Arbeitsgattung-Rodungen:Clearings`,
    'Arbeitsgattung-Rohbau_1-Balkone_Treppen': $localize`:@@Arbeitsgattung-Rohbau_1-Balkone_Treppen:Balconies and stairs`,
    'Arbeitsgattung-Rohbau_1-Baumeisterarbeiten': $localize`:@@Arbeitsgattung-Rohbau_1-Baumeisterarbeiten:General building construction work`,
    'Arbeitsgattung-Rohbau_1-Betonbohrungen_Fraesen': $localize`:@@Arbeitsgattung-Rohbau_1-Betonbohrungen_Fraesen:Concrete drilling / milling`,
    'Arbeitsgattung-Rohbau_1-Instandsetzungsarbeiten': $localize`:@@Arbeitsgattung-Rohbau_1-Instandsetzungsarbeiten:Repair work`,
    'Arbeitsgattung-Rohbau_1-Kanal': $localize`:@@Arbeitsgattung-Rohbau_1-Kanal:Sewerages in buildings`,
    'Arbeitsgattung-Rohbau_1-Kunststeinarbeiten': $localize`:@@Arbeitsgattung-Rohbau_1-Kunststeinarbeiten:Artificial stone work`,
    'Arbeitsgattung-Rohbau_1-Maurerarbeiten': $localize`:@@Arbeitsgattung-Rohbau_1-Maurerarbeiten:Bricklayer\'s work`,
    'Arbeitsgattung-Rohbau_1-Montagebau_als_Leichtkonstruktion': $localize`:@@Arbeitsgattung-Rohbau_1-Montagebau_als_Leichtkonstruktion:Assembly as lightweight construction`,
    'Arbeitsgattung-Rohbau_1-Montagebau_in_Beton_und_vorfabriziertem_Mauerwerk': $localize`:@@Arbeitsgattung-Rohbau_1-Montagebau_in_Beton_und_vorfabriziertem_Mauerwerk:Assembly with concrete & prefabr. walling`,
    'Arbeitsgattung-Rohbau_1-Montagebau_in_Holz': $localize`:@@Arbeitsgattung-Rohbau_1-Montagebau_in_Holz:Assembly work made of wood`,
    'Arbeitsgattung-Rohbau_1-Montagebau_in_Stahl': $localize`:@@Arbeitsgattung-Rohbau_1-Montagebau_in_Stahl:Assembly work made of steel`,
    'Arbeitsgattung-Rohbau_1-Natursteinarbeiten': $localize`:@@Arbeitsgattung-Rohbau_1-Natursteinarbeiten:Natural stone work`,
    'Arbeitsgattung-Rohbau_1-Schutzraumabschluesse': $localize`:@@Arbeitsgattung-Rohbau_1-Schutzraumabschluesse:Protection area closures`,
    'Arbeitsgattung-Rohbau_1-Stahlbetonarbeiten': $localize`:@@Arbeitsgattung-Rohbau_1-Stahlbetonarbeiten:Concrete and reinforced concrete work`,
    'Arbeitsgattung-Rohbau_2-Aeussere_Abschluesse_Sonnenschutz': $localize`:@@Arbeitsgattung-Rohbau_2-Aeussere_Abschluesse_Sonnenschutz:External completion work, sun protection`,
    'Arbeitsgattung-Rohbau_2-Aeussere_Oberflaechenbehandlungen': $localize`:@@Arbeitsgattung-Rohbau_2-Aeussere_Oberflaechenbehandlungen:External surface treatments`,
    'Arbeitsgattung-Rohbau_2-Arealabschluesse': $localize`:@@Arbeitsgattung-Rohbau_2-Arealabschluesse:Area closures`,
    'Arbeitsgattung-Rohbau_2-Aussentueren': $localize`:@@Arbeitsgattung-Rohbau_2-Aussentueren:External doors, gates made of metal`,
    'Arbeitsgattung-Rohbau_2-Aussentueren_aus_Holz': $localize`:@@Arbeitsgattung-Rohbau_2-Aussentueren_aus_Holz:External doors made of wood`,
    'Arbeitsgattung-Rohbau_2-Bedachungsarbeiten': $localize`:@@Arbeitsgattung-Rohbau_2-Bedachungsarbeiten:Roofing work`,
    'Arbeitsgattung-Rohbau_2-Blitzschutz': $localize`:@@Arbeitsgattung-Rohbau_2-Blitzschutz:Lightning protection`,
    'Arbeitsgattung-Rohbau_2-Brandschutzbekleidungen': $localize`:@@Arbeitsgattung-Rohbau_2-Brandschutzbekleidungen:Fire protective products`,
    'Arbeitsgattung-Rohbau_2-Deckungen': $localize`:@@Arbeitsgattung-Rohbau_2-Deckungen:Roof covering (steep roofs)`,
    'Arbeitsgattung-Rohbau_2-Fassadenputze': $localize`:@@Arbeitsgattung-Rohbau_2-Fassadenputze:Plasters`,
    'Arbeitsgattung-Rohbau_2-Fenster_aus_Holz': $localize`:@@Arbeitsgattung-Rohbau_2-Fenster_aus_Holz:Windows made of wood or wood/metal`,
    'Arbeitsgattung-Rohbau_2-Fenster_aus_Kunststoff': $localize`:@@Arbeitsgattung-Rohbau_2-Fenster_aus_Kunststoff:Windows made of plastic`,
    'Arbeitsgattung-Rohbau_2-Fenster_Aussentueren_Tore': $localize`:@@Arbeitsgattung-Rohbau_2-Fenster_Aussentueren_Tore:Windows, external doors, gates`,
    'Arbeitsgattung-Rohbau_2-Fenster_aus_Stahl': $localize`:@@Arbeitsgattung-Rohbau_2-Fenster_aus_Stahl:Windows made of steel or aluminium`,
    'Arbeitsgattung-Rohbau_2-Fensterlaeden': $localize`:@@Arbeitsgattung-Rohbau_2-Fensterlaeden:Windows and window shutters`,
    'Arbeitsgattung-Rohbau_2-Fugendichtungen': $localize`:@@Arbeitsgattung-Rohbau_2-Fugendichtungen:Joint sealants`,
    'Arbeitsgattung-Rohbau_2-Glaseinbauten_in_Flachdaechern': $localize`:@@Arbeitsgattung-Rohbau_2-Glaseinbauten_in_Flachdaechern:Glass mounting in flat roofs`,
    'Arbeitsgattung-Rohbau_2-Glaseinbauten_in_Steildaechern': $localize`:@@Arbeitsgattung-Rohbau_2-Glaseinbauten_in_Steildaechern:Glass mounting in steep roofs`,
    'Arbeitsgattung-Rohbau_2-Lichtdurchlaessige_Bauteile': $localize`:@@Arbeitsgattung-Rohbau_2-Lichtdurchlaessige_Bauteile:Transparent components`,
    'Arbeitsgattung-Rohbau_2-Metallbaufertigteile': $localize`:@@Arbeitsgattung-Rohbau_2-Metallbaufertigteile:Prefabricated metal construction parts`,
    'Arbeitsgattung-Rohbau_2-Plastische_Dachbelaege': $localize`:@@Arbeitsgattung-Rohbau_2-Plastische_Dachbelaege:Plastic roof coverings`,
    'Arbeitsgattung-Rohbau_2-Rollaeden': $localize`:@@Arbeitsgattung-Rohbau_2-Rollaeden:Shutters, slats and sunshades, glare shield systems`,
    'Arbeitsgattung-Rohbau_2-Schaufensteranlagen': $localize`:@@Arbeitsgattung-Rohbau_2-Schaufensteranlagen:Window displays`,
    'Arbeitsgattung-Rohbau_2-Spenglerarbeiten': $localize`:@@Arbeitsgattung-Rohbau_2-Spenglerarbeiten:Sheet metal work`,
    'Arbeitsgattung-Rohbau_2-Spezielle_Daemmungen': $localize`:@@Arbeitsgattung-Rohbau_2-Spezielle_Daemmungen:Special insulations`,
    'Arbeitsgattung-Rohbau_2-Spezielle_Dichtungen_und_Daemmungen': $localize`:@@Arbeitsgattung-Rohbau_2-Spezielle_Dichtungen_und_Daemmungen:Special seals and insulations`,
    'Arbeitsgattung-Rohbau_2-Spezielle_Feuchtigkeitsabdichtungen': $localize`:@@Arbeitsgattung-Rohbau_2-Spezielle_Feuchtigkeitsabdichtungen:Special damp-proofing`,
    'Arbeitsgattung-Rohrreinigung': $localize`:@@Arbeitsgattung-Rohrreinigung:Pipe and drain cleaning`,
    'Arbeitsgattung-Sanitaer_Daemmungen': $localize`:@@Arbeitsgattung-Sanitaer_Daemmungen:Insulations sanitary installations`,
    'Arbeitsgattung-Sanitaeringenieur': $localize`:@@Arbeitsgattung-Sanitaeringenieur:Plumbing engineer`,
    'Arbeitsgattung-Sanitaerinstallationselemente': $localize`:@@Arbeitsgattung-Sanitaerinstallationselemente:Sanitary installation elements`,
    'Arbeitsgattung-Sanitaerleitungen': $localize`:@@Arbeitsgattung-Sanitaerleitungen:Sanitary lines`,
    'Arbeitsgattung-Saugen_und_Blasen': $localize`:@@Arbeitsgattung-Saugen_und_Blasen:Sucking and blowing of soil and bulk material`,
    'Arbeitsgattung-Schiebe_und_Faltwaende': $localize`:@@Arbeitsgattung-Schiebe_und_Faltwaende:Sliding and folding walls`,
    'Arbeitsgattung-Schwachstrominstallationen': $localize`:@@Arbeitsgattung-Schwachstrominstallationen:Low voltage installations`,
    'Arbeitsgattungsgruppe-Ausbau_1': $localize`:@@Arbeitsgattungsgruppe-Ausbau_1:Construction phase 1`,
    'Arbeitsgattungsgruppe-Ausbau_2': $localize`:@@Arbeitsgattungsgruppe-Ausbau_2:Construction phase 2`,
    'Arbeitsgattungsgruppe-Baugrube': $localize`:@@Arbeitsgattungsgruppe-Baugrube:Excavation pit`,
    'Arbeitsgattungsgruppe-Bauherr': $localize`:@@Arbeitsgattungsgruppe-Bauherr:Construction owner`,
    'Arbeitsgattungsgruppe-Baunebenleistungen': $localize`:@@Arbeitsgattungsgruppe-Baunebenleistungen:Other construction services`,
    'Arbeitsgattungsgruppe-Baustelleneinrichtung': $localize`:@@Arbeitsgattungsgruppe-Baustelleneinrichtung:Joint site equipment`,
    'Arbeitsgattungsgruppe-Bestandesaufnahmen': $localize`:@@Arbeitsgattungsgruppe-Bestandesaufnahmen:Recording of current situation, geotechnical examinations`,
    'Arbeitsgattungsgruppe-Dokumentationen': $localize`:@@Arbeitsgattungsgruppe-Dokumentationen:Samples, models, reproductions, documentations`,
    'Arbeitsgattungsgruppe-Elektroanlagen': $localize`:@@Arbeitsgattungsgruppe-Elektroanlagen:Electrical installations`,
    'Arbeitsgattungsgruppe-Erschliessung': $localize`:@@Arbeitsgattungsgruppe-Erschliessung:Development of lines`,
    'Arbeitsgattungsgruppe-Geraete': $localize`:@@Arbeitsgattungsgruppe-Geraete:Devices, apparatuses`,
    'Arbeitsgattungsgruppe-Geruestarbeiten': $localize`:@@Arbeitsgattungsgruppe-Geruestarbeiten:Scaffolding work`,
    'Arbeitsgattungsgruppe-Gesamtleistungstraeger': $localize`:@@Arbeitsgattungsgruppe-Gesamtleistungstraeger:Full service provider`,
    'Arbeitsgattungsgruppe-Heizungs_Lueftungs_Klima_und_Kaelteanlagen': $localize`:@@Arbeitsgattungsgruppe-Heizungs_Lueftungs_Klima_und_Kaelteanlagen:Heating, ventilation, air conditioning systems`,
    'Arbeitsgattungsgruppe-Installationen': $localize`:@@Arbeitsgattungsgruppe-Installationen:Installations`,
    'Arbeitsgattungsgruppe-Kleininventar': $localize`:@@Arbeitsgattungsgruppe-Kleininventar:Operating equipment`,
    'Arbeitsgattungsgruppe-Kunstbauten': $localize`:@@Arbeitsgattungsgruppe-Kunstbauten:New structures`,
    'Arbeitsgattungsgruppe-Moebel': $localize`:@@Arbeitsgattungsgruppe-Moebel:Furniture`,
    'Arbeitsgattungsgruppe-Planer': $localize`:@@Arbeitsgattungsgruppe-Planer:Planner`,
    'Arbeitsgattungsgruppe-Raeumungen_Terrainvorbereitungen': $localize`:@@Arbeitsgattungsgruppe-Raeumungen_Terrainvorbereitungen:Clearings, preparation of landscape`,
    'Arbeitsgattungsgruppe-Rohbau_1': $localize`:@@Arbeitsgattungsgruppe-Rohbau_1:Shell construction 1`,
    'Arbeitsgattungsgruppe-Rohbau_2': $localize`:@@Arbeitsgattungsgruppe-Rohbau_2:Shell construction 2`,
    'Arbeitsgattungsgruppe-Sanitaeranlagen': $localize`:@@Arbeitsgattungsgruppe-Sanitaeranlagen:Sanitary installations`,
    'Arbeitsgattungsgruppe-Sicherungen_Provisorien': $localize`:@@Arbeitsgattungsgruppe-Sicherungen_Provisorien:Safeguards, provisional arrangements`,
    'Arbeitsgattungsgruppe-Spez_Fundationen_Baugrubensicherung': $localize`:@@Arbeitsgattungsgruppe-Spez_Fundationen_Baugrubensicherung:Special foundations, securing of the excavation pit, ground water sealing`,
    'Arbeitsgattungsgruppe-Spezialisten': $localize`:@@Arbeitsgattungsgruppe-Spezialisten:Specialists`,
    'Arbeitsgattungsgruppe-Transportanlagen': $localize`:@@Arbeitsgattungsgruppe-Transportanlagen:Transport systems`,
    'Arbeitsgattungsgruppe-Transportmittel': $localize`:@@Arbeitsgattungsgruppe-Transportmittel:Means of transport`,
    'Arbeitsgattungsgruppe-Trassenbauten': $localize`:@@Arbeitsgattungsgruppe-Trassenbauten:Route construction`,
    'Arbeitsgattungsgruppe-Umgebungsarbeiten': $localize`:@@Arbeitsgattungsgruppe-Umgebungsarbeiten:Garden work`,
    'Arbeitsgattungsgruppe-Untertagebauten': $localize`:@@Arbeitsgattungsgruppe-Untertagebauten:Underground structures`,
    'Arbeitsgattungsgruppe-Verbrauchsmaterial': $localize`:@@Arbeitsgattungsgruppe-Verbrauchsmaterial:Consumables`,
    'Arbeitsgattungsgruppe-Versicherungen': $localize`:@@Arbeitsgattungsgruppe-Versicherungen:Insurances`,
    'Arbeitsgattung-Sicherheitsanlagen': $localize`:@@Arbeitsgattung-Sicherheitsanlagen:Security systems (external)`,
    'Arbeitsgattung-Sicherung': $localize`:@@Arbeitsgattung-Sicherung:Securing of existing systems`,
    'Arbeitsgattung-Spezialanlagen': $localize`:@@Arbeitsgattung-Spezialanlagen:Special systems`,
    'Arbeitsgattung-Spezielle_Sanitaerapparate': $localize`:@@Arbeitsgattung-Spezielle_Sanitaerapparate:Special sanitary apparatuses`,
    'Arbeitsgattung-Sprengstoffexperte': $localize`:@@Arbeitsgattung-Sprengstoffexperte:Explosives expert`,
    'Arbeitsgattung-Sprinkleranlagen': $localize`:@@Arbeitsgattung-Sprinkleranlagen:Other, sprinkler systems etc.`,
    'Arbeitsgattung-Starkstrominstallationen': $localize`:@@Arbeitsgattung-Starkstrominstallationen:High voltage installations`,
    'Arbeitsgattung-Totalunternehmer': $localize`:@@Arbeitsgattung-Totalunternehmer:Total contractor`,
    'Arbeitsgattung-Transportmittel-Lastwagen': $localize`:@@Arbeitsgattung-Transportmittel-Lastwagen:Lorries, passenger cars`,
    'Arbeitsgattung-Trassenbauten-Ausbau': $localize`:@@Arbeitsgattung-Trassenbauten-Ausbau:Expansion route constructions`,
    'Arbeitsgattung-Trassenbauten-Entwaesserung': $localize`:@@Arbeitsgattung-Trassenbauten-Entwaesserung:Drainage route constructions`,
    'Arbeitsgattung-Trassenbauten-Erdbau': $localize`:@@Arbeitsgattung-Trassenbauten-Erdbau:Earthwork and substructure`,
    'Arbeitsgattung-Trassenbauten-Kunstbauten': $localize`:@@Arbeitsgattung-Trassenbauten-Kunstbauten:Small new structures`,
    'Arbeitsgattung-Trassenbauten-Oberbau': $localize`:@@Arbeitsgattung-Trassenbauten-Oberbau:Superstructure route constructions`,
    'Arbeitsgattung-Trassenbauten-Werkleitungen': $localize`:@@Arbeitsgattung-Trassenbauten-Werkleitungen:Utility lines and sewerage route constructions`,
    'Arbeitsgattung-Umgebungsarbeiten-Abschrankungen_Zaeune': $localize`:@@Arbeitsgattung-Umgebungsarbeiten-Abschrankungen_Zaeune:Stockades`,
    'Arbeitsgattung-Umgebungsarbeiten-Ausstattungen': $localize`:@@Arbeitsgattung-Umgebungsarbeiten-Ausstattungen:Facilities, gardens, garden furniture`,
    'Arbeitsgattung-Umgebungsarbeiten-Garten_Landschaftsbau': $localize`:@@Arbeitsgattung-Umgebungsarbeiten-Garten_Landschaftsbau:Garden work landscaping`,
    'Arbeitsgattung-Umgebungsarbeiten-Sport': $localize`:@@Arbeitsgattung-Umgebungsarbeiten-Sport:Playground and sports facilities`,
    'Arbeitsgattung-Umgebungsarbeiten-Strasse_Wege_Plaetze': $localize`:@@Arbeitsgattung-Umgebungsarbeiten-Strasse_Wege_Plaetze:Street, paths & places`,
    'Arbeitsgattung-Umgebungsarbeiten-Ueberdachungen': $localize`:@@Arbeitsgattung-Umgebungsarbeiten-Ueberdachungen:Roofs`,
    'Arbeitsgattung-Unterfangungen': $localize`:@@Arbeitsgattung-Unterfangungen:Underpinnings`,
    'Arbeitsgattung-Unterkuenfte': $localize`:@@Arbeitsgattung-Unterkuenfte:Accommodations, catering facilities`,
    'Arbeitsgattung-Untertagebauten-Ausbau': $localize`:@@Arbeitsgattung-Untertagebauten-Ausbau:Expansion underground structures`,
    'Arbeitsgattung-Untertagebauten-Entwaesserung': $localize`:@@Arbeitsgattung-Untertagebauten-Entwaesserung:Drainage and water supply`,
    'Arbeitsgattung-Untertagebauten-Oberbau': $localize`:@@Arbeitsgattung-Untertagebauten-Oberbau:Superstructure underground structures`,
    'Arbeitsgattung-Untertagebauten-Transportanlagen': $localize`:@@Arbeitsgattung-Untertagebauten-Transportanlagen:Transport systems underground structures`,
    'Arbeitsgattung-Untertagebauten-Verkleidung': $localize`:@@Arbeitsgattung-Untertagebauten-Verkleidung:Cladding, vaults`,
    'Arbeitsgattung-Untertagebauten-Vortrieb': $localize`:@@Arbeitsgattung-Untertagebauten-Vortrieb:Propulsion`,
    'Arbeitsgattung-Untertagebauten-Werkleitungen': $localize`:@@Arbeitsgattung-Untertagebauten-Werkleitungen:Utility lines`,
    'Arbeitsgattung-USV_Anlagen': $localize`:@@Arbeitsgattung-USV_Anlagen:UPS systems`,
    'Arbeitsgattung-Verbrauchsmittel-Brennstoffvorraete': $localize`:@@Arbeitsgattung-Verbrauchsmittel-Brennstoffvorraete:Fuel stocks`,
    'Arbeitsgattung-Versetzbare_Elementwaende': $localize`:@@Arbeitsgattung-Versetzbare_Elementwaende:Repositionable element walls`,
    'Arbeitsgattung-Versicherungen-Bauzeitenversicherungen': $localize`:@@Arbeitsgattung-Versicherungen-Bauzeitenversicherungen:Building time insurance (progressive building insurance)`,
    'Arbeitsgattung-Versicherungen-Spezialversicherungen': $localize`:@@Arbeitsgattung-Versicherungen-Spezialversicherungen:Special insurances (construction / construction owner liability insurance)`,
    'Arbeitsgattung-Versorgungsapparate': $localize`:@@Arbeitsgattung-Versorgungsapparate:Sanitary supply and waste disposal facilities`,
    'Arbeitsgattung-Videoanlage': $localize`:@@Arbeitsgattung-Videoanlage:Building automation`,
    'Arbeitsgattung-Waermeerzeugung': $localize`:@@Arbeitsgattung-Waermeerzeugung:Heat generation`,
    'Arbeitsgattung-Waermeverteilung': $localize`:@@Arbeitsgattung-Waermeverteilung:Heat distribution`,
    'Arbeitsgattung-Wasserbau': $localize`:@@Arbeitsgattung-Wasserbau:Hydraulic engineering`,
    'Arbeitsgattung-Wasserhaltung': $localize`:@@Arbeitsgattung-Wasserhaltung:Water retention`,
    'Arbeitsgattung-Zufahrt': $localize`:@@Arbeitsgattung-Zufahrt:Access road, spaces`,
    'OLMERO_TENDER-Unternehmer': $localize`:@@OLMERO_TENDER-Unternehmer:Contractor`,
    snackbar_close_tender_tender_closed: $localize`:@@snackbar_close_tender_tender_closed:The tender was closed.`,
    snackbar_close_tender_tender_closed_error: $localize`:@@snackbar_close_tender_tender_closed_error:Could not close tender. An unexpected error occurred. Please try again.`,
    snackbar_not_authorized: $localize`:@@snackbar_not_authorized:You have no permission to perform this action.`,
    snackbar_bad_request: $localize`:@@snackbar_bad_request:There was an error performing this action. Please try again.`,
    snackbar_close_tender_tender_already_completed: $localize`:@@snackbar_close_tender_tender_already_completed:Tender was already closed.`,
    snackbar_delete_tender_tender_deletion_not_allowed: $localize`:@@snackbar_delete_tender_tender_deletion_not_allowed:You can only delete saved to cache tenders.`,
    snackbar_delete_tender_tender_deleted: $localize`:@@snackbar_delete_tender_tender_deleted:The tender was deleted.`,
    snackbar_delete_tender_tender_already_deleted: $localize`:@@snackbar_delete_tender_tender_already_deleted:The tender was already deleted.`,
    snackbar_tender_unexpected_error: $localize`:@@snackbar_tender_unexpected_error:An unexpected error occurred.`,
    snackbar_delete_tender_tender_deleted_error: $localize`:@@snackbar_delete_tender_tender_deleted_error:Could not delete tender. An unexpected error occurred. Please try again.`,
    snackbar_select_client_error: $localize`:@@snackbar_select_client_error:Please select a branch.`,
    snackbar_support_label: $localize`:@@snackbar_support_label:Support`,
    snackbar_remove_company_company_removed: $localize`:@@snackbar_remove_company_company_removed:Company was removed from tender`,
    snackbar_remove_company_company_removed_error: $localize`:@@snackbar_remove_company_company_removed_error:Could not remove company from tender. An unexpected error occurred. Please try again.`,
    snackbar_remove_company_company_already_removed: $localize`:@@snackbar_remove_company_company_already_removed:Company was already removed from tender.`,
    mat_paginator_items_per_page: $localize`:@@mat_paginator_items_per_page:Items per page`,
    mat_paginator_next_page: $localize`:@@mat_paginator_next_page:Next page`,
    mat_paginator_previous_page: $localize`:@@mat_paginator_previous_page:Previous page`,
    mat_paginator_first_page: $localize`:@@mat_paginator_first_page:First page`,
    mat_paginator_last_page: $localize`:@@mat_paginator_last_page:Last page`,
    mat_paginator_range_page_1: $localize`:@@mat_paginator_range_page_1:0 of ?length?`,
    mat_paginator_range_page_2: $localize`:@@mat_paginator_range_page_2:?startIndex? - ?endIndex?  of ?length?`,
    tender_contact_default_function: $localize`:@@tender_contact_default_function:contact`,
    save_modal_title: $localize`:@@save_modal_title:Save entries`,
    selective_tender_invalid_dates_message: $localize`:@@selective_tender_invalid_dates_message:The dates "SUBMIT OFFER BY" (?submitOfferBy?), "SUBMIT APPLICATION BY" (?submitApplicationBy?) and "FEEDBACK BY" (?feedbackBy?) should not be in the past.Do you still want to save the tender?`,
    open_and_invitation_tender_invalid_dates_message: $localize`:@@open_and_invitation_tender_invalid_dates_message:The dates "SUBMIT OFFER BY" (?submitOfferBy?) and "FEEDBACK BY" (?feedbackBy?) should not be in the past.Do you still want to save the tender?`,
    project_details_button_plans: $localize`:@@project_details_button_plans:Plans`,
    plans_title: $localize`:@@plans_title:Plans`,
    plans_breadcrumb: $localize`:@@plans_breadcrumb:Plans`,
    button_upload_plans: $localize`:@@button_upload_plans:Upload plans`,
    upload_plans_breadcrumb: $localize`:@@upload_plans_breadcrumb:Upload plans`,
    validation_and_upload: $localize`:@@validation_and_upload:Validation and Upload`,
    no_naming_scheme_message: $localize`:@@no_naming_scheme_message:No naming scheme is activated, the file names are not validated.`,
    upload_files_error: $localize`:@@upload_files_error:There was an error while uploading files.`,
    create_plan_error: $localize`:@@create_plan_error:There was an error while creating the plan.`,
    plans_column_name_plan: $localize`:@@plans_column_name_plan:Plan`,
    min_attachments_length: $localize`:@@min_attachments_length:Can not create plans. No files uploaded or one or more uploads contain an error.`,
    pp_server_validation_error_unused: $localize`:@@pp_server_validation_error_unused:File name already used`,
    remove_company_modal_title: $localize`:@@remove_company_modal_title:Remove company`,
    remove_company_modal_text: $localize`:@@remove_company_modal_text:Should the company be removed permanently?`,
    invite_bidder_email: $localize`:@@invite_bidder_email:Email`,
    invite_bidder_phone: $localize`:@@invite_bidder_phone:Phone`,
    invite_bidder_fax: $localize`:@@invite_bidder_fax:Fax`,
    invite_bidder_blacklist: $localize`:@@invite_bidder_blacklist:Blacklist`,
    no_open_tenders_in_project: $localize`:@@no_open_tenders_in_project:No open tenders in the project`,
    validation_error_between: $localize`:@@validation_error_between:Value must be between ?min? and ?max?.`,
    favorite_tooltip_mark_as_favorite: $localize`:@@favorite_tooltip_mark_as_favorite:Mark as favorite`,
    favorite_tooltip_marked_as_favorite: $localize`:@@favorite_tooltip_marked_as_favorite:Marked as favorite`,
    favorite_tooltip_remove_as_favorite: $localize`:@@favorite_tooltip_remove_as_favorite:Remove as favorite`,
    blacklist_tooltip_blacklisted: $localize`:@@blacklist_tooltip_blacklisted:Blacklisted`,
    more_projects: $localize`:@@more_projects:More projects`,
    bidders_tender_dashboard: $localize`:@@bidders_tender_dashboard:Tender Dashboard`,
    bidders_tender_dashboard_header_tender: $localize`:@@bidders_tender_dashboard_header_tender:Tender`,
    bidders_tender_dashboard_header_submit_offer_by: $localize`:@@bidders_tender_dashboard_header_submit_offer_by:Submit offer by`,
    bidders_tender_dashboard_header_project: $localize`:@@bidders_tender_dashboard_header_project:Project`,
    bidders_tender_dashboard_header_issuer: $localize`:@@bidders_tender_dashboard_header_issuer:Issuer`,
    bidders_tender_dashboard_search_tender: $localize`:@@bidders_tender_dashboard_search_tender:Tender`,
    bidders_tender_dashboard_search_project: $localize`:@@bidders_tender_dashboard_search_project:Project`,
    bidders_tender_dashboard_search_issuer: $localize`:@@bidders_tender_dashboard_search_issuer:Issuer`,
    invite_bidder_accepted_types: $localize`:@@invite_bidder_accepted_types:Accepted file types`,
    business_directory: $localize`:@@business_directory:Business Directory`,
    directory: $localize`:@@directory:Private Directory`,
    menu_bidder: $localize`:@@menu_bidder:Bidder`,
    menu_bidder_tenders: $localize`:@@menu_bidder_tenders:Tenders`,
    menu_bidder_info_package: $localize`:@@menu_bidder_info_package:Info package`,
    add_one_company_error_message: $localize`:@@add_one_company_error_message:Please add at least one company`,
    hamburger_menu_settings: $localize`:@@hamburger_menu_settings:Settings`,
    hamburger_menu_profile: $localize`:@@hamburger_menu_profile:Profile`,
    hamburger_menu_company: $localize`:@@hamburger_menu_company:Company`,
    bidder_tender_details: $localize`:@@bidder_tender_details:Details`,
    bidder_tender_details_name: $localize`:@@bidder_tender_details_name:Tender`,
    bidder_tender_details_sub_date: $localize`:@@bidder_tender_details_sub_date:Submit application by`,
    bidder_tender_details_offer_by: $localize`:@@bidder_tender_details_offer_by:Submit offer by`,
    bidder_tender_details_bidding_by: $localize`:@@bidder_tender_details_bidding_by:Bidding by`,
    bidder_tender_details_supplier_info: $localize`:@@bidder_tender_details_supplier_info:Supplier information`,
    bidder_tender_details_project: $localize`:@@bidder_tender_details_project:Project`,
    bidder_tender_details_issuer: $localize`:@@bidder_tender_details_issuer:Issuer`,
    bidder_tender_details_changes_title: $localize`:@@bidder_tender_details_changes_title:Tender details have changed since your offer.`,
    bidder_tender_details_changes_time_label: $localize`:@@bidder_tender_details_changes_time_label:Change Message:`,
    bidder_tender_list_state_accepted: $localize`:@@bidder_tender_list_state_accepted:Accepted`,
    bidder_tender_list_state_rejected: $localize`:@@bidder_tender_list_state_rejected:Rejected`,
    bidder_tender_list_state_pending: $localize`:@@bidder_tender_list_state_pending:Pending`,
    bidder_tender_list_state_open: $localize`:@@bidder_tender_list_state_open:Open`,
    bidder_tender_list_state_will_offer: $localize`:@@bidder_tender_list_state_will_offer:Will submit an offer`,
    bidder_tender_list_state_application_rejected: $localize`:@@bidder_tender_list_state_application_rejected:Rejected`,
    bidder_tender_list_state_invited: $localize`:@@bidder_tender_list_state_invited:Invited`,
    bidder_tender_list_state_signed_off: $localize`:@@bidder_tender_list_state_signed_off:Signed off`,
    bidder_tender_list_state_in_bidding: $localize`:@@bidder_tender_list_state_in_bidding:In bidding round`,
    bidder_tender_list_state_bid_placed: $localize`:@@bidder_tender_list_state_bid_placed:Bid submitted`,
    bidder_tender_list_state_application_expired: $localize`:@@bidder_tender_list_state_application_expired:Expired`,
    bidder_tender_list_state_application_possibility: $localize`:@@bidder_tender_list_state_application_possibility:Application possibility`,
    bidder_tender_list_state_offer_possibility: $localize`:@@bidder_tender_list_state_offer_possibility:Offer possibility`,
    bidder_tender_list_state_offer_rejected: $localize`:@@bidder_tender_list_state_offer_rejected:Rejected`,
    bidder_tender_list_state_offered: $localize`:@@bidder_tender_list_state_offered:Offered`,
    bidder_tender_list_state_applied: $localize`:@@bidder_tender_list_state_applied:Applied`,
    bidder_tender_list_state_closed: $localize`:@@bidder_tender_list_state_closed:Closed`,
    bidder_tender_list_tender: $localize`:@@bidder_tender_list_tender:Tender`,
    bidder_tender_list_start: $localize`:@@bidder_tender_list_start:Start work by`,
    bidder_tender_list_submission: $localize`:@@bidder_tender_list_submission:Offer Submission by`,
    bidder_tender_list_bidding_by: $localize`:@@bidder_tender_list_bidding_by:Bidding by`,
    bidder_tender_list_issuer: $localize`:@@bidder_tender_list_issuer:Issuer`,
    bidder_tender_list_feedback: $localize`:@@bidder_tender_list_feedback:Feedback by`,
    bidder_tender_list_published: $localize`:@@bidder_tender_list_published:Published`,
    bidder_tender_list_application_date: $localize`:@@bidder_tender_list_application_date:Application by`,
    bidder_tender_list_submission_deadline_warn: $localize`:@@bidder_tender_list_submission_deadline_warn:Expires in ?days? day(s)`,
    bidder_tender_list_changes_label: $localize`:@@bidder_tender_list_changes_label:Changes`,
    bidder_tender_filter_tenders: $localize`:@@bidder_tender_filter_tenders:Filter Tenders`,
    bidder_tender_favorites_only: $localize`:@@bidder_tender_favorites_only:Favorites only`,
    work_description_processing_error: $localize`:@@work_description_processing_error:Cannot process the work description`,
    work_description_processing_error_on_draft: $localize`:@@work_description_processing_error_on_draft:The work description is defective. Since the tender is in draft, you are still able to save it.`,
    work_description_defective_on_draft: $localize`:@@work_description_defective_on_draft:The CRB SIA test detected errors for this file. Please correct the errors for publishing.`,
    cannot_navigate_can_accept: $localize`:@@cannot_navigate_can_accept:Cannot accept application, it might have already been accepted`,
    cannot_navigate_can_reject: $localize`:@@cannot_navigate_can_reject:Cannot reject application, it might have already been rejected`,
    not_determinable: $localize`:@@not_determinable:Not determinable`,
    ok: $localize`:@@ok:Ok'`,
    remove_participant: $localize`:@@remove_participant:Remove participant`,
    remove_self_contact_message: $localize`:@@remove_self_contact_message:Warning: You are in the process of removing yourself! You will lose all access rights.`,
    remove_last_participant_error: $localize`:@@remove_last_participant_error:It is not possible to remove all participants. At least one person must retain access rights`,
    apply: $localize`:@@apply:Apply`,
    see_more_reference_projects: $localize`:@@see_more_reference_projects:See More Reference Projects`,
    application_successful: $localize`:@@application_successful:Application sent successfully`,
    favorite_bidder_tooltip_mark_as_favorite: $localize`:@@favorite_bidder_tooltip_mark_as_favorite:Mark company as favorite`,
    favorite_bidder_tooltip_remove_as_favorite: $localize`:@@favorite_bidder_tooltip_remove_as_favorite:Remove company as favorite`,
    display_only_favorites: $localize`:@@display_only_favorites:Display only favorite companies`,
    display_only_blacklisted: $localize`:@@display_only_blacklisted:Display only blacklisted companies`,
    display_blacklisted: $localize`:@@display_blacklisted:Display blacklisted companies`,
    favorite_contractor: $localize`:@@favorite_contractor:Favorite company`,
    blacklisted_company: $localize`:@@blacklisted_company:Blacklisted companies`,
    bidder_tender_sidenav_submission_deadline: $localize`:@@bidder_tender_sidenav_submission_deadline:Submission deadline`,
    page_selected_length_1: $localize`:@@page_selected_length_1:All`,
    page_selected_length_2: $localize`:@@page_selected_length_2:companies on this page are selected`,
    log_project_created: $localize`:@@log_project_created:Project created`,
    log_project_edited: $localize`:@@log_project_edited:Project edited`,
    log_project_tender_template_created: $localize`:@@log_project_tender_template_created:Tender template created`,
    log_project_tender_template_edited: $localize`:@@log_project_tender_template_edited:Tender template edited`,
    log_project_participant_added: $localize`:@@log_project_participant_added:Participant added`,
    log_project_participant_removed: $localize`:@@log_project_participant_removed:Participant removed`,
    will_submit_offer: $localize`:@@will_submit_offer:Will submit offer`,
    will_submit_offer_success_message: $localize`:@@will_submit_offer_success_message:Issuer Notified of future Submission of Offer`,
    create_application: $localize`:@@create_application:Create application`,
    submit_application: $localize`:@@submit_application:Submit application`,
    bidder_tender_application: $localize`:@@bidder_tender_application:Application`,
    bidder_tender_create_offer: $localize`:@@bidder_tender_create_offer:Create offer`,
    bidder_tender_create_offer_hint: $localize`:@@bidder_tender_create_offer_hint:At first, tell the issuer that you will submit an offer, so he is informed about your intention`,
    bidder_tender_create_offer_submit_offer: $localize`:@@bidder_tender_create_offer_submit_offer:Submit offer`,
    bidder_tender_create_offer_documents: $localize`:@@bidder_tender_create_offer_documents:Offer documents`,
    bidder_tender_create_offer_details: $localize`:@@bidder_tender_create_offer_details:Offer details`,
    bidder_tender_create_offer_no_category: $localize`:@@bidder_tender_create_offer_no_category:No category`,
    bidder_tender_create_offer_upload_documents_description: $localize`:@@bidder_tender_create_offer_upload_documents_description:Add a file description here`,
    bidder_tender_create_offer_upload_documents_file_size_error: $localize`:@@bidder_tender_create_offer_upload_documents_file_size_error:The maximum file size is ?size? MB`,
    bidder_tender_create_offer_no_category_error: $localize`:@@bidder_tender_create_offer_no_category_error:Documents without category.Please complete the mandatory information, before proceeding`,
    bidder_tender_create_offer_cover_sheet: $localize`:@@bidder_tender_create_offer_cover_sheet:Cover sheet`,
    bidder_tender_create_offer_terms_conditions: $localize`:@@bidder_tender_create_offer_terms_conditions:Terms & conditions`,
    bidder_tender_create_offer_save_as_draft: $localize`:@@bidder_tender_create_offer_save_as_draft:Save as draft`,
    bidder_tender_create_offer_draft_saved: $localize`:@@bidder_tender_create_offer_draft_saved:Your offer is saved`,
    bidder_tender_create_file_uploading_wait: $localize`:@@bidder_tender_create_file_uploading_wait: One of your attatchment files is still being uploaded, wait for it to finish before saving your offer`,
    menu_bidder_construction_data: $localize`:@@menu_bidder_construction_data:Construction data`,
    menu_bidder_project_list: $localize`:@@menu_bidder_project_list:Project list`,
    menu_bidder_project_handling: $localize`:@@menu_bidder_project_handling:Project handling`,
    menu_bidder_public_tenders: $localize`:@@menu_bidder_public_tenders:Public tenders`,
    application_text_placeholder: $localize`:@@application_text_placeholder:Type something`,
    bidder_tender_deregister: $localize`:@@bidder_tender_deregister:Deregistration`,
    bidder_tender_deregister_confirm: $localize`:@@bidder_tender_deregister_confirm:Do you really want to deregister from this teder`,
    bidder_tender_deregister_explain: $localize`:@@bidder_tender_deregister_explain:Please let the advertiser know why you are deregistering`,
    bidder_tender_deregister_reason: $localize`:@@bidder_tender_deregister_reason:Reason`,
    bidder_tender_deregister_description: $localize`:@@bidder_tender_deregister_description:Description`,
    bidder_tender_download_all: $localize`:@@bidder_tender_download_all:Download All`,
    deregister_description_placeholder: $localize`:@@deregister_description_placeholder:Here you can write a message to the issuer`,
    deregister_reason_placeholder: $localize`:@@deregister_reason_placeholder:Choose an option`,
    deregister_reason_not_interested_in_cooperation: $localize`:@@deregister_reason_not_interested_in_cooperation:Not interested in cooperation`,
    deregister_reason_no_capacity: $localize`:@@deregister_reason_no_capacity:No capacity`,
    deregister_reason_project_too_big: $localize`:@@deregister_reason_project_too_big:Project to big`,
    deregister_reason_too_far_away: $localize`:@@deregister_reason_too_far_away:Too far away`,
    deregister_reason_other: $localize`:@@deregister_reason_other:Other`,
    deregister_label_date: $localize`:@@deregister_label_date:Date`,
    deregister_label_reason: $localize`:@@deregister_label_reason:Reason`,
    deregister_label_description: $localize`:@@deregister_label_description:Description`,
    application_text: $localize`:@@application_text:Application text`,
    apply_for_tender: $localize`:@@apply_for_tender:Apply for tender`,
    application_submitted: $localize`:@@application_submitted:Application submitted`,
    not_yet_applied: $localize`:@@not_yet_applied:Not yet applied`,
    application_summary: $localize`:@@application_summary:Summary`,
    application_message: $localize`:@@application_message:Application message`,
    deregister: $localize`:@@deregister:Deregister`,
    deregister_success_message: $localize`:@@deregister_success_message:The deregistration was noted and the issuer was informed.`,
    submit_offer_later: $localize`:@@submit_offer_later:Submit an offer later?`,
    confirm_notify: $localize`:@@confirm_notify:Yes, notify`,
    notify_user: $localize`:@@notify_user:Would you like to notify the Issuer that you will be submitting an offer by`,
    invite_attractive_offers: $localize`:@@invite_attractive_offers:Invite potential suppliers to support you with attractive offers and advice to support you in your offer process.`,
    masterplan_replace_import_label: $localize`:@@masterplan_replace_import_label:Upload`,
    masterplan_replace_info_message: $localize`:@@masterplan_replace_info_message:Please note that following will occur when replacing a masterplan`,
    masterplan_replace_pins_message: $localize`:@@masterplan_replace_pins_message:All entries with pins need to be uploaded before replacing a masterplan.`,
    masterplan_replace_filesize_message: $localize`:@@masterplan_replace_filesize_message:The file size might be different and pins might be moved to another location.`,
    masterplan_replace_validation_pdf: $localize`:@@masterplan_replace_validation_pdf:Only .pdf files allowed.`,
    masterplan_replace_naming_convention_info: $localize`:@@masterplan_replace_naming_convention_info:Please note that the naming convention changes according to the original name.`,
    masterplan_replace_success: $localize`:@@masterplan_replace_success:Masterplan succesfully replaced`,
    masterplan_details_all_versions: $localize`:@@masterplan_details_all_versions:All versions`,
    masterplan_created_by: $localize`:@@masterplan_created_by:Created by`,
    masterplan_created_at: $localize`:@@masterplan_created_at:Created at`,
    cancel_application: $localize`:@@cancel_application:Cancel application`,
    cancel_application_success: $localize`:@@cancel_application_success:Cancellation of the application was successful`,
    masterplan_table_header_name: $localize`:@@masterplan_table_header_name:Name`,
    masterplan_table_header_description: $localize`:@@masterplan_table_header_description:Description`,
    masterplan_table_header_plan_informations: $localize`:@@masterplan_table_header_plan_informations:Plan Informations`,
    masterplan_table_header_uploaded_by: $localize`:@@masterplan_table_header_uploaded_by:Created by`,
    masterplan_table_header_status: $localize`:@@masterplan_table_header_status:Status`,
    inspection_can_be_seen_after_deadline: $localize`:@@inspection_can_be_seen_after_deadline:The offers received can only be accessed after the offer submission deadline has expired on ?submissionDeadline?.`,
    masterplan_dialog_activate_title: $localize`:@@masterplan_dialog_activate_title:Are you sure you want to activate this plan?`,
    masterplan_dialog_activate_desc: $localize`:@@masterplan_dialog_activate_desc:By activating this plan now it will be visible.`,
    masterplan_dialog_deactivate_title: $localize`:@@masterplan_dialog_deactivate_title:Are you sure you want to deactivate this plan?`,
    masterplan_dialog_deactivate_desc: $localize`:@@masterplan_dialog_deactivate_desc:By deactivating this plan now it will not be shown.`,
    masterplan_activate_success: $localize`:@@masterplan_activate_success:Masterplan successfully activated.`,
    masterplan_deactivate_success: $localize`:@@masterplan_deactivate_success:Masterplan successfully deactivated.`,
    masterplan_deactivate_hint: $localize`:@@masterplan_deactivate_hint:Hint:All entries need to be uploaded before setting the pin inactive.`,
    tender_release_options: $localize`:@@tender_release_options:Release Options`,
    tender_release_draft: $localize`:@@tender_release_draft:Draft`,
    tender_release_publish_now: $localize`:@@tender_release_publish_now:Publish now`,
    tender_release_publish_on: $localize`:@@tender_release_publish_on:Publish on`,
    tender_button_publish: $localize`:@@tender_button_publish:Publish`,
    tender_release_published_on: $localize`:@@tender_release_published_on:Published on`,
    tender_release_published_message: $localize`:@@tender_release_published_message:Congratulations, your tender is now published.`,
    tender_scheduled_date: $localize`:@@tender_scheduled_date:Choose a date`,
    tender_error_message_release_options: $localize`:@@tender_error_message_release_options:You need to select a release option`,
    tender_error_message_scheduled_date_past: $localize`:@@tender_error_message_scheduled_date_past:The date for publishing the tender should not be in the past.`,
    tender_error_message_scheduled_date_future: $localize`:@@tender_error_message_scheduled_date_future:The date for publishing cannot be scheduled after the date you chose for Submit Application by, Feedback by or Submit offer by, Please reschedule the date.`,
    tender_release_scheduled_message: $localize`:@@tender_release_scheduled_message:Congratulations, your tender will be Published at ?date? at ?time?.`,
    tender_release_hint_publish: $localize`:@@tender_release_hint_publish:Click the publish button to release the publication.`,
    tender_details_expired: $localize`:@@tender_details_expired:Expired Tender`,
    tender_details_approval_on: $localize`:@@tender_details_approval_on:Approval created on ?date?`,
    tender_details_bidding_by: $localize`:@@tender_details_bidding_by:Bidding by ?date?`,
    tender_details_submit_by: $localize`:@@tender_details_submit_by:Submit offer by ?date?`,
    tender_details_published_at: $localize`:@@tender_details_published_at:Published at ?date?`,
    tender_details_saved_as_draft: $localize`:@@tender_details_saved_as_draft:Saved as draft`,
    tender_details_closed_on: $localize`:@@tender_details_closed_on:Tender Closed on ?date?`,
    tender_details_submission_extended_to: $localize`:@@tender_details_submission_extended_to:Submission Extended to ?date?`,
    tender_details_publish_scheduled: $localize`:@@tender_details_publish_scheduled:Publication scheduled on ?date?`,
    tender_log_publication_scheduled: $localize`:@@tender_log_publication_scheduled:Tender will be published on ?date?`,
    tender_log_publication_cancelled: $localize`:@@tender_log_publication_cancelled:Tender publication is cancelled`,
    tender_log_publication_automatically: $localize`:@@tender_log_publication_automatically:Tender published automatically`,
    tender_log_publication_scheduled_label: $localize`:@@tender_log_publication_scheduled_label:Publication scheduled`,
    tender_inspection: $localize`:@@tender_inspection:Inspection`,
    tender_inspection_allowed: $localize`:@@tender_inspection_allowed:Immediate`,
    tender_inspection_not_allowed: $localize`:@@tender_inspection_not_allowed:After offer submission deadline`,
    bidder_details_reject_contractor: $localize`:@@bidder_details_reject_contractor:Reject contractor`,
    bidder_reject_contractor_inform_hint: $localize`:@@bidder_reject_contractor_inform_hint:By default, the contractor is sent a notification of the rejection. If you wish, you can enter a reason why you are rejecting the contractor. This reason will be sent to the bidder together with the notification of the rejection.`,
    bidder_reject_contractor_message_placeholder: $localize`:@@bidder_reject_contractor_message_placeholder:If you wish, you can enter a reason why you are rejecting the contractor. This reason will be sent to the bidder together with the notification of the rejection.`,
    bidder_contractor_rejected: $localize`:@@bidder_contractor_rejected:Contractor rejected`,
    bidder_reject_contractor_no_email_hint: $localize`:@@bidder_reject_contractor_no_email_hint:No reason for rejection can be sent to this entrepreneur, since no email address is stored.`,
    bidder_contractor_rejected_save: $localize`:@@bidder_contractor_rejected_save:Reject`,
    cannot_navigate_can_reject_contractor: $localize`:@@cannot_navigate_can_reject_contractor:Cannot reject contractor, they might have already been rejected`,
    reject_contractors_first_step_title: $localize`:@@reject_contractors_first_step_title:Select contractors`,
    reject_contractors_second_step_title: $localize`:@@reject_contractors_second_step_title:Sent notification`,
    reject_contractors_checkbox_label: $localize`:@@reject_contractors_checkbox_label:Reject`,
    reject_contractors_table_contractor: $localize`:@@reject_contractors_table_contractor:Contractor`,
    reject_contractors_table_selection: $localize`:@@reject_contractors_table_selection:Selection`,
    reject_contractors_table_reject: $localize`:@@reject_contractors_table_reject:Reject`,
    reject_contractors_table_receipt: $localize`:@@reject_contractors_table_receipt:Receipt`,
    reject_contractors_table_status: $localize`:@@reject_contractors_table_status:Status`,
    reject_contractors_table_phone: $localize`:@@reject_contractors_table_phone:Phone`,
    reject_contractors_table_fax: $localize`:@@reject_contractors_table_fax:Fax`,
    reject_contractors_table_email: $localize`:@@reject_contractors_table_email:E-mail`,
    reject_contractors_table_conformity: $localize`:@@reject_contractors_table_conformity:Conformity`,
    reject_contractors_table_gross_exl_vat: $localize`:@@reject_contractors_table_gross_exl_vat:Gross excluding VAT`,
    reject_contractors_table_net_incl_vat: $localize`:@@reject_contractors_table_net_incl_vat:Net including VAT`,
    reject_contractors_step_error: $localize`:@@reject_contractors_step_error:Please select at least one contractor`,
    reject_contractors_reject_hint: $localize`:@@reject_contractors_reject_hint:If you wish, you can enter a personal note to the contractor. Otherwise, a standard notification email is sent to the contractor.`,
    reject_contractors_step_1_side_hint: $localize`:@@reject_contractors_step_1_side_hint:Selection: Per default, all contractors are marked for rejection. Please remove the ticks from the contractors whose offer you do NOT want to reject`,
    reject_contractors_step_2_side_hint: $localize`:@@reject_contractors_step_2_side_hint:If you wish, you can enter a personal note to the contractor. Otherwise, a standard notification email is sent to the contractor.`,
    reject_contractors_copy_to_all: $localize`:@@reject_contractors_copy_to_all:Copy text to all`,
    reject_contractors_snack_bar_success: $localize`:@@reject_contractors_snack_bar_success:You rejected ?contractors? contractors successfully. The companies will be informed about your decision`,
    reject_contractors_explanation: $localize`:@@reject_contractors_explanation:Explanation`,
    reject_contractors_table_view_details: $localize`:@@reject_contractors_table_view_details:View details`,
    form_label_main_contact: $localize`:@@form_label_main_contact:Main contact`,
    logs_load_more: $localize`:@@logs_load_more:Load more`,
    logs_number_of_entries: $localize`:@@logs_number_of_entries:?numberOfEntries? Entries`,
    logs_expand: $localize`:@@logs_expand:Expand`,
    logs_collapse: $localize`:@@logs_collapse:Collapse`,
    name: $localize`:@@name:Project name`,
    object_type: $localize`:@@object_type:Object type`,
    description: $localize`:@@description:Project description`,
    phase: $localize`:@@phase:Phase`,
    construction_start: $localize`:@@construction_start:Start of construction`,
    construction_end: $localize`:@@construction_end:End of construction`,
    contract_volume: $localize`:@@contract_volume:Order volume`,
    building_types: $localize`:@@building_types:Building type`,
    project_logs_building_site_address: $localize`:@@project_logs_building_site_address:Building site address`,
    project_logs_construction_owner: $localize`:@@project_logs_construction_owner:Construction owner`,
    project_logs_changed_to: $localize`:@@project_logs_changed_to:changed to`,
    project_logs_deleted: $localize`:@@project_logs_deleted:deleted`,
    project_logs_added: $localize`:@@project_logs_added:added`,
    client_dialog_delete_title: $localize`:@@client_dialog_delete_title:Delete contractor`,
    client_dialog_delete_message: $localize`:@@client_dialog_delete_message:Should the contractor be deleted permanently?`,
    client_dialog_delete_success: $localize`:@@client_dialog_delete_success:The company is permanently deleted`,
    client_dialog_delete_error_message: $localize`:@@client_dialog_delete_error_message:The company cannot be deleted at the moment, as it is still in running tenders:?message?`,
    client_dialog_delete_error_title: $localize`:@@client_dialog_delete_error_title:Contractor cannot be deleted`,
    tender_logs_submission_address: $localize`:@@tender_logs_submission_address:Submission address for offers`,
    customer_reference_number: $localize`:@@customer_reference_number:Tender number`,
    estimated_budget: $localize`:@@estimated_budget:Estimated budget`,
    project_budget: $localize`:@@project_budget:Project budget`,
    submission_date: $localize`:@@submission_date:Submit offer by`,
    application_date: $localize`:@@application_date:Submit application by`,
    feedback_date: $localize`:@@feedback_date:Feedback by`,
    accepting_date: $localize`:@@accepting_date:To be awarded on`,
    offer_date: $localize`:@@offer_date:Offers are valid until`,
    workstart: $localize`:@@workstart:Start of work on`,
    reminder_time: $localize`:@@reminder_time:Reminder lead time`,
    tender_kind: $localize`:@@tender_kind:Tender`,
    immediate_inspection_allowed: $localize`:@@immediate_inspection_allowed:Inspection`,
    comment: $localize`:@@comment:Comment on the tender`,
    submission_input: $localize`:@@submission_input:Offer submission`,
    type_of_work: $localize`:@@type_of_work:Type of work`,
    attachment: $localize`:@@attachment:Attachments`,
    deductions: $localize`:@@deductions:Deductions`,
    contacts: $localize`:@@contacts:Contacts`,
    tender_name: $localize`:@@tender_name:Work description`,
    tender_logs_not_allowed_by_post: $localize`:@@tender_logs_not_allowed_by_post:not allowed by post`,
    tender_logs_inspection_immediately: $localize`:@@tender_logs_inspection_immediately:Immediately`,
    tender_logs_inspection_after_deadline: $localize`:@@tender_logs_inspection_after_deadline:After submission deadline`,
    deduction_period: $localize`:@@deduction_period:Discount period`,
    deduction_vat: $localize`:@@deduction_vat:VAT rate`,
    project_logs_supplier_questions_allowed: $localize`:@@project_logs_supplier_questions_allowed:Allowed`,
    project_logs_supplier_questions_not_allowed: $localize`:@@project_logs_supplier_questions_not_allowed:Not allowed`,
    tender_info_allowed: $localize`:@@tender_info_allowed:Supplier questions`,
    no_contractors_to_be_rejected: $localize`:@@no_contractors_to_be_rejected:There are no contractors to be rejected`,
    picture: $localize`:@@picture:Picture`,

    // form errors start ===============================================================================================
    form_error_required: $localize`:@@form_error_required:This field is required.`,
    form_error_max_value: $localize`:@@form_error_max_value:`,
    form_error_min_value: $localize`:@@form_error_min_value:`,
    form_error_max_length: $localize`:@@form_error_max_length:Only ?numberOfChars? characters are allowed.`,
    form_error_lesser_or_equal_than: $localize`:@@form_error_lesser_or_equal_than: value should be lesser or equal than ?value?`,
    form_error_min_length: $localize`:@@form_error_min_length:At least ?numberOfChars? characters are required.`,
    form_error_between: $localize`:@@form_error_between:Value must be between ?min? and ?max?.`,
    form_error_percent: $localize`:@@form_error_percent:Values must be between 0% and 100%!`,
    form_error_decimal_format: $localize`:@@form_error_decimal_format:Only decimals with zero to two decimal places separated by comma or dot are allowed.`,
    form_error_absolute_min: $localize`:@@form_error_absolute_min:Please enter a positive value!`,
    form_error_email_format: $localize`:@@form_error_email_format:Invalid e-mail! Must be in local@domain format! Umlauts and special characters are not allowed!`,
    form_error_phone_format: $localize`:@@form_error_phone_format:Invalid number! Must have minimum length of 4 characters and start with + or 00!`,
    form_error_min_skills: $localize`:@@form_error_min_skills:You must add at least one type of work!`,
    form_error_min_contacts: $localize`:@@form_error_min_contacts:You must add at least one contact person!`,
    form_error_percent_or_absolute: $localize`:@@form_error_percent_or_absolute:Please enter either percentage or absolute value!`,
    form_error_min_building_types: $localize`:@@form_error_min_building_types:You must add at least one type of building!`,
    form_error_construction_start_before_end: $localize`:@@form_error_construction_start_before_end:The "start of construction" must be before the "end of construction".`,
    form_error_contacts_not_from_company: $localize`:@@form_error_contacts_not_from_company:You must add at least one contact person from the issuing company.`,
    form_error_contacts_not_from_project: $localize`:@@form_error_contacts_not_from_project:All contacts must be participants of the project.`,
    form_error_duplicate_email: $localize`:@@form_error_duplicate_email:There is already a company with this e-mail address.`,
    form_error_duplicate_fax: $localize`:@@form_error_duplicate_fax:There is already a company with this fax number.`,
    form_error_email_or_fax: $localize`:@@form_error_email_or_fax:Please specify either "E-mail" or "Fax", otherwise the company cannot be notified.`,
    form_error_file_upload_error: $localize`:@@form_error_file_upload_error:There was an error while uploading files.`,
    form_error_create_plan_error: $localize`:@@form_error_create_plan_error:There was an error while creating the plan.`,
    form_error_future_and_after_tender_submission_date: $localize`:@@form_error_future_and_after_tender_submission_date:Date must be in the future after the submission deadline for the offer.`,
    // form errors end =================================================================================================
    duplicate_email_issuer: $localize`:@@duplicate_email_issuer:The company is already registered on Olmero.`,
    duplicate_email_private: $localize`:@@duplicate_email_private:The company already exists in the private directory.`,
    company_member_of_olmero_network: $localize`:@@company_member_of_olmero_network:This company is a member of the Olmero network and manages its own contact data.`,
    project_dashboard_all_projects: $localize`:@@project_dashboard_all_projects:All Projects`,
    project_dashboard_favorites: $localize`:@@project_dashboard_favorites:Favorites`,
    project_dashboard_running: $localize`:@@project_dashboard_running:Running Projects`,
    project_dashboard_closed: $localize`:@@project_dashboard_closed:Closed Projects`,
    project_dashboard: $localize`:@@project_dashboard:Project Dashboard`,
    project_dashboard_work_in_progress: $localize`:@@project_dashboard_work_in_progress:Work in progress`,
    project_dashboard_volume: $localize`:@@project_dashboard_volume:Volume`,
    project_dashboard_construction_duration: $localize`:@@project_dashboard_construction_duration:Construction duration`,
    project_dashboard_contact_person: $localize`:@@project_dashboard_contact_person:Contact person`,
    project_dashboard_open_applicants: $localize`:@@project_dashboard_open_applicants:Open applicants`,
    project_dashboard_number_of_tenders: $localize`:@@project_dashboard_number_of_tenders:Number of ongoing tenders`,
    project_dashboard_total_offers: $localize`:@@project_dashboard_total_offers:Number of tenders in draft`,
    project_dashboard_savings: $localize`:@@project_dashboard_savings:Savings`,
    project_dashboard_additional_costs: $localize`:@@project_dashboard_additional_costs:Additional costs`,
    project_dashboard_sum_of_all_lowest_offers: $localize`:@@project_dashboard_sum_of_all_lowest_offers:Sum of all Lowest Offers`,
    project_dashboard_savings_and_additional_cost_cumulated: $localize`:@@project_dashboard_savings_and_additional_cost_cumulated:Sum of all tender results (Savings and additional expenses cumulated)`,
    project_core_number_of_tenders_with_offer: $localize`:@@project_core_number_of_tenders_with_offer:Number of tenders with offers`,
    project_core_number_of_tenders_with_quotation_but_without_offer: $localize`:@@project_core_number_of_tenders_with_quotation_but_without_offer:Number of tenders with quotation but without offer`,
    no_favorites_project_dashboard: $localize`:@@no_favorites_project_dashboard:You have not marked any projects as a favorite yet, just select the star on the right side of the project line`,
    no_projects_project_dashboard: $localize`:@@no_projects_project_dashboard:Currently you have no projects`,
    email_error_default: $localize`:@@email_error_default:Unknown error`,
    email_error_default_message: $localize`:@@email_error_default_message:An unexpected error has occurred`,
    email_error_address_not_found: $localize`:@@email_error_address_not_found:Address not found`,
    email_error_address_not_found_message: $localize`:@@email_error_address_not_found_message:Your message was not delivered to ?email? because the address could not be found, or is unable to receive email`,
    email_error_address_deferred: $localize`:@@email_error_address_deferred:Connection error`,
    email_error_address_deferred_message: $localize`:@@email_error_address_deferred_message:Temporary connection error. The email could not be sent, please try again`,
    email_error_address_dropped: $localize`:@@email_error_address_dropped:Invalid domain`,
    email_error_address_dropped_message: $localize`:@@email_error_address_dropped_message:Invalid domain. Please correct the email address and try again`,
    search_project_dashboard: $localize`:@@search_project_dashboard:Project search`,
    sorting_project_dashboard_project_number: $localize`:@@sorting_project_dashboard_project_number:Project Number`,
    sorting_project_dashboard_project_name: $localize`:@@sorting_project_dashboard_project_name:Project Name`,
    display_private_only: $localize`:@@display_private_only:Display only private companies`,
    directory_toggle_private_contractor: $localize`:@@directory_toggle_private_contractor:Private company`,
    bidder_resent_notification_label: $localize`:@@bidder_resent_notification_label:Re-send notification`,
    bidder_resent_notification_button: $localize`:@@bidder_resent_notification_button:Re-send`,
    bidder_resent_notification_message: $localize`:@@bidder_resent_notification_message:Your rejection notification was successfully sent again`,
    project_picture: $localize`:@@project_picture:Picture`,
    project_picture_hint: $localize`:@@project_picture_hint:For best results, provide your project picture in landscape format.`,
    open_tenders_section_header: $localize`:@@open_tenders_section_header:Open Tenders`,
    draft_tenders_section_header: $localize`:@@draft_tenders_section_header:Draft`,
    draft_tenders_publication_date: $localize`:@@draft_tenders_publication_date:Publication date`,
    contact_info_telephone: $localize`:@@contact_info_telephone:Tel`,
    tender_attachment_file_accept_pending: $localize`:@@tender_attachment_file_accept_pending:'File has not been accepted yet.`,
    contact_info_email: $localize`:@@contact_info_email:Email`,
    compare_offer_type: $localize`:@@compare_offer_type: Offer type`,
    compare_gros_excl_vat: $localize`:@@compare_gros_excl_vat: Gross excl. VAT`,
    compare_conditions: $localize`:@@compare_conditions: Conditions`,
    compare_fixed_discount: $localize`:@@compare_fixed_discount: Fixed Discount`,
    compare_early_payment_discount: $localize`:@@compare_early_payment_discount: Early payment Discount`,
    compare_intermediate_results: $localize`:@@compare_intermediate_results: Intermediate result`,
    compare_deductions_before_vat: $localize`:@@compare_deductions_before_vat: Deductions before VAT`,
    compare_netto_excl_vat: $localize`:@@compare_netto_excl_vat: Netto excl. VAT`,
    compare_netto_fixed_price_excl_vat: $localize`:@@compare_netto_fixed_price_excl_vat: Netto fixed price excl. VAT`,
    compare_netto_before_vat: $localize`:@@compare_netto_before_vat: Netto before VAT`,
    compare_vat: $localize`:@@compare_vat: VAT ?percentage?`,
    compare_netto_incl_vat: $localize`:@@compare_netto_incl_vat: Netto incl. VAT`,
    compare_order_least_expensive: $localize`:@@compare_order_least_expensive: In order from least expensive`,
    bider_is_participating_in_ongoing_tenders_hint: $localize`:@@bider_is_participating_in_ongoing_tenders_hint:Bidder is involved in ongoing tenders`,
    bider_is_participating_in_ongoing_tenders_message: $localize`:@@bider_is_participating_in_ongoing_tenders_message:This bidder is involved in the following tenders`,
    advertising: $localize`:@@advertising: Advertising`,
    offers_list_entry_location: $localize`:@@offers_list_entry_location: Entry location`,
    offers_list_entry_location_platform: $localize`:@@offers_list_entry_location_platform: Platform`,
    offers_list_entry_location_outside: $localize`:@@offers_list_entry_location_outside: Outside platform`,
    offers_list_details: $localize`:@@offers_list_details: Details`,
    bidder_tender_project_number: $localize`:@@bidder_tender_project_number: Project number`,
    bidder_tender_project: $localize`:@@bidder_tender_project: Project`,
    bidder_tender_project_name: $localize`:@@bidder_tender_project_name: Project name`,
    bidder_tender_object_type: $localize`:@@bidder_tender_object_type: Object type`,
    bidder_tender_building_type: $localize`:@@bidder_tender_building_type: Building type`,
    bidder_tender_building_site: $localize`:@@bidder_tender_building_site: Building site`,
    bidder_tender_project_start_construction: $localize`:@@bidder_tender_project_start_construction: Start of construction`,
    bidder_tender_project_end_construction: $localize`:@@bidder_tender_project_end_construction: End of construction`,
    bidder_tender_project_order_volume: $localize`:@@bidder_tender_project_order_volume: Order volume`,
    bidder_tender_project_construction_owner: $localize`:@@bidder_tender_project_construction_owner: Construction owner`,
    bidder_tender_project_issuer: $localize`:@@bidder_tender_project_issuer: Issuer`,
    bidder_tender_tender_description: $localize`:@@bidder_tender_tender_description: Tender description`,
    bidder_tender_tender: $localize`:@@bidder_tender_tender: Tender`,
    bidder_tender_type_of_work: $localize`:@@bidder_tender_type_of_work: Type of work`,
    bidder_tender_teder_process: $localize`:@@bidder_tender_teder_process: Tender process`,
    bidder_tender_phase: $localize`:@@bidder_tender_phase: Phase`,
    bidder_tender_submission: $localize`:@@bidder_tender_submission: Submission`,
    bidder_tender_submission_allowed_by_post: $localize`:@@bidder_tender_submission_allowed_by_post: Offer submission is also allowed by post`,
    bidder_tender_submission_electronically_only: $localize`:@@bidder_tender_submission_electronically_only: Offer submission electronically only`,
    bidder_tender_postal_adress: $localize`:@@bidder_tender_postal_adress: Postal adress`,
    bidder_tender_publication: $localize`:@@bidder_tender_publication: Publication`,
    bidder_tender_submit_aplication_by: $localize`:@@bidder_tender_submit_aplication_by: Submit aplication by`,
    bidder_tender_feedback_if_you_intend_to_offer: $localize`:@@bidder_tender_feedback_if_you_intend_to_offer: Feedback if you intend to offer by`,
    bidder_tender_to_be_awarded: $localize`:@@bidder_tender_to_be_awarded: To be awarded on`,
    bidder_tender_start_of_work: $localize`:@@bidder_tender_start_of_work: Start of work`,
    bidder_tender_offers_valid_until: $localize`:@@bidder_tender_offers_valid_until: Offers are valid until`,
    bidder_tender_comment: $localize`:@@bidder_tender_comment: Comment on tender`,
    bidder_tender_contacts: $localize`:@@bidder_tender_contacts: Contacts`,
    bidder_tender_contact_project: $localize`:@@bidder_tender_contact_project: Project contacts`,
    bidder_tender_contact_tender: $localize`:@@bidder_tender_contact_tender: Tender contacts`,
    bidder_tender_contact_main: $localize`:@@bidder_tender_contact_main: Main contact`,
    bidder_tender_other_contact: $localize`:@@bidder_tender_other_contact: Other contact persons`,
    bidder_tender_telephone: $localize`:@@bidder_tender_telephone: Tel. Number`,
    bidder_tender_fax: $localize`:@@bidder_tender_fax: Fax Number`,
    bidder_tender_email: $localize`:@@bidder_tender_email: E-mail`,
    days: $localize`:@@days: days`,
    not_available: $localize`:@@not_available: not traceable`,
    offer_comment: $localize`:@@offer_comment: Comment`,
    form_error_deleted_member: $localize`:@@form_error_deleted_member: Deleted member`,
    pin_offer: $localize`:@@pin_offer: Pin offer`,
    attachment_work_description: $localize`:@@attachment_work_description: Work descriptions`,
    attachment_general_terms: $localize`:@@attachment_general_terms: General terms`,
    attachment_special_terms: $localize`:@@attachment_special_terms: Special terms`,
    attachment_plans: $localize`:@@attachment_plans: Plans`,
    attachment_attachment: $localize`:@@attachment_attachment: Attachments`,
    attachment_candidate_information: $localize`:@@attachment_candidate_information: Application Documents`,
    attachment_open_popup_and_redirect: $localize`:@@attachment_open_popup_and_redirect:In order to be able to open BAU4x, please make sure your browser allows pop-ups and redirects for Olmero`,
    hide_offer: $localize`:@@hide_offer: Hide offer`,
    show_hidden: $localize`:@@show_hidden: Show hidden`,
    applicated_date: $localize`:@@applicated_date: Date`,
    bidder_tender_status: $localize`:@@bidder_tender_status: Status`,
    bidder_blacklisted: $localize`:@@bidder_blacklisted: Bidder is blacklisted`,
    pp_core_form_label_annual_turnover: $localize`:@@pp_core_form_label_annual_turnover:Annual Turnover`,
    tender_log_applications_reminder_about_application: $localize`:@@tender_log_applications_reminder_about_application: Reminder about open applicants was sent`,
    tender_log_applications_reminder_sent: $localize`:@@tender_log_applications_reminder_sent: Reminder sent`,
    tender_log_offer_created_on_behalf: $localize`:@@tender_log_offer_created_on_behalf:Offer created`,
    tender_log_offer_on_behalf_changed: $localize`:@@tender_log_offer_on_behalf_changed:Offer changed`,
    tender_log_offer_on_behalf_deleted: $localize`:@@tender_log_offer_on_behalf_deleted:Offer deleted`,
    tender_log_bid_created_on_behalf: $localize`:@@tender_log_bid_created_on_behalf:Bid created`,
    tender_log_bid_on_behalf_changed: $localize`:@@tender_log_bid_on_behalf_changed:Bid changed`,
    tender_log_bid_on_behalf_deleted: $localize`:@@tender_log_bid_on_behalf_deleted:Bid deleted`,
    tender_log_offer_on_behalf_notification_sent: $localize`:@@tender_log_offer_on_behalf_notification_sent:Notification offer created sent`,
    tender_log_bid_on_behalf_notification_sent: $localize`:@@tender_log_bid_on_behalf_notification_sent:Notification bid created sent`,
    tender_log_invited_to_bidding_round: $localize`:@@tender_log_invited_to_bidding_round:Invited to bidding round:`,
    tender_log_notification_sent_invited_bidding_round: $localize`:@@tender_log_notification_sent_invited_bidding_round:Notification of invitation sent to bidding round`,
    bidder_my_offers: $localize`:@@bidder_my_offers: My offers`,
    bidder_offer_type: $localize`:@@bidder_offer_type: Offer type`,
    bidder_contact_person: $localize`:@@bidder_contact_person: Contact person`,
    bidder_message: $localize`:@@bidder_message: Message`,
    bidder_tender_state_rejected: $localize`:@@bidder_tender_state_rejected: Rejected`,
    bidder_tender_state_accepted: $localize`:@@bidder_tender_state_accepted: Accepted`,
    bidder_tender_state_received: $localize`:@@bidder_tender_state_received: Submitted`,
    bidder_tender_state_draft: $localize`:@@bidder_tender_state_draft: Draft`,
    bidder_tender_state_replaced: $localize`:@@bidder_tender_state_replaced: Replaced`,
    issuer_offers_offer: $localize`:@@issuer_offers_offer:Offer ?index?`,
    issuer_offers_status: $localize`:@@issuer_offers_status:Status`,
    issuer_offers_offer_received: $localize`:@@issuer_offers_offer_received:Received`,
    bidder_attachment_work_description: $localize`:@@bidder_attachment_work_description:Work description`,
    bidder_attachment_general_terms: $localize`:@@bidder_attachment_general_terms:General terms`,
    bidder_attachment_special_terms: $localize`:@@bidder_attachment_special_terms:Special terms`,
    bidder_attachment_plans: $localize`:@@bidder_attachment_plans:Plans`,
    bidder_attachment_attachments: $localize`:@@bidder_attachment_attachments:Attachments`,
    bidder_stepper_changes_removed: $localize`:@@bidder_stepper_changes_removed: All your changes will be removed.`,
    bidder_tender_create_cancel: $localize`:@@bidder_tender_create_cancel:Cancel`,
    issuer_general_and_special_terms: $localize`:@@issuer_general_and_special_terms:General and special terms`,
    issuer_no_files_present: $localize`:@@issuer_no_files_present:No files present`,
    issuer_terms_and_conditions_accepted: $localize`:@@issuer_terms_and_conditions_accepted: Accepted`,
    issuer_terms_and_conditions_not_accepted: $localize`:@@issuer_terms_and_conditions_not_accepted: Not accepted`,
    bidder_tender_create_offer_documents_delete: $localize`:@@bidder_tender_create_offer_documents_delete:Delete`,
    bidder_tender_draft_offer: $localize`:@@bidder_tender_draft_offer:Draft offer`,
    bider_tender_offer_edit_offer: $localize`:@@bider_tender_offer_edit_offer:Edit offer`,
    bidder_tender_deregistration_not_possible: $localize`:@@bidder_tender_deregistration_not_possible:Deregistration is no more possible because the submission deadline is expired`,
    bidder_create_offer_work_description_from_offer: $localize`:@@bidder_create_offer_work_description_from_offer: Work description from your offer`,
    bidder_tender_create_offer_save_failed: $localize`:@@bidder_tender_create_offer_save_failed:Saving your offer failed`,
    bidder_tender_create_offer_draft_offer: $localize`:@@bidder_tender_create_offer_draft_offer:Draft offer`,
    bidder_tender_create_offer_create_new_offer: $localize`:@@bidder_tender_create_offer_create_new_offer:Create new Offer`,
    bidder_tender_create_offer_neto_label: $localize`:@@bidder_tender_create_offer_neto_label:Netto before VAT`,
    bidder_tender_create_offer_dialog_hint: $localize`:@@bidder_tender_create_offer_dialog_hint:There are already existing draft offers`,
    bidder_tender_create_offer_dialog_new_offers_label: $localize`:@@bidder_tender_create_offer_dialog_new_offers_label:Would you like to create a new offer?`,
    bidder_tender_create_offer_dialog_existing_offers_label: $localize`:@@bidder_tender_create_offer_dialog_existing_offers_label:Would you like to edit an existing draft offer?`,
    bidder_create_offer_delete_offer_hint1: $localize`:@@bidder_create_offer_delete_offer_hint1:Do you want to delete your offer no ?name? ?`,
    bidder_create_offer_delete_offer_hint2: $localize`:@@bidder_create_offer_delete_offer_hint2:All your files and entries will be gone for good`,
    bidder_create_offer_delete_offer_delete: $localize`:@@bidder_create_offer_delete_offer_delete:Delete`,
    bidder_create_offer_delete_offer_cancel: $localize`:@@bidder_create_offer_delete_offer_cancel:Cancel`,
    bidder_tender_offer_delete_offer: $localize`:@@bidder_tender_offer_delete_offer:Delete offer`,
    bidder_tender_offer_delete_offer_success: $localize`:@@bidder_tender_offer_delete_offer_success:Your draft offer has been deleted`,
    bidder_tender_offer_delete_offer_error: $localize`:@@bidder_tender_offer_delete_offer_error:Your draft offer delete failed`,
    bau4x_processing_error: $localize`:@@bau4x_processing_error:Error while processing file in BAU4x`,
    comparison_header_title: $localize`:@@comparison_header_title:Company`,
    bidder_tender_detail_section_deregistration: $localize`:@@bidder_tender_detail_section_deregistration:Deregistration`,
    bidder_tender_work_description_processing: $localize`:@@bidder_tender_work_description_processing:The file is being proccessed`,
    bidder_tender_work_description_defective: $localize`:@@bidder_tender_work_description_defective:The work description is defective. Please delete the file.`,
    bidder_tender_create_offer_files: $localize`:@@bidder_tender_create_offer_files:?ammount? files`,
    bidder_tender_create_offer_file: $localize`:@@bidder_tender_create_offer_file:?ammount? file`,
    bidder_tender_create_offer_terms_conditions_title: $localize`:@@bidder_tender_create_offer_terms_conditions_title:Terms and conditions`,
    bidder_tender_create_offer_terms_conditions_accepted: $localize`:@@bidder_tender_create_offer_terms_conditions_accepted:All terms and conditions have been accepted`,
    bidder_tender_create_offer_terms_conditions_label_accepted: $localize`:@@bidder_tender_create_offer_terms_conditions_label_accepted:Issuers terms and conditions have been accepted`,
    bidder_tender_create_offer_terms_conditions_not_accepted: $localize`:@@bidder_tender_create_offer_terms_conditions_not_accepted:Please accept the issuers terms and conditions`,
    bidder_tender_create_offer_terms_conditions_not_uploaded: $localize`:@@bidder_tender_create_offer_terms_conditions_not_uploaded:The issuer did not upload any files for the terms and conditions`,
    create_offer_contacts: $localize`:@@create_offer_contacts:Your contact details`,
    create_offer_contacts_enter_details: $localize`:@@create_offer_contacts_enter_details:Who should be contacted by the issuer? Enter your contact details here.`,
    create_offer_mobile_phone: $localize`:@@create_offer_mobile_phone:Mobile`,
    bidder_tender_offer_label: $localize`:@@bidder_tender_offer_label:Offer`,
    bidder_tender_offer_inspection_date: $localize`:@@bidder_tender_offer_inspection_date:Inspection date`,
    bidder_tender_offer_trans_id: $localize`:@@bidder_tender_offer_trans_id:Trans ID`,
    bidder_tender_offer_inspection_date_not_viewed: $localize`:@@bidder_tender_offer_inspection_date_not_viewed:Not viewed`,
    bidder_tender_offer_number: $localize`:@@bidder_tender_offer_number:No`,
    zip_city_country_invalid: $localize`:@@zip_city_country_invalid:Zip/City/Country combo invalid`,
    pp_core_form_label_tender_description_prefix: $localize`:@@pp_core_form_label_tender_description_prefix:BKP`,
    bidder_tender_create_offer_documents_copy_and_fill: $localize`:@@bidder_tender_create_offer_documents_copy_and_fill:Fill out the file directly online and copy it into your offer. By opening this file, you access standards of the CRB that are subject to licensing and fees. The accruing license fees are settled directly between Olmero and CRB. There are no additional costs for you. (Source of supply & copyright: CRB Zurich)`,
    bidder_tender_create_offer_documents_attachment_copied: $localize`:@@bidder_tender_create_offer_documents_attachment_copied:This file has been copied to your offer already`,
    bidder_tender_download_file: $localize`:@@bidder_tender_download_file:Download`,
    bidder_tender_create_open_popup_and_redirect: $localize`:@@bidder_tender_create_open_popup_and_redirect: In order to be able to open BAU4x, please make sure your browser allows pop-ups and redirects for Olmero`,
    bidder_tender_work_description_fill_out: $localize`:@@bidder_tender_work_description_fill_out:Fill out online`,
    create_offer_edit_contact: $localize`:@@create_offer_edit_contact:Edit contact details`,
    create_offer_edit_contact_save: $localize`:@@create_offer_edit_contact_save:Save`,
    create_offer_edit_contact_error: $localize`:@@create_offer_edit_contact_error:Could not save. Please complete the mandatory information.`,
    create_offer_edit_contact_cancel: $localize`:@@create_offer_edit_contact_cancel:Cancel`,
    bidder_tender_work_description_conform: $localize`:@@bidder_tender_work_description_conform:Conform`,
    bidder_tender_work_description_non_conform: $localize`:@@bidder_tender_work_description_non_conform:Non conform`,
    bidder_tender_work_description_invalid: $localize`:@@bidder_tender_work_description_invalid:Invalid`,
    bidder_tender_work_description_conformity_error: $localize`:@@bidder_tender_work_description_conformity_error:Work description conformity check not available for this offer`,
    bidder_tender_work_description_conformed_tooltip: $localize`:@@bidder_tender_work_description_conformed_tooltip:The work description is conform with the issuers requirements`,
    bidder_tender_work_description_non_conformed_tooltip: $localize`:@@bidder_tender_work_description_non_conformed_tooltip:The work description is not conform with the issuers requirements`,
    create_offer_comments_placeholder: $localize`:@@create_offer_comments_placeholder:Add a comment to your offer`,
    create_offer_comments_error: $localize`:@@create_offer_comments_error:Only ?max? characters are allowed. You entered ?actual? characters`,
    bidder_tender_work_offer_actions_disabled: $localize`:@@bidder_tender_work_offer_actions_disabled:You can no longer edit and delete the offer, because the deadline is expired`,
    bidder_tender_work_description_partially_conform: $localize`:@@bidder_tender_work_description_partially_conform:Partially conform`,
    bidder_tender_work_description_not_verifiable: $localize`:@@bidder_tender_work_description_not_verifiable:Not verifiable`,
    private_bidder_public_directory: $localize`:@@private_bidder_public_directory:Public directory`,
    private_bidder_supplier_directory: $localize`:@@private_bidder_supplier_directory:Supplier directory`,
    private_bidder_register_now: $localize`:@@private_bidder_register_now:Register now`,
    private_bidder_no_access_breadcrumb: $localize`:@@private_bidder_no_access_breadcrumb:Oops!`,
    private_bidder_onboarding_breadcrumb: $localize`:@@private_bidder_onboarding_breadcrumb:Complete Profile`,
    private_bidder_hook_text: $localize`:@@private_bidder_hook_text:To see this tender and gain access to even more tenders on our market place, sign up and request a free
  demonstration <a href="https://www.olmero.ch/en/register/" class="external-link">on our website</a>.`,
    registered_bidder_hook_text: $localize`:@@registered_bidder_hook_text:To view this tender, please log with your other account.`,
    private_bidder_signup_label: $localize`:@@private_bidder_signup_label:Sign up now!`,
    private_bidder_signup_link: $localize`:@@private_bidder_signup_link:https://www.olmero.ch/en/register/`,
    bidder_tender_work_description_validation_error: $localize`:@@bidder_tender_work_description_validation_error:The CRB SIA test detected errors for this file. For more details open the file. To open Olmero BAU4x, please make sure that your browser allows pop-ups and redirects to Olmero.`,
    bidder_tender_work_description_processing_failed: $localize`:@@bidder_tender_work_description_processing_failed:The work description is defective and cannot be opened with the Olmero Bau4x Editor`,
    bidder_tender_create_offer_submit_disabled_tooltip: $localize`:@@bidder_tender_create_offer_submit_disabled_tooltip:You can only submit an offer, if you have uploaded a file with category work description and filled out a price in the cover sheet`,
    issuer_tender_attachments: $localize`:@@issuer_tender_attachments:Tender attachments`,
    issuer_tender_present_at_submision: $localize`:@@issuer_tender_present_at_submision:Present at offer submission time`,
    issuer_tender_not_present_at_submision: $localize`:@@issuer_tender_not_present_at_submision:New version uploaded after offer submission`,
    issuer_tender_trans_id: $localize`:@@issuer_tender_trans_id:Trans-Id`,
    issuer_tender_show_attachments: $localize`:@@issuer_tender_show_attachments:Show all tender attachments`,
    issuer_tender_hide_attachments: $localize`:@@issuer_tender_hide_attachments:Hide all tender attachments`,
    issuer_tender_access_to_documents: $localize`:@@issuer_tender_access_to_documents:Attention! The contractor did not have access to all documents when submitting the offer. Please check the following list.`,
    bidder_tender_create_offer_received_offer: $localize`:@@bidder_tender_create_offer_received_offer:Submitted offer`,
    tender_list_sort_submission_date: $localize`:@@tender_list_sort_submission_date:Submission Date`,
    tender_list_sort_publication_date: $localize`:@@tender_list_sort_publication_date:Publication Date`,
    tender_list_sort_application_date: $localize`:@@tender_list_sort_application_date:Application Date`,
    tender_list_sort_issuer_company_name: $localize`:@@tender_list_sort_issuer_company_name:Issuer Name`,
    tender_list_sort_project_name: $localize`:@@tender_list_sort_project_name:Project Name`,
    bidder_tender_dashboard_search_favorite: $localize`:@@bidder_tender_dashboard_search_favorite:Favorit`,
    bidder_tender_dashboard_search_status: $localize`:@@bidder_tender_dashboard_search_status:Status`,
    bidders_tender_dashboard_tab_offered: $localize`:@@bidders_tender_dashboard_tab_offered:Offered`,
    bidders_tender_dashboard_tab_offer_possibility: $localize`:@@bidders_tender_dashboard_tab_offer_possibility:Offer possibility`,
    bidders_tender_dashboard_tab_applied: $localize`:@@bidders_tender_dashboard_tab_applied:Applied`,
    bidders_tender_dashboard_tab_bidding_round: $localize`:@@bidders_tender_dashboard_tab_bidding_round:Bidding round`,
    bidders_tender_dashboard_tab_app_possibility: $localize`:@@bidders_tender_dashboard_tab_app_possibility:Application possibility`,
    bidders_tender_dashboard_tab_signed_off: $localize`:@@bidders_tender_dashboard_tab_signed_off:Signed off`,
    bidders_tender_dashboard_tab_invited: $localize`:@@bidders_tender_dashboard_tab_invited:Invited`,
    bidders_tender_dashboard_tab_will_submit_offer: $localize`:@@bidders_tender_dashboard_tab_will_submit_offer:Will Submit Offer`,
    bidders_tender_dashboard_tab_application_rejected: $localize`:@@bidders_tender_dashboard_tab_application_rejected:Application Rejected`,
    bidders_tender_dashboard_tab_offer_rejected: $localize`:@@bidders_tender_dashboard_tab_offer_rejected:Offer Rejected`,
    bidder_tender_dashboard_search_all: $localize`:@@bidder_tender_dashboard_search_all:All`,
    bidder_tender_dashboard_current: $localize`:@@bidder_tender_dashboard_current:Current`,
    areas_of_work: $localize`:@@areas_of_work:Areas of Work`,
    canton_ag: $localize`:@@canton_ag:Aargau`,
    canton_ai: $localize`:@@canton_ai:Appenzell Innerrhoden`,
    canton_ar: $localize`:@@canton_ar:Appenzell Ausserrhoden`,
    canton_bl: $localize`:@@canton_bl:Basel-Land`,
    canton_bs: $localize`:@@canton_bs:Basel-Stadt`,
    canton_be: $localize`:@@canton_be:Bern`,
    canton_fr: $localize`:@@canton_fr:Fribourg`,
    canton_ge: $localize`:@@canton_ge:Genève`,
    canton_gl: $localize`:@@canton_gl:Glarus`,
    canton_gr: $localize`:@@canton_gr:Graubünden`,
    canton_ju: $localize`:@@canton_ju:Jura`,
    canton_lu: $localize`:@@canton_lu:Luzern`,
    canton_ne: $localize`:@@canton_ne:Neuchâtel`,
    canton_nw: $localize`:@@canton_nw:Nidwalden`,
    canton_ow: $localize`:@@canton_ow:Obwalden`,
    canton_sg: $localize`:@@canton_sg:St.Gallen`,
    canton_sh: $localize`:@@canton_sh:Schaffhausen`,
    canton_sz: $localize`:@@canton_sz:Schwyz`,
    canton_so: $localize`:@@canton_so:Solothurn`,
    canton_tg: $localize`:@@canton_tg:Thurgau`,
    canton_ti: $localize`:@@canton_ti:Ticino`,
    canton_ur: $localize`:@@canton_ur:Uri`,
    canton_vd: $localize`:@@canton_vd:Vaud`,
    canton_vs: $localize`:@@canton_vs:Valais`,
    canton_zg: $localize`:@@canton_zg:Zug`,
    canton_zh: $localize`:@@canton_zh:Zürich`,
    canton_ch: $localize`:@@canton_ch:Switzerland`,
    canton_fl: $localize`:@@canton_fl:Liechtenstein`,
    tender_dashboard_read_more: $localize`:@@tender_dashboard_read_more:Read more`,
    tender_dashboard_read_less: $localize`:@@tender_dashboard_read_less:Read less`,
    bidder_tender_dashboard_search_type_of_work: $localize`:@@bidder_tender_dashboard_search_type_of_work:Type of work`,
    bidder_tender_search_all_incl_unsubscribed: $localize`:@@bidder_tender_search_all_incl_unsubscribed:All (incl. unsubscribed)`,
    bidder_tender_dashboard_foreign_type_of_work: $localize`:@@bidder_tender_dashboard_foreign_type_of_work:Foreign type of work`,
    issuer_tender_work_description_validation_error: $localize`:@@issuer_tender_work_description_validation_error:The CRB SIA test detected errors for this file. Please correct the errors and upload again.`,
    bidder_tender_create_application_breadcrumb: $localize`:@@bidder_tender_create_application_breadcrumb:Create Application`,
    bidder_tender_create_application_apply: $localize`:@@bidder_tender_create_application_apply:Apply`,
    bidder_tender_create_application_cancel: $localize`:@@bidder_tender_create_application_cancel:Cancel`,
    issuer_detail_comparison_title: $localize`:@@issuer_detail_comparison_title:Detailed comparison`,
    issuer_detail_comparison_sub_title: $localize`:@@issuer_detail_comparison_sub_title:Choose which crbx files should be taken over to MTF`,
    issuer_detail_comparison_continue: $localize`:@@issuer_detail_comparison_continue:Continue`,
    issuer_detail_comparison_cancel: $localize`:@@issuer_detail_comparison_cancel:Cancel`,
    issuer_detail_comparison_no_crbx_files: $localize`:@@issuer_detail_comparison_no_crbx_files:no CRBX files present`,
    issuer_detail_comparison_non_conform_files_present: $localize`:@@issuer_detail_comparison_non_conform_files_present:Files that are not conform to the original file cannot be compared`,
    bidder_tender_deregister_breadcrumb: $localize`:@@bidder_tender_deregister_breadcrumb:Deregister`,
    work_description_proccessing: $localize`:@@work_description_proccessing:Your attached work descriptions are being processed`,
    bidder_tender_dashboard_archive: $localize`:@@bidder_tender_dashboard_archive:Archive`,
    bidder_tender_dashboard_time_period_title: $localize`:@@bidder_tender_dashboard_time_period_title:Time period`,
    bidder_tender_dashboard_time_period_after: $localize`:@@bidder_tender_dashboard_time_period_after:Offer submission date is now or in the future`,
    bidder_tender_dashboard_time_period_before: $localize`:@@bidder_tender_dashboard_time_period_before:The date of the tenders max. 120 days in the past or have the status “offered”`,
    bidder_tender_create_offer_validating: $localize`:@@bidder_tender_create_offer_validating:Validating offer data`,
    bidder_tender_create_offer_processing: $localize`:@@bidder_tender_create_offer_processing:Processing work description`,
    bidder_tender_create_offer_saving: $localize`:@@bidder_tender_create_offer_saving:Saving Data`,
    feature_flag_error: $localize`:@@feature_flag_error:Some Olmero platform features are blocked by a browser extension. Please try to turn off any script- or ad-blockers or use another browser. If this message keeps showing up, contact us to get help.`,
    tender_mobile_number: $localize`:@@tender_mobile_number:Mobile number`,
    dashboard_filter_reset: $localize`:@@dashboard_filter_reset:Reset all`,
    bidder_tender_offer_print_offer: $localize`:@@bidder_tender_offer_print_offer:Print offer`,
    bidder_tender_offer_download_offer: $localize`:@@bidder_tender_offer_download_offer:Download offer`,
    bidder_tender_create_offer_submit_success: $localize`:@@bidder_tender_create_offer_submit_success:Your offer was submitted`,
    tender_choose_category: $localize`:@@tender_choose_category:Edit category`,
    invite_contractor_add_company: $localize`:@@invite_contractor_add_company:Company will be created in the private directory`,
    issuer_invite_bidder: $localize`:@@issuer_invite_bidder:Select`,
    issuer_uninvite_bidder: $localize`:@@issuer_uninvite_bidder:Deselect`,
    show_reference_projects: $localize`:@@show_reference_projects:Reference projects (?quantity?)`,
    dialog_title_reference_projects: $localize`:@@dialog_title_reference_projects:Project References (?quantity?)`,
    link_to_profile: $localize`:@@link_to_profile:See company profile`,
    bidder_tender_tender_attachment_details_conform_text: $localize`:@@bidder_tender_tender_attachment_details_conform_text:The structure of the CRBx file has not been changed`,
    bidder_tender_tender_attachment_details_non_conform_text: $localize`:@@bidder_tender_tender_attachment_details_non_conform_text:The structure of the CRBx file has been changed`,
    bidder_tender_tender_attachment_details_view_details: $localize`:@@bidder_tender_tender_attachment_details_view_details:view details`,
    bidder_tender_offer_delete_submited_offer_success: $localize`:@@bidder_tender_offer_delete_submited_offer_success:Your submited offer has been deleted`,
    bidder_tender_create_offer_documents_copy_and_fill_short_version: $localize`:@@bidder_tender_create_offer_documents_copy_and_fill_short_version:The NPC-Editor opens automatically if you make sure that your browser allows pop-ups and redirects on Olmero`,
    admin_statistics_page_title: $localize`:@@admin_statistics_page_title:Statistics`,
    admin_statistics_page_label: $localize`:@@admin_statistics_page_label:Export all tender and offer information for a specified time period`,
    admin_statistics_page_button: $localize`:@@admin_statistics_page_button:Export tender data`,
    datepicker_max_date_exceeded: $localize`:@@datepicker_max_date_exceeded:Date must be before or equal to ?max? but is ?actual?`,
    datepicker_from: $localize`:@@datepicker_from:From`,
    datepicker_to: $localize`:@@datepicker_to:To`,
    date_to_before_from_date: $localize`:@@date_to_before_from_date:'To'-Date must be after or equal to 'from'-Date.`,
    issuer_directory_company_profile: $localize`:@@issuer_directory_company_profile:Company profile`,
    bidder_invite_select_all: $localize`:@@bidder_invite_select_all:Select all`,
    issuer_invited: $localize`:@@issuer_invited:Invited`,
    issuer_issuer_no_work_description_message: $localize`:@@issuer_issuer_no_work_description_message:It is important to have a work description so that bidders can efficiently enter quotes.
    Are you sure you want to save the tender without work description?`,
    description_read_more: $localize`:@@description_read_more: Read more`,
    pp_core_button_details_add_offer: $localize`:@@pp_core_button_details_add_offer:Add Offer`,
    bidder_tender_details_contractor: $localize`:@@bidder_tender_details_contractor:Contractor`,
    bidder_tender_details_state_application_expired: $localize`:@@bidder_tender_details_state_application_expired:Application expired`,
    pp_core_add_offer_contact_data: $localize`:@@pp_core_add_offer_contact_data:Contact`,
    pp_core_add_offer_contact_data_title: $localize`:@@pp_core_add_offer_contact_data_title:Contact details`,
    pp_core_add_offer_contact_data_subtitle: $localize`:@@pp_core_add_offer_contact_data_subtitle:Who should be displayed as contact person for this offer`,
    issuer_tender_no_attachments_at_time_of_offer_because_external_offers: $localize`:@@issuer_tender_no_attachments_at_time_of_offer_because_external_offers:For offers manually entered on behalf of the contractor it can not be identified`,
    bidder_tender_entered_by_issuer: $localize`:@@bidder_tender_entered_by_issuer:Offer entered by issuer`,
    bidder_tender_entered_by_issuer_tooltip: $localize`:@@bidder_tender_entered_by_issuer_tooltip:The issuer entered this time the offer on your behalf. Please enter your next offers directly on the Olmero platform. For details about how to do it please check out our tutorials in the help area.`,
    yes: $localize`:@@yes:Yes`,
    no: $localize`:@@no:No`,
    max_150_characters: $localize`:@@max_150_characters:Max. 150 characters`,
    add_offer_choose_date: $localize`:@@add_offer_choose_date:Choose a date`,
    add_offer_received_via: $localize`:@@add_offer_received_via:Entered by ?name? on the Olmero tender platform`,
    date_time_picker_max_exceeded: $localize`:@@date_time_picker_max_exceeded:Date must be before or equal to ?max? but is ?actual?`,
    add_offer_cover_page: $localize`:@@add_offer_cover_page:Cover page`,
    add_offer_comment_placeholder: $localize`:@@add_offer_comment_placeholder:Here you can enter the message from the Bidder`,
    client_bidder_list_bidder_suggested: $localize`:@@client_bidder_list_bidder_suggested:Our Suggestion`,
    client_choose_olmero_suggestions_filter_title: $localize`:@@client_choose_olmero_suggestions_filter_title:Olmero Suggestions`,
    client_choose_olmero_suggestions_filter_description: $localize`:@@client_choose_olmero_suggestions_filter_description:Display suggested companies`,
    pp_core_project_quotation_label: $localize`:@@pp_core_project_quotation_label:Project quotation`,
    pp_core_project_quotation_placeholder: $localize`:@@pp_core_project_quotation_placeholder:Project quotation (BKP 2)`,
    add_offer_received_via_label: $localize`:@@add_offer_received_via_label:Entered by`,
    show_companies_contractor: $localize`:@@show_companies_contractor:Contractor`,
    show_companies_status: $localize`:@@show_companies_status:Status`,
    show_companies_submit_of_by: $localize`:@@show_companies_submit_of_by:Submit offer by`,
    show_companies_initial_contact: $localize`:@@show_companies_initial_contact:Initial contact`,
    show_companies_gross_excl_vat: $localize`:@@show_companies_gross_excl_vat:Gross excl. VAT`,
    show_companies_net_before_vat: $localize`:@@show_companies_net_before_vat:Net before VAT`,
    show_companies_company_profile: $localize`:@@show_companies_company_profile:Company profile`,
    show_companies_private_directory: $localize`:@@show_companies_private_directory:Private directory`,
    show_companies_no_inspection: $localize`:@@show_companies_no_inspection:No inspection`,
    show_companies_notification_failed: $localize`:@@show_companies_notification_failed:Notification failed`,
    pp_core_form_label_state_received: $localize`:@@pp_core_form_label_state_received:received`,
    show_companies_invited: $localize`:@@show_companies_invited:Was invited`,
    show_companies_accepted: $localize`:@@show_companies_accepted:Accepted`,
    show_companies_offer_rejected_date: $localize`:@@show_companies_offer_rejected_date:Offer rejected`,
    compare_company_state_unsubscribe: $localize`:@@compare_company_state_unsubscribe:Unsubscribed`,
    show_companies_contractor_terminated: $localize`:@@show_companies_contractor_terminated:The Membership of this contractor in the Olmero Network has been terminated.`,
    pp_core_form_label_state_unsubscribe: $localize`:@@pp_core_form_label_state_unsubscribe:Unsubscribed`,
    pp_core_tender_details_received_externally: $localize`:@@pp_core_tender_details_received_externally:externally`,
    pp_core_tender_please_check_your_filter_settings: $localize`:@@pp_core_tender_please_check_your_filter_settings:Please check your filter settings`,
    min_file_size: $localize`:@@min_file_size:Can't upload empty file`,
    not_interested: $localize`:@@not_interested:Not interested`,
    bidder_tender_list_state_not_interested: $localize`:@@bidder_tender_list_state_not_interested:Not interested`,
    not_interested_success: $localize`:@@not_interested_success:Success`,
    show_companies_offer_rejected_on: $localize`:@@show_companies_offer_rejected_on: on ?date?`,
    compare_company_department: $localize`:@@compare_company_department:Company, Department`,
    compare_company_state_applied: $localize`:@@compare_company_state_applied:Applied`,
    compare_company_state_invited: $localize`:@@compare_company_state_invited:Open`,
    compare_company_state_offered: $localize`:@@compare_company_state_offered:Offered`,
    compare_company_state_rejected: $localize`:@@compare_company_state_rejected:Rejected`,
    compare_company_state_offer_rejected: $localize`:@@compare_company_state_offer_rejected:Rejected`,
    compare_company_state_signed_off: $localize`:@@compare_company_state_signed_off:Signed Off`,
    compare_company_state_will_offer: $localize`:@@compare_company_state_will_offer:Will Submit Offer`,
    compare_company_inspection_by: $localize`:@@compare_company_inspection_by:Inspected`,
    compare_company_no_inspection: $localize`:@@compare_company_no_inspection:No inspection`,
    compare_company_state_application_rejected: $localize`:@@compare_company_state_application_rejected:Application Rejected`,
    contractors_rows_menu_section_title: $localize`:@@contractors_rows_menu_section_title: Contractors`,
    pp_core_tender_detail_tender_number: $localize`:@@pp_core_tender_detail_tender_number:Tender no`,
    pp_core_quotation_tender_budget: $localize`:@@pp_core_quotation_tender_budget:Quotation`,
    pp_core_quotation_sum_tender_without_offer: $localize`:@@pp_core_quotation_sum_tender_without_offer:Sum tender budgets without offer`,
    pp_core_quotation_lowest_offer: $localize`:@@pp_core_quotation_lowest_offer:Lowest Offer`,
    pp_core_quotation_sum_of_lowest_offers: $localize`:@@pp_core_quotation_sum_of_lowest_offers:Sum of all lowest offers`,
    pp_core_quotation_additional_costs: $localize`:@@pp_core_quotation_additional_costs:Additional costs`,
    pp_core_quotation_savings: $localize`:@@pp_core_quotation_savings:Savings`,
    pp_core_quotation_in_percent: $localize`:@@pp_core_quotation_in_percent:in %`,
    project_core_number_of_tenders_with_quotation_and_offers: $localize`:@@project_core_number_of_tenders_with_quotation_and_offers:Number of tenders with quotation and offers`,
    add_offer_submit_disabled_tooltip: $localize`:@@add_offer_submit_disabled_tooltip:You can only submit an offer, if you have uploaded a file with category work description`,
    add_offer_submit_success: $localize`:@@add_offer_submit_success:The offer has been saved. The contractor has been informed about it and can view the offer`,
    add_offer_edit_submit_success: $localize`:@@add_offer_edit_submit_success:The changes have been saved.`,
    add_offer_terms_and_conditions_not_known: $localize`:@@add_offer_terms_and_conditions_not_known:not known`,
    pp_core_project_detail_company_placeholder: $localize`:@@pp_core_project_detail_company_placeholder: Company`,
    pp_core_created_on_label: $localize`:@@pp_core_created_on_label:Created on`,
    pp_core_feedback_label: $localize`:@@pp_core_feedback_label:Feedback`,
    pp_core_will_offer_label: $localize`:@@pp_core_will_offer_label:will offer`,
    no_offer_for_file: $localize`:@@no_offer_for_file:No Offer for this file`,
    tender_details_publish_scheduled_on: $localize`:@@tender_details_publish_scheduled_on:Publication scheduled on`,
    subtabs_compare_offers: $localize`:@@subtabs_compare_offers:Offer Comparison`,
    subtabs_bidding_round: $localize`:@@subtabs_bidding_round:Bidding round`,
    subtabs_approval: $localize`:@@subtabs_approval:Approval`,
    approval_state_not_created: $localize`:@@approval_state_not_created:Not Created`,
    approval_page_not_created: $localize`:@@approval_page_not_created:You have not finished the approval. Lorem ipsum ...`,
    approval_select_offers: $localize`:@@approval_select_offers:Select Offers`,
    approval_recommendations: $localize`:@@approval_recommendations:Recommendations`,
    approval_recommendation: $localize`:@@approval_recommendation:Recommendation`,
    approval_details_involved_people: $localize`:@@approval_details_involved_people:Details & Involved People`,
    approval_select_offer_subtitle: $localize`:@@approval_select_offer_subtitle:Select the offers that you would like to recommenend for approval.`,
    approval_select_offer_empty: $localize`:@@approval_select_offer_empty:Please select at least one offer`,
    approval_recommendations_remarks: $localize`:@@approval_recommendations_remarks:Recommendations & Remarks`,
    approval_remarks: $localize`:@@approval_remarks:Approval Remarks`,
    approval_recommended_offers: $localize`:@@approval_recommended_offers:Recommended Offers`,
    approval_recommendations_subtitle: $localize`:@@approval_recommendations_subtitle:Select the recommended offer and add remarks.`,
    approval_contractor_initial_contact_invited: $localize`:@@approval_contractor_initial_contact_invited:Was Invited`,
    approval_contractor_initial_contact_accepted: $localize`:@@approval_contractor_initial_contact_accepted:Application was Accepted`,
    bidding_state_completed: $localize`:@@bidding_state_completed:Completed`,
    bidding_state_started: $localize`:@@bidding_state_started:Started`,
    bidding_state_not_started: $localize`:@@bidding_state_not_started:Not started`,
    subtabs_bidding_round_note: $localize`:@@subtabs_bidding_round_note:You have not started a bidding round yet.`,
    subtabs_bidding_round_subtitle: $localize`:@@subtabs_bidding_round_subtitle:To do this, use the Start bidding round function to receive a customized offer.`,
    issuer_bidding_round_created: $localize`:@@issuer_bidding_round_created:The bidding round has been started and all participating companies have received a notification.`,
    issuer_bidding_round_select_offers: $localize`:@@issuer_bidding_round_select_offers:Select offers`,
    issuer_bidding_round_type_n_date: $localize`:@@issuer_bidding_round_type_n_date:Select bidding type & date`,
    issuer_bidding_round_start_new: $localize`:@@issuer_bidding_round_start_new:Start new bidding round`,
    issuer_bidding_round_edit: $localize`:@@issuer_bidding_round_edit:Edit bidding round`,
    issuer_bidding_round_type_our_suggestion: $localize`:@@issuer_bidding_round_type_our_suggestion:Our Suggestion`,
    issuer_bidding_round_bidding_type_title: $localize`:@@issuer_bidding_round_bidding_type_title:Bidding type`,
    issuer_bidding_round_bidding_type_desc: $localize`:@@issuer_bidding_round_bidding_type_desc:Choose the bidding process that best suits your tender.`,
    issuer_bidding_round_bidding_full_price_title: $localize`:@@issuer_bidding_round_bidding_full_price_title:Full price ranking`,
    issuer_bidding_round_bidding_full_price_desc: $localize`:@@issuer_bidding_round_bidding_full_price_desc:An anonymized ranking list is sent to all contractors. They can see at which prices the competitors
              have bid and can adjust their offer accordingly.`,
    issuer_bidding_round_bidding_best_price_title: $localize`:@@issuer_bidding_round_bidding_best_price_title:Full price ranking`,
    issuer_bidding_round_bidding_best_price_desc: $localize`:@@issuer_bidding_round_bidding_best_price_desc:Contractors see the price of the lowest offer and can adjust their offer accordingly.`,
    issuer_bidding_round_bidding_no_info_title: $localize`:@@issuer_bidding_round_bidding_no_info_title:No price info`,
    issuer_bidding_round_bidding_no_info_desc: $localize`:@@issuer_bidding_round_bidding_no_info_desc:No information about already received offers is given to the contractor. They can adjust their offer
              based on your input.`,
    issuer_bidding_round_submit_by: $localize`:@@issuer_bidding_round_submit_by:Bidding by`,
    issuer_bidding_round_submission_title: $localize`:@@issuer_bidding_round_submission_title:Bidding submission date`,
    issuer_bidding_round_submission_desc: $localize`:@@issuer_bidding_round_submission_desc:Choose deadline for the revised offers of the contractors.`,
    issuer_bidding_round_preview: $localize`:@@issuer_bidding_round_preview:Preview`,
    issuer_bidding_round_type_must_be_set: $localize`:@@issuer_bidding_round_type_must_be_set:Bidding type must be set`,
    issuer_bidding_round_type_date_incomplete_snackbar: $localize`:@@issuer_bidding_round_type_date_incomplete_snackbar:No deadline entered for the bidding round. Please complete the mandatory information, before processing.`,
    issuer_bidding_round_no_offers_snackbar: $localize`:@@issuer_bidding_round_no_offers_snackbar:No offer selected for the bidding round. Please complete the mandatory information, before processing.`,
    issuer_bidding_round_offer_must_be_selected: $localize`:@@issuer_bidding_round_offer_must_be_selected:At least one offer must be selected`,
    issuer_bidding_round_submission_date_validation_message: $localize`:@@issuer_bidding_round_submission_date_validation_message:The date must be in the future after the submission deadline for the offer.`,
    issuer_bidding_round_round_no: $localize`:@@issuer_bidding_round_round_no:Round ?roundNo?`,
    issuer_bidding_round_detail_section_title: $localize`:@@issuer_bidding_round_detail_section_title:Bidding Rounds`,
    pp_core_project_detail_title_project: $localize`:@@pp_core_project_detail_title_project:Project details`,
    pp_core_project_detail_tender_with_applications: $localize`:@@pp_core_project_detail_tender_with_applications:Only tenders with applications`,
    pp_core_project_detail_tender_groups: $localize`:@@pp_core_project_detail_tender_groups:Tender groups`,
    pp_core_project_detail_draft_tenders: $localize`:@@pp_core_project_detail_draft_tenders:Draft Tenders`,
    pp_core_project_detail_approval_tenders: $localize`:@@pp_core_project_detail_approval_tenders:In Approval`,
    pp_core_project_detail_bidding_tenders: $localize`:@@pp_core_project_detail_bidding_tenders:Bidding round`,
    pp_core_project_detail_ongoing_tenders: $localize`:@@pp_core_project_detail_ongoing_tenders:Ongoing Tenders`,
    pp_core_project_detail_expired_tenders: $localize`:@@pp_core_project_detail_expired_tenders:Expired Tenders`,
    pp_core_project_detail_closed_tenders: $localize`:@@pp_core_project_detail_closed_tenders:Closed Tenders`,
    pp_core_project_detail_no_tenders: $localize`:@@pp_core_project_detail_no_tenders:No tenders in this project`,
    date_time_picker_below_min: $localize`:@@date_time_picker_below_min:Date must be after or equal to ?max? but is ?actual?`,
    pp_core_button_details_export_title: $localize`:@@pp_core_button_details_export_title:Export`,
    pp_core_button_details_tender_title: $localize`:@@pp_core_button_details_tender_title:Tender`,
    pp_core_button_details_contractors_title: $localize`:@@pp_core_button_details_contractors_title:Contractors`,
    no_results_found: $localize`:@@no_results_found:No results found`,
    bidding_by: $localize`:@@bidding_by:Bidding?numberOfBiddingRounds?by`,
    bidding: $localize`:@@bidding:Bidding`,
    bidder_tender_bidding_round_label: $localize`:@@bidder_tender_bidding_round_label:Bidding Round`,
    bidding_round_preview_title: $localize`:@@bidding_round_preview_title:Bidding round`,
    bidding_round_preview_type_label: $localize`:@@bidding_round_preview_type_label:Procedure`,
    bidding_round_preview_bidding_by_label: $localize`:@@bidding_round_preview_bidding_by_label:Bidding by`,
    bidding_round_preview_type_full_price: $localize`:@@bidding_round_preview_type_full_price:Full price`,
    bidding_round_preview_type_best_price: $localize`:@@bidding_round_preview_type_best_price:Best price`,
    bidding_round_preview_type_no_price: $localize`:@@bidding_round_preview_type_no_price:No price`,
    bidding_round_preview_type_full_price_hint: $localize`:@@bidding_round_preview_type_full_price_hint:All entrepreneurs receive an anonymized ranking of all offers.`,
    bidding_round_preview_type_best_price_hint: $localize`:@@bidding_round_preview_type_best_price_hint:All companies receive information on the best offer.`,
    bidding_round_preview_type_no_price_hint: $localize`:@@bidding_round_preview_type_no_price_hint:No contractor receives information about other offers.`,
    bidding_round_preview_type_full_price_additional_info: $localize`:@@bidding_round_preview_type_full_price_additional_info:The table below lists all contractors who were also invited to the bidding round`,
    bidding_round_preview_competitors_title: $localize`:@@bidding_round_preview_competitors_title:Information about the competitors`,
    bidding_round_preview_bid_adjusted_date_text: $localize`:@@bidding_round_preview_bid_adjusted_date_text:You have adjusted your bid on ?date?`,
    preview_values_table_contractors_name_prefix: $localize`:@@preview_values_table_contractors_name_prefix:Contractor`,
    preview_values_table_contractors_company_name: $localize`:@@preview_values_table_contractors_company_name:Company name`,
    preview_values_best_price_label: $localize`:@@preview_values_best_price_label:Best offer`,
    preview_values_table_header_contractor: $localize`:@@preview_values_table_header_contractor:Contractor`,
    preview_values_table_header_gross_excl_vat: $localize`:@@preview_values_table_header_gross_excl_vat:Gross excl. VAT`,
    preview_values_table_header_net_incl_vat: $localize`:@@preview_values_table_header_net_incl_vat:Netto incl. MwSt`,
    pp_core_quotation_project_budget: $localize`:@@pp_core_quotation_project_budget:Project budget`,
    pp_core_quotation_result: $localize`:@@pp_core_quotation_result:Result`,
    bidder_sidenav_info_bidding_by_label: $localize`:@@bidder_sidenav_info_bidding_by_label:Bidding by`,
    issuer_bidding_round_first_step_info: $localize`:@@issuer_bidding_round_first_step_info:Select the offers that you would like to take into the negotiation. All the
    selected companies will receive an email with the request to adapt their offers. The exact process can be chosen in the next step.`,
    issuer_bidding_round_first_step_contractors_warning: $localize`:@@issuer_bidding_round_first_step_contractors_warning:Please select only one offer per contractor.`,
    offer_card_select_offer: $localize`:@@offer_card_select_offer:Select offer`,
    contractor_info_table_bidding_started_tooltip: $localize`:@@contractor_info_table_bidding_started_tooltip:Bidding started`,
    tender_list_bid_submitted: $localize`:@@tender_list_bid_submitted:Bid submitted`,
    bidder_tender_create_bid: $localize`:@@bidder_tender_create_bid:Create bid`,
    bidder_tender_submit_bid: $localize`:@@bidder_tender_submit_bid:Submit bid`,
    bidder_tender_edit_bid: $localize`:@@bidder_tender_edit_bid:Edit bid`,
    bidder_tender_add_bid: $localize`:@@bidder_tender_add_bid:Add bid`,
    create_bid_comments_placeholder: $localize`:@@create_bid_comments_placeholder:Add a comment to your bid`,
    bidder_tender_create_bid_submit_disabled_tooltip: $localize`:@@bidder_tender_create_bid_submit_disabled_tooltip:You can only submit an bid, if you have uploaded a file with category work description and filled out a price in the cover sheet`,
    bidder_tender_create_bid_save_failed: $localize`:@@bidder_tender_create_bid_save_failed:Saving your bid failed`,
    bidder_tender_create_bid_submit_success: $localize`:@@bidder_tender_create_bid_submit_success:Your bid was submitted`,
    bidder_tender_bid_label: $localize`:@@bidder_tender_bid_label:Bid ID`,
    offer_history_action_button: $localize`:@@offer_history_action_button:Offer history`,
    offer_history_initial_offer: $localize`:@@offer_history_initial_offer:Initial offer`,
    open_in_editor: $localize`:@@open_in_editor:Open in Editor`,
    offer_history_bid_number: $localize`:@@offer_history_bid_number:Bid ?number?`,
    cover_sheet_data_bid_indicator: $localize`:@@cover_sheet_data_bid_indicator:Bid`,
    cover_sheet_data_original_offer_indicator: $localize`:@@cover_sheet_data_original_offer_indicator:Original offer`,
    offer_list_in_bidding_list_icon_label: $localize`:@@offer_list_in_bidding_list_icon_label:in bidding`,
    offer_list_bid_received_list_icon_label: $localize`:@@offer_list_bid_received_list_icon_label:bid received`,
    offer_list_in_approval_list_icon_label: $localize`:@@offer_list_in_approval_list_icon_label:in approval`,
    tender_create_submission_date_change_forbidden_message: $localize`:@@tender_create_submission_date_change_forbidden_message:The offer submission date must be before the bidding date ?date? ?time?`,
    offer_list_action_menu_offer_title: $localize`:@@offer_list_action_menu_offer_title:Offer`,
    offer_list_action_menu_edit_offer: $localize`:@@offer_list_action_menu_edit_offer:Edit offer`,
    offer_list_action_menu_show_previous_offer: $localize`:@@offer_list_action_menu_show_previous_offer:Show Previous Offers`,
    offer_list_action_menu_hide_previous_offer: $localize`:@@offer_list_action_menu_hide_previous_offer:Hide Previous Offers`,
    offer_list_action_menu_delete_offer: $localize`:@@offer_list_action_menu_delete_offer:Delete offer`,
    offer_list_action_menu_print_cover_page: $localize`:@@offer_list_action_menu_print_cover_page:Print cover page`,
    offer_list_action_menu_export_cover_page: $localize`:@@offer_list_action_menu_export_cover_page:Export cover page`,
    home_page_projects_menu: $localize`:@@home_page_projects_menu:Projects`,
    home_page_tenders_menu: $localize`:@@home_page_tenders_menu:Tenders`,
    home_page_tender_menu: $localize`:@@home_page_tender_menu:Tender`,
    home_page_clients_menu: $localize`:@@home_page_clients_menu:Clients`,
    home_page_project_handling_menu: $localize`:@@home_page_project_handling_menu:Project Handling`,
    home_page_tools_menu: $localize`:@@home_page_tools_menu:Tools`,
    home_page_private_directory_link: $localize`:@@home_page_private_directory_link:Private Directory`,
    home_page_reference_platform_link: $localize`:@@home_page_reference_platform_link:Reference Platform`,
    home_page_breadcrumb: $localize`:@@home_page_breadcrumb:Home`,
    home_page_title: $localize`:@@home_page_title:Welcome to Olmero`,
    home_hotline_title: $localize`:@@home_hotline_title:We are happy to help you:`,
    home_hotline_number: $localize`:@@home_hotline_number:+41 848 656 376`,
    home_hotline_help: $localize`:@@home_hotline_help:Help`,
    home_hotline_tutorials: $localize`:@@home_hotline_tutorials:Tutorials`,
    client_list_table_location: $localize`:@@client_list_table_location:Location`,
    client_list_table_login_as: $localize`:@@client_list_table_login_as:Login as`,
    navigation_search_customers: $localize`:@@navigation_search_customers:Search Customers`,
    navigation_search_projects: $localize`:@@navigation_search_projects:Search Projects`,
    navigation_extended_administration: $localize`:@@navigation_extended_administration:Extended Administration`,
    navigation_development: $localize`:@@navigation_development:Development`,
    navigation_user_profile: $localize`:@@navigation_user_profile:User Profile`,
    navigation_company_profile: $localize`:@@navigation_company_profile:Company Profile`,
    client_list_table_select_user: $localize`:@@client_list_table_select_user:Select user`,
    privacy_policy_text: $localize`:@@privacy_policy_text:In order that the issuer can also assign your offer and the possibility to contact you, we need your address. It is not a registration with Olmero.`,
    privacy_policy_note: $localize`:@@privacy_policy_note:Note: By logging in to the Olmero platform, you agree that Olmero may use your details to contact you regarding relevant content and products. You can opt out of any communication at any time. For more information, please see our `,
    privacy_policy_link: $localize`:@@privacy_policy_link:Privacy Policy`,
    profile_form_user_details_title: $localize`:@@profile_form_user_details_title:User Details`,
    profile_form_company_details_title: $localize`:@@profile_form_company_details_title:Company Details`,
    profile_form_complete_profile: $localize`:@@profile_form_complete_profile:Complete Profile`,
    save_profile_progress_user_data: $localize`:@@save_profile_progress_user_data:Saving user profile`,
    save_profile_progress_company_data: $localize`:@@save_profile_progress_company_data:Saving company profile`,
    client_page_warning_select_user: $localize`:@@client_page_warning_select_user:Please, select a user to impersonate`,
    home_page_for_issuers: $localize`:@@home_page_for_issuers:For Issuers`,
    home_page_for_contractors: $localize`:@@home_page_for_contractors:For Contractors`,
    home_page_for_suppliers: $localize`:@@home_page_for_suppliers:For Suppliers`,
    home_page_for_administrators: $localize`:@@home_page_for_administrators:For Administrators`,
    project_references_selector_select_all: $localize`:@@project_references_selector_select_all:Select All (?projectCount?)`,
    project_references_selector_no_projects_label: $localize`:@@project_references_selector_no_projects_label:Increase your application chances by 11% by adding Project References`,
    project_references_cta_name_1: $localize`:@@project_references_cta_name_1:Coop Grüzenmarkt Winterthur`,
    project_references_cta_summary_1: $localize`:@@project_references_cta_summary_1:Modernization during ongoing operations`,
    project_references_cta_categories_1: $localize`:@@project_references_cta_categories_1:Trade and administration, supermarkets`,
    project_references_cta_city_1: $localize`:@@project_references_cta_city_1:Winterthur`,
    project_references_cta_name_2: $localize`:@@project_references_cta_name_2:Fassadenrenovation MFH – Stadt Zürich`,
    project_references_cta_summary_2: $localize`:@@project_references_cta_summary_2:Facade renovation MFH - City of Zurich`,
    project_references_cta_categories_2: $localize`:@@project_references_cta_categories_2:Multi-family houses, residential (from 3 residential units)`,
    project_references_cta_city_2: $localize`:@@project_references_cta_city_2:Zurich`,
    project_references_cta_add_project_link: $localize`:@@project_references_cta_add_project_link:https://referenzen.olmero.ch/de/projekt-hinzufuegen/`,
    project_reference_card_item_example_label: $localize`:@@project_reference_card_item_example_label:Example`,
    project_references_selector_select_project_references_label: $localize`:@@project_references_selector_select_project_references_label:Select references for the offer:`,
    project_references_selector_create_offer_label: $localize`:@@project_references_selector_create_offer_label:Increase your offer chances by 11% by adding Project References.`,
    user_profile_usr_id: $localize`:@@user_profile_usr_id:User Id`,
    project_references_selector_select_project_references_offer_label: $localize`:@@project_references_selector_select_project_references_offer_label:Select references for the offer:`,
    project_references_selector_select_project_references_application_label: $localize`:@@project_references_selector_select_project_references_application_label:Select references for the application:`,
    project_references_selector_create_application_label: $localize`:@@project_references_selector_create_application_label:Increase your application chances by 11% by adding Project References`,
    my_application_references_project_title: $localize`:@@my_application_references_project_title:Reference Projects (?quantity?)`,
    my_application_references_project_title_without_quantity: $localize`:@@my_application_references_project_title_without_quantity:Reference Projects`,
    my_application_no_references_project_title: $localize`:@@my_application_no_references_project_title:No reference projects added.`,
    my_application_state_application_expired: $localize`:@@my_application_state_application_expired:Application expired`,
    bidder_details_reference_projects_title: $localize`:@@bidder_details_reference_projects_title:Reference Projects (?quantity?)`,
    tender_details_reminder_time_unit: $localize`:@@tender_details_reminder_time_unit:?quantity? hours`,
    invite_bidder_table_seal_of_approval_tooltip: $localize`:@@invite_bidder_table_seal_of_approval_tooltip:Seal of Approval`,
    invite_bidder_table_invited_contractor: $localize`:@@invite_bidder_table_invited_contractor:Invited`,
    invite_bidder_table_invited_contractor_tooltip: $localize`:@@invite_bidder_table_invited_contractor_tooltip:Contractor already invited`,
    invite_bidder_table_blacklisted_contractor: $localize`:@@invite_bidder_table_blacklisted_contractor:Blocked`,
    invite_bidder_table_new_contractor: $localize`:@@invite_bidder_table_new_contractor:New`,
    invite_bidder_table_new_contractor_tooltip: $localize`:@@invite_bidder_table_new_contractor_tooltip:Company will be created in the private directory`,
    contact_card_phone_number_label: $localize`:@@contact_card_phone_number_label:Phone:`,
    contact_card_fax_number_label: $localize`:@@contact_card_fax_number_label:Fax:`,
    contact_card_mobile_number_label: $localize`:@@contact_card_mobile_number_label:Mobile:`,
    contact_card_email_label: $localize`:@@contact_card_email_label:Email:`,
    form_error_greater_than_zero: $localize`:@@form_error_greater_than_zero:Value should be greater than zero`,
    ui_file_upload_upload_successful: $localize`:@@ui_file_upload_upload_successful:Successfully uploaded`,
    ui_file_upload_remove_item: $localize`:@@ui_file_upload_remove_item:Remove`,
    ui_file_upload_drop_area_subtitle_plural: $localize`:@@ui_file_upload_drop_area_subtitle_plural:Drag and drop files onto this field or ...`,
    ui_file_upload_drop_area_subtitle_singular: $localize`:@@ui_file_upload_drop_area_subtitle_singular:Drag and drop a file onto this field or ...`,
    ui_file_upload_browse_button: $localize`:@@ui_file_upload_browse_button:Select files`,
    ui_file_upload_error: $localize`:@@ui_file_upload_error:Oops, there was a problem with your upload:`,
    ui_file_upload_filetype_not_allowed: $localize`:@@ui_file_upload_filetype_not_allowed:The file type ?extension? is not allowed.`,
    ui_file_upload_filesize_exceeded: $localize`:@@ui_file_upload_filesize_exceeded:This file exceeded the maximum size of ?size? MB.`,
    pp_core_list_label_last_login: $localize`:@@pp_core_list_label_last_login:Last login`,
    tender_choose_category_disabled: $localize`:@@tender_choose_category_disabled:For CRBX or 01s files the category must be work description`,
    bidder_tender_actions_tooltip_not_interested: $localize`:@@bidder_tender_actions_tooltip_not_interested: Not interested`,
    bidder_tender_actions_tooltip_sign_off: $localize`:@@bidder_tender_actions_tooltip_sign_off: Sign Off`,
    bidder_tender_actions_tooltip_cancel_application: $localize`:@@bidder_tender_actions_tooltip_cancel_application: Cancel Application`,
    bidder_tender_actions_tooltip_create_application: $localize`:@@bidder_tender_actions_tooltip_create_application: Create Application`,
    bidder_tender_actions_tooltip_create_offer: $localize`:@@bidder_tender_actions_tooltip_create_offer: Create Offer`,
    bidder_tender_actions_tooltip_create_bid: $localize`:@@bidder_tender_actions_tooltip_create_bid: Create Bid`,
    bidder_tender_actions_tooltip_edit_bid: $localize`:@@bidder_tender_actions_tooltip_edit_bid: Edit Bid`,
    bidder_tender_actions_tooltip_will_submit_offer: $localize`:@@bidder_tender_actions_tooltip_will_submit_offer: Will submit offer`,
    tender_list_new_tender_label: $localize`:@@tender_list_new_tender_label:New`,
    offer_detail_cannot_see_offers: $localize`:@@offer_detail_cannot_see_offers:Offers are displayed when the offer submission deadline has expired.`,
    tender_detail_menu_inspection_not_allowed_tooltip: $localize`:@@tender_detail_menu_inspection_not_allowed_tooltip:This option will be available when the offer submission deadline expires.`,
    quantities_for_some_position_are_empty: $localize`:@@quantities_for_some_position_are_empty: Quantities for some position are empty`,
    file_is_complete: $localize`:@@file_is_complete: File is complete`,
    types_of_work: $localize`:@@types_of_work:Types of Work`,
    add: $localize`:@@add:Add`,
    select_all: $localize`:@@select_all:Select all`,
    offer_list_add_offer_comment_button: $localize`:@@offer_list_add_offer_comment_button: Add Comment`,
    offer_list_add_offer_comment_label: $localize`:@@offer_list_add_offer_comment_label: Offer Comments (internal use)`,
    offer_list_add_offer_comment_dialog_confirm_button: $localize`:@@offer_list_add_offer_comment_dialog_confirm_button: Add`,
    offer_list_edit_offer_comment_dialog_confirm_button: $localize`:@@offer_list_edit_offer_comment_dialog_confirm_button: Save`,
    offer_list_delete_offer_comment_dialog_confirm_button: $localize`:@@offer_list_delete_offer_comment_dialog_confirm_button: Delete`,
    offer_list_add_offer_comment_dialog_cancel_button: $localize`:@@offer_list_add_offer_comment_dialog_cancel_button: Cancel`,
    offer_list_delete_offer_comment_dialog_content: $localize`:@@offer_list_delete_offer_comment_dialog_content: Do you really want to delete this comment?`,
    offer_list_previous_comments_label: $localize`:@@offer_list_previous_comments_label: Previous Offer Comments`,
    offer_list_add_offer_comment_dialog_title: $localize`:@@offer_list_add_offer_comment_dialog_title: Add Comment`,
    offer_list_delete_offer_comment_dialog_title: $localize`:@@offer_list_delete_offer_comment_dialog_title: Delete Comment`,
    offer_list_edit_offer_comment_dialog_title: $localize`:@@offer_list_edit_offer_comment_dialog_title: Edit Comment`,
    offer_list_add_offer_comment_dialog_placeholder: $localize`:@@offer_list_add_offer_comment_dialog_placeholder: Please enter ...`,
    offer_list_edit_offer_comment_dialog_delete_button: $localize`:@@offer_list_edit_offer_comment_dialog_delete_button: Delete Comment`,
    offer_list_comment_read_more: $localize`:@@offer_list_comment_read_more: Read more...`,
    offer_list_comments_edited_label: $localize`:@@offer_list_comments_edited_label: edited`,
    contractor_info__private_directory_link: $localize`:@@contractor_info__private_directory_link:Private Directory`,
    contractor_table_not_costumer_anymore: $localize`:@@contractor_table_not_costumer_anymore:Not a Customer Anymore`,
    contractor_table_offer_received_state: $localize`:@@contractor_table_offer_received_state:Offer Received`,
    contractor_table_offer_rejected_state: $localize`:@@contractor_table_offer_rejected_state:Offer Rejected`,
    contractor_table_submission_extended: $localize`:@@contractor_table_submission_extended:Submission Extended to:`,
    contractor_table_in_bidding: $localize`:@@contractor_table_in_bidding:In bidding`,
    contractor_table_in_approval: $localize`:@@contractor_table_in_approval:In Approval`,
    contractor_table_bid_received: $localize`:@@contractor_table_bid_received:Bid received`,
    contractor_table_hide_rejected_offers: $localize`:@@contractor_table_hide_rejected_offers:Hide Rejected Offers`,
    contractor_table_compare_offers: $localize`:@@contractor_table_compare_offers:Hide Rejected OCompare Offers`,
    contractor_table_deregister_reason: $localize`:@@contractor_table_deregister_reason:Reason:`,
    contractor_table_deregister_description: $localize`:@@contractor_table_deregister_description:Description`,
    stepper_button_next: $localize`:@@stepper_button_next:Next`,
    stepper_button_back: $localize`:@@stepper_button_back:Back`,
    stepper_button_discard: $localize`:@@stepper_button_discard:Discard`,
    global_button_cancel: $localize `:@@global_button_cancel: Cancel`,
    global_button_discard: $localize `:@@global_button_discard: Discard`,
    global_button_save: $localize `:@@global_button_save: Save`,
    global_button_delete: $localize `:@@global_button_delete: Delete`,
    global_button_add: $localize `:@@global_button_add: Add`,
    global_button_done: $localize `:@@global_button_done: Done`,
    global_edited: $localize `:@@global_edited: Edited`,
    global_show_all: $localize `:@@global_show_all: Show all`,
    global_collapse: $localize `:@@global_collapse: Collapse`,
    offer_list_read_more_comment_edit_button: $localize`:@@offer_list_read_more_comment_edit_button: Edit Comment`,
    pp_core_breadcrumb_configure_crb_licenses: $localize`:@@pp_core_breadcrumb_configure_crb_licenses:Configure CRB Licenses`,
    crb_webshop_card_message_text: $localize`:@@crb_webshop_card_message_text:Use your CRB licenses or if you do not have them yet, acquire them from the Swiss Center for Construction CRB.`,
    crb_webshop_card_link_name: $localize`:@@crb_webshop_card_link_name:To the CRB Webshop`,
    crb_webshop_section_title: $localize`:@@crb_webshop_section_title:Company & License Information`,
    global_processing: $localize`:@@global_processing:Processing`,
    global_saving: $localize`:@@global_saving:Saving`,
    configure_crb_license_form_company_number_label: $localize`:@@configure_crb_license_form_company_number_label:CRB Company Number`,
    configure_crb_license_form_license_number_label: $localize`:@@configure_crb_license_form_license_number_label:CRB License Number(s)`,
    configure_crb_license_add_license_label: $localize`:@@configure_crb_license_add_license_label:Add License Field`,
    configure_crb_license_form_required_label: $localize`:@@configure_crb_license_form_required_label:* Required`,
    configure_crb_license_form_check_licenses_button_label: $localize`:@@configure_crb_license_form_check_licenses_button_label:Check License(s)`,
    pp_core_breadcrumb_view_crb_licenses: $localize`:@@pp_core_breadcrumb_view_crb_licenses:CRB Licenses`,
    crb_contact_admin_card_message_text: $localize`:@@crb_contact_admin_card_message_text:To manage your CRB licenses, contact your company administrator.`,
    view_crb_licenses_no_licenses_found: $localize`:@@view_crb_licenses_no_licenses_found:There are no CRB licenses yet.`,
    crb_company_data_header_company_number_label: $localize`:@@crb_company_data_header_company_number_label:Company Number`,
    crb_company_data_header_company_name_label: $localize`:@@crb_company_data_header_company_name_label:Company Name`,
    crb_company_data_header_company_address_label: $localize`:@@crb_company_data_header_company_address_label:Company Address`,
    crb_license_card_valid_from_label: $localize`:@@crb_license_card_valid_from_label:Valid From`,
    crb_license_card_valid_to_label: $localize`:@@crb_license_card_valid_to_label:Valid To`,
    crb_license_card_catalog_entry_label: $localize`:@@crb_license_card_catalog_entry_label:Catalog Entries`,
    crb_license_card_catalog_show_label: $localize`:@@crb_license_card_catalog_show_label:Show`,
    crb_license_card_users_label: $localize`:@@crb_license_card_users_label:Users`,
    crb_license_card_tag_expired_label: $localize`:@@crb_license_card_tag_expired_label:Expired`,
    crb_license_card_will_be_removed_label: $localize`:@@crb_license_card_will_be_removed_label:Will be removed`,
    configure_crb_license_form_recheck_licenses_button_label: $localize`:@@configure_crb_license_form_recheck_licenses_button_label:Recheck license`,
    crb_license_card_german_label: $localize`:@@crb_license_card_german_label:German`,
    crb_license_card_french_label: $localize`:@@crb_license_card_french_label:French`,
    crb_license_card_italian_label: $localize`:@@crb_license_card_italian_label:Italian`,
    global_required: $localize`:@@global_required:Required`,
    configure_crb_license_form_discard_entries_message: $localize`:@@configure_crb_license_form_discard_entries_message:Do you really want to discard unsaved changes?`,
    configure_crb_license_form_invalid_license: $localize`:@@configure_crb_license_form_invalid_license:License invalid.`,
    configure_crb_license_form_invalid_company_number: $localize`:@@configure_crb_license_form_invalid_company_number:Please check company number.`,
    configure_crb_license_form_crb_already_added: $localize`:@@configure_crb_license_form_crb_already_added:The CRB license ?license? was already added by another company.`,
    configure_crb_licenses_no_license_changes_on_recheck: $localize`:@@configure_crb_licenses_no_license_changes_on_recheck:No modified license information available.`,
    configure_crb_licenses_changed_licenses_on_recheck: $localize`:@@configure_crb_licenses_changed_licenses_on_recheck:License information has been updated. Please save the changes.`,
    dialog_close_tender_hint_bidding_round_deadline: $localize`:@@dialog_close_tender_hint_bidding_round_deadline:The tender is still open, but the deadline for submitting bids has not yet expired, should the tender be closed anyway?`,
    resume_tender_work_dialog_title: $localize`:@@resume_tender_work_dialog_title:Resume Work`,
    resume_tender_work_dialog_content: $localize`:@@resume_tender_work_dialog_content:Do you want to resume your work from yesterday?`,
    resume_tender_work_dialog_resume_button: $localize`:@@resume_tender_work_dialog_resume_button:Resume?`,
    project_dashboard_search_input_placeholder: $localize`:@@project_dashboard_search_input_placeholder: No., Project, Company`,
    create_application_message_section_title: $localize`:@@create_application_message_section_title:Message`,
    involved_person_form_involved_person_section_title: $localize`:@@involved_person_form_involved_person_section_title:Involved Persons`,
    involved_person_form_details_alert_text: $localize`:@@involved_person_form_details_alert_text:The fields Start of work and Tender quotation are part of the tender’s information and will be changed accordingly. No change notification will be sent to contractors`,
    involved_person_details_quotation_hint: $localize`:@@involved_person_details_quotation_hint:Quotation is still not visible for contractors.`,
    involved_person_form_visible_in_approval_checkbox_label: $localize`:@@involved_person_form_visible_in_approval_checkbox_label:Visible in approval document`,
    involved_person_contacts_form_top_label: $localize`:@@involved_person_contacts_form_top_label:Please add the persons who will be shown and decide who is allowed to sign the approval.`,
    involved_person_contact_form_role_construction_owner: $localize`:@@involved_person_contact_form_role_construction_owner:Construction Owner`,
    involved_person_contact_form_role_construction_owner_deputy: $localize`:@@involved_person_contact_form_role_construction_owner_deputy:Construction Owner Deputy`,
    involved_person_contact_form_role_construction_ownership: $localize`:@@involved_person_contact_form_role_construction_ownership:Construction Ownership`,
    involved_person_contact_form_role_project_manager: $localize`:@@involved_person_contact_form_role_project_manager:Project Manager`,
    involved_person_contact_form_role_site_manager: $localize`:@@involved_person_contact_form_role_site_manager:Site Manager`,
    involved_person_contact_form_role_general_contractor: $localize`:@@involved_person_contact_form_role_general_contractor:General Contractor`,
    involved_person_contact_form_role_total_contractor: $localize`:@@involved_person_contact_form_role_total_contractor:Total Contractor`,
    involved_person_contact_form_role_architect: $localize`:@@involved_person_contact_form_role_architect:Architect`,
    involved_person_contact_form_role_other: $localize`:@@involved_person_contact_form_role_other:Other`,
    involved_person_contact_form_show_signature_checkbox_label: $localize`:@@involved_person_contact_form_show_signature_checkbox_label:Show signature field`,
    involved_person_contact_form_role_label: $localize`:@@involved_person_contact_form_role_label:Role`,
    involved_person_contact_form_remove_person_button_label: $localize`:@@involved_person_contact_form_remove_person_button_label:Remove Person`,
    involved_person_form_add_person_button_label: $localize`:@@involved_person_form_add_person_button_label:Add Person`,
    involved_person_form_name_label: $localize`:@@involved_person_form_name_label:Firma / Vorname, Name`,
    involved_person_form_department_label: $localize`:@@involved_person_form_department_label:Department`,
    involved_person_form_address_label: $localize`:@@involved_person_form_address_label:Address`,
    involved_person_form_street_placeholder: $localize`:@@involved_person_form_street_placeholder:Street and Number`,
    form_float_action_bar_fill_required_fields_label: $localize`:@@form_float_action_bar_fill_required_fields_label:Please fill all the required Fields (*)`,
    form_float_action_bar_check_mandatory_or_validations: $localize`:@@form_float_action_bar_check_mandatory_or_validations:Please fill in all mandatory fields (*) or note the validation messages.`,
    form_float_action_bar_fix_errors: $localize`:@@form_float_action_bar_fix_errors:Please fix all the errors`,
    form_float_action_bar_show_button_label: $localize`:@@form_float_action_bar_show_button_label:Show`,
    involved_person_form_create_approval_button_label: $localize`:@@involved_person_form_create_approval_button_label:Create approval`,
    create_approval_success_message: $localize`:@@create_approval_success_message:The Approval request has been generated and can be processed at any time`,
    supplier_request_redirect_title: $localize`:@@supplier_request_redirect_title:Direct Inquiry`,
    supplier_request_redirect_company_title: $localize`:@@supplier_request_redirect_company_title:Company`,
    supplier_request_redirect_company_contact: $localize`:@@supplier_request_redirect_company_contact:Contact person:`,
    supplier_request_redirect_company_email: $localize`:@@supplier_request_redirect_company_email:EMail:`,
    supplier_request_redirect_company_tel: $localize`:@@supplier_request_redirect_company_tel:Telefon:`,
    supplier_request_redirect_tender_title: $localize`:@@supplier_request_redirect_tender_title:Ausschreibung`,
    supplier_request_redirect_tender_construction_owner: $localize`:@@supplier_request_redirect_tender_construction_owner:Construction owner:`,
    supplier_request_redirect_tender_issuer: $localize`:@@supplier_request_redirect_tender_issuer:Issuer:`,
    supplier_request_redirect_tender_start_of_work: $localize`:@@supplier_request_redirect_tender_start_of_work:Start of work`,
    supplier_request_redirect_buttons_tender_documents: $localize`:@@supplier_request_redirect_buttons_tender_documents:to tender documents`,
    supplier_request_redirect_buttons_offer_request: $localize`:@@supplier_request_redirect_buttons_offer_request:Offer request`,
    supplier_request_redirect_buttons_projects: $localize`:@@supplier_request_redirect_buttons_projects:Project`,
    supplier_request_redirect_invalid_request: $localize`:@@supplier_request_redirect_invalid_request:Invalid request.`,
    delete_tender_dialog_title: $localize`:@@delete_tender_dialog_title:Delete tender`,
    delete_tender_dialog_button: $localize`:@@delete_tender_dialog_button:Delete tender`,
    delete_tender_dialog_content: $localize`:@@delete_tender_dialog_content:: Should the cached tender be permanently deleted? It is no longer displayed and cannot be reactivated.`,
    cannot_create_approval_dialog_title: $localize`:@@cannot_create_approval_dialog_title:Award approval cannot be created`,
    tender_approval_unavailable_reason_tender_not_expired: $localize`:@@tender_approval_unavailable_reason_tender_not_expired:Offers can still be received until `,
    tender_approval_unavailable_reason_bidder_with_extended_submission: $localize`:@@tender_approval_unavailable_reason_bidder_with_extended_submission:There is still at least one company that can submit offers by `,
    tender_approval_unavailable_reason_bidding_round_active: $localize`:@@tender_approval_unavailable_reason_bidding_round_active:Bids can still be submitted until `,
    tender_timeline_step_award_approval_on: $localize`:@@tender_timeline_step_award_approval_on:Award Approval on`,
    tender_timeline_step_awarded_by_on: $localize`:@@tender_timeline_step_awarded_by_on:To be Awarded on`,
    tender_timeline_step_submit_offer_by: $localize`:@@tender_timeline_step_submit_offer_by:Submit offer by`,
    tender_timeline_step_start_of_work_on: $localize`:@@tender_timeline_step_start_of_work_on:Start of Work on`,
    tender_timeline_step_published_on: $localize`:@@tender_timeline_step_published_on:Published on`,
    tender_timeline_step_published_scheduled_on: $localize`:@@tender_timeline_step_published_scheduled_on:Publication scheduled on`,
    tender_timeline_step_application_by: $localize`:@@tender_timeline_step_application_by:Application by`,
    tender_timeline_step_feedback_by: $localize`:@@tender_timeline_step_feedback_by:Feedback by`,
    contractor_comments_show: $localize`:@@contractor_comments_show:Show Comments`,
    contractor_comments_add: $localize`:@@contractor_comments_add:Add Comment`,
    contractor_comments_edit: $localize`:@@contractor_comments_edit:Edit Comment`,
    contractor_comments_delete: $localize`:@@contractor_comments_delete:Delete Comment`,
    contractor_comments_delete_message: $localize`:@@contractor_comments_delete_message:Are you sure you want to delete this comment?`,
    contractor_comment_textarea_placeholder: $localize`:@@contractor_comment_textarea_placeholder:Enter comment...`,
    contractor_comment_dialog_list: $localize`:@@contractor_comment_dialog_list:Company Comments (?num?)`,
    contractor_comments_show_quantity: $localize`:@@contractor_comments_show_quantity:Show Comments (?num?)`,
    pp_core_button_details_delete_approval: $localize`:@@pp_core_button_details_delete_approval:Delete Approval`,
    delete_approval_dialog_title: $localize`:@@delete_approval_dialog_title:Delete award Approval`,
    delete_approval_dialog_message: $localize`:@@delete_approval_dialog_message:Should the award application be permanently deleted?`,
    snackbar_tender_detail_approval_successfully_deleted: $localize`:@@snackbar_tender_detail_approval_successfully_deleted:The approval was deleted`,
    pp_core_form_placeholder_tender_estimated_budget: $localize`:@@pp_core_form_placeholder_tender_estimated_budget:Tender quotation`,
    tender_log_approval_created: $localize`:@@tender_log_approval_created:Tender approval created`,
    tender_log_approval_deleted: $localize`:@@tender_log_approval_deleted:Tender approval deleted`,
    membersearch_placeholder: $localize`:@@membersearch_placeholder:Name, E-mail, Company, Department, Town`,
    pp_core_global_legal_form_SOLE_PROPRIETORSHIP: $localize`:@@pp_core_global_legal_form_SOLE_PROPRIETORSHIP:Sole Proprietorship`,
    pp_core_global_legal_form_GENERAL_PARTNERSHIP: $localize`:@@pp_core_global_legal_form_GENERAL_PARTNERSHIP:General Partnership`,
    pp_core_global_legal_form_LIMITED_PARTNERSHIP: $localize`:@@pp_core_global_legal_form_LIMITED_PARTNERSHIP:Limited Partnership`,
    pp_core_global_legal_form_LIMITED_COMPANY: $localize`:@@pp_core_global_legal_form_LIMITED_COMPANY:Limited Company`,
    pp_core_global_legal_form_LLC: $localize`:@@pp_core_global_legal_form_LLC:LLC`,
    pp_core_global_legal_form_COOPERATIVE: $localize`:@@pp_core_global_legal_form_COOPERATIVE:Cooperative`,
    pp_core_global_legal_form_ASSOCIATION: $localize`:@@pp_core_global_legal_form_ASSOCIATION:Association`,
    pp_core_global_legal_form_FOUNDATIONS: $localize`:@@pp_core_global_legal_form_FOUNDATIONS:Foundations`,
    pp_core_global_legal_form_OTHER: $localize`:@@pp_core_global_legal_form_OTHER:Other`,
    license_in_use_email_button: $localize`:@@license_in_use_email_button:info@olmero.ch`,
    license_in_use_phone_button: $localize`:@@license_in_use_phone_button:41 848 656 376`,
    create_tender_additional_content_published_on_label: $localize`:@@create_tender_additional_content_published_on_label:Published on ?date?`,
    pp_core_form_label_tender_phase_hint: $localize`:@@pp_core_form_label_tender_phase_hint:The phase is only editable on project level`,
    pp_core_form_label_tender_number_hint: $localize`:@@pp_core_form_label_tender_number_hint:For internal use`,
    pp_core_form_label_tender_label_description_hint: $localize`:@@pp_core_form_label_tender_label_description_hint:Will be displayed as tender title`,
    expandable_form_groups_show_options: $localize`:@@expandable_form_groups_show_options:Show Additional Options`,
    expandable_form_groups_hide_options: $localize`:@@expandable_form_groups_hide_options:Hide Additional Options`,
    pp_core_form_label_deductions_discount_period_label: $localize`:@@pp_core_form_label_deductions_discount_period_label:Days`,
    create_work_description_modal_type_of_work_description_label: $localize`:@@create_work_description_modal_type_of_work_description_label:Type of Work Description`,
    create_work_description_npc_type_label: $localize`:@@create_work_description_npc_type_label:NPC Work Description`,
    create_work_description_free_form_type_label: $localize`:@@create_work_description_free_form_type_label:Free Form Work Description`,
    create_work_description_configure_license_admin_snackbar_message: $localize`:@@create_work_description_configure_license_admin_snackbar_message:To be able to create NPC work descriptions, you need to have a CRB license configured.`,
    create_work_description_configure_license_non_admin_snackbar_message: $localize`:@@create_work_description_configure_license_non_admin_snackbar_message:To be able to create NPC work descriptions, you need to have a CRB licence configured. Please contact your company’s administrator.`,
    create_work_description_manage_own_catalogue: $localize`:@@create_work_description_manage_own_catalogue:Manage Own Catalogue`,
    duplicate_private_bidders_company_details_button: $localize`:@@duplicate_private_bidders_company_details_button:Company Details`,
    client_details_modules_configuration_smart_alert_text: $localize`:@@client_details_modules_configuration_smart_alert_text:For now, only SMART can be activated/deactivated here. When activating, please make sure to deactivate the module Construction Data in the settings of the old AP.`,
    client_details_modules_configuration_dialog_success: $localize`:@@client_details_modules_configuration_dialog_success:Module configuration successfully saved.`,
    attachment_download_free_form_as_pdf: $localize`:@@attachment_download_free_form_as_pdf:Download as PDF`,
    upload_tender_attachments_category_title_hint: $localize`:@@upload_tender_attachments_category_title_hint:Please select a category`,
    pp_core_tender_detail_offer_addon_text1: $localize`:@@pp_core_tender_detail_offer_addon_text1:A cover sheet is created for each offer. It can be printed or downloaded and is automatically added to the offer export.`,
    offer_list_status_of_tender_attachments_label: $localize`:@@offer_list_status_of_tender_attachments_label:Status of Tender Attachments`,
    offer_attachments_changes_status_changed_label: $localize`:@@offer_attachments_changes_status_changed_label:?quantity? Change(s)`,
    offer_attachments_changes_status_unknown_hint_label: $localize`:@@offer_attachments_changes_status_unknown_hint_label:For offers manually entered on behalf of the contractor it can not be identified.`,
    offer_attachments_changes_status_unchanged_hint_label: $localize`:@@offer_attachments_changes_status_unchanged_hint_label:All documents were available at the time the offer was submitted.`,
    changed_tender_attachments_popup_title: $localize`:@@changed_tender_attachments_popup_title:Changed Tender Attachments (?quantity?)`,
    offer_list_action_menu_delete_bid: $localize`:@@offer_list_action_menu_delete_bid:Delete bid`,
    issuer_delete_bid_dialog_title: $localize`:@@issuer_delete_bid_dialog_title:Do you want to delete your bid no ?name? ?`,
    issuer_delete_bid_dialog_content: $localize`:@@issuer_delete_bid_dialog_content:All your files and entries in this bid will be gone for good`,
    attachment_copy_and_edit_button: $localize`:@@attachment_copy_and_edit_button:Copy and Edit`,
    bidder_tender_create_copy_free_form_tooltip: $localize`:@@bidder_tender_create_copy_free_form_tooltip:Copy and fill freeform`,
    pp_core_button_details_add_bid: $localize`:@@pp_core_button_details_add_bid:Add Bid`,
    pp_core_button_details_edit_bid: $localize`:@@pp_core_button_details_edit_bid:Edit Bid`,
    add_offer_stepper_documents_section: $localize`:@@add_offer_stepper_documents_section:Offer Documents`,
    add_offer_stepper_reference_projects_section: $localize`:@@add_offer_stepper_reference_projects_section:Reference Projects`,
    issuer_template_attachments_title: $localize`:@@issuer_template_attachments_title:Work description templates from issuer (?quantity? Files)`,
    add_offer_bidding_round: $localize`:@@add_offer_bidding_round:Bidding Round`,
    add_offer_cover_sheet_bidding_round_selector_placeholder: $localize`:@@add_offer_cover_sheet_bidding_round_selector_placeholder:Bidding Round`,
    add_offer_cover_sheet_bidding_round_selector_display_name: $localize`:@@add_offer_cover_sheet_bidding_round_selector_display_name:Bidding Round ?index? (?initialDate? - ?finalDate?)`,
    add_offer_bid_received_on: $localize`:@@add_offer_bid_received_on:Bid received on`,
    bid_offer_choose_date: $localize`:@@bid_offer_choose_date:Choose a date`,
    issuer_bid_on_behalf_max_received_on_date: $localize`:@@issuer_bid_on_behalf_max_received_on_date:Date is not within the selected bidding period.`,
    free_form_work_description_error: $localize`:@@free_form_work_description_error:Error while processing the free form work description`,
  };

  public textByKey(key: string): string {
    return this.i18nText[key];
  }

  public allKeys(): string[] {
    return Object.keys(this.i18nText);
  }
}
