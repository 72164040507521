import {
  ChangeDetectorRef,
  Component, ComponentFactoryResolver, ElementRef, OnDestroy, OnInit, ViewChild
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ErrorToastProxyService } from '@project-shared/services/error-proxy/error-toast-proxy.service';
import { ToastService } from '@project-shared/modules/toast/services/toast.service';
import { TextService } from '@olmero/shared-core';
import { SidenavService } from './shared/services/sidenav.service';
import { SidenavDirective } from '@olmero/shared-ui';
import { NavigationEnd, Router } from '@angular/router';
import { ScrollService } from '@project-shared/services/scroll.service';

@Component({
  selector: 'olm-app-frame',
  templateUrl: './app-frame.component.html',
  styleUrls: ['./app-frame.component.scss'],
})
export class AppFrameComponent implements OnInit, OnDestroy {

  @ViewChild('appFrameContent', { static: true }) appFrameContent: ElementRef;
  @ViewChild(SidenavDirective, { static: true }) sidenavDirective: SidenavDirective;

  hasSidenav = false;
  hasSidenavWithTitle = false;

  private unsubscribe = new Subject<void>();

  constructor(
    private errorToastProxyService: ErrorToastProxyService,
    private toastService: ToastService,
    private textService: TextService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private sidenavService: SidenavService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private scrollService: ScrollService
  ) {}

  ngOnInit(): void {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd){
        this.scrollService.scrollTo();
      }
    });

    this.setSidenav();

    this.errorToastProxyService.catchError()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(toastOpener => {
        toastOpener.open(this.textService, this.toastService);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }


  private setSidenav(): void {
    this.sidenavService.component$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(value => {
        if (value) {
          this.hasSidenav = true;
          this.sidenavDirective.viewContainerRef.clear();
          const componentFactory = this.componentFactoryResolver.resolveComponentFactory(value.component);
          const componentRef = this.sidenavDirective.viewContainerRef.createComponent(componentFactory);
          componentRef.instance.data = value.data;
          this.hasSidenavWithTitle = value.data.showAppFrameTitle;
          this.changeDetector.detectChanges();
        } else {
          this.hasSidenav = false;
          this.hasSidenavWithTitle = false;
          this.sidenavDirective.viewContainerRef.clear();
        }
      });
  }
}
